import React, { useEffect, useState } from 'react'
import { PageBody } from '@components/Page/PageBody'
import { Box, Color, Group, IconButton, Link } from '@revolut/ui-kit'
import { FormPreview } from '@components/FormPreview/FormPreview'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ReferCandidateInterface } from '@src/interfaces/referCandidate'
import { Document, LogoLinkedIn } from '@revolut/icons'
import { jobPostingsRequests } from '@src/api/jobPosting'
import { FilterOption } from '@src/interfaces/data'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import Tooltip from '@components/Tooltip/Tooltip'
import { NewCandidateInterface } from '@src/interfaces/newCandidate'
import { ApplicationCandidateInterface } from '@src/interfaces/applicationCandidate'
import CandidateApplicationFormSection from '@src/features/CandidateFormPreview/CandidateApplicationFormSection'
import { getLinkedinUrl } from '@src/utils/hiring'
import CVPreviewSidebar, {
  CVFileType,
} from '@src/features/CVPreviewSidebar/CVPreviewSidebar'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import WorkExperienceInfo from '@src/features/InterviewTool/WorkExperienceInfo'
import EducationInfo from '@src/features/InterviewTool/EducationInfo'

const getURLOrigin = (link: string) => {
  try {
    const { origin } = new URL(link)
    return origin
  } catch {
    return link
  }
}

const CandidateFormPreview = () => {
  const { values } = useLapeContext<
    ReferCandidateInterface | NewCandidateInterface | ApplicationCandidateInterface
  >()
  const [mainPosting, setMainPosting] = useState<JobPostingInterface>()
  const [secondaryPostings, setSecondaryPostings] = useState<JobPostingInterface[]>()
  const [documentSidebarOpen, setDocumentSidebarOpen] = useState(false)
  const permissions = useSelector(selectPermissions)
  const canViewCandidate = permissions?.includes(PermissionTypes.ViewCandidate)

  const linkedin = getLinkedinUrl(values.links)
  const links = values.links?.filter(link => link !== linkedin)

  useEffect(() => {
    const fetchJobPostings = async () => {
      const filters: FilterOption[] = []
      if (values.posting_id) {
        filters.push({
          id: values.posting_id,
          name: values.posting_id,
        })
      }
      if (values.secondary_posting_ids) {
        filters.push(
          ...values.secondary_posting_ids.map(item => ({
            id: item,
            name: item,
          })),
        )
      }

      if (!filters.length) {
        return
      }

      const resp = await jobPostingsRequests.getItems({
        filters: [
          {
            columnName: 'id',
            filters,
          },
        ],
      })

      setMainPosting(resp.data.results.find(item => +item.id === +values.posting_id!))
      setSecondaryPostings(
        resp.data.results.filter(item => +item.id !== +values.posting_id!),
      )
    }
    fetchJobPostings()
  }, [values.posting_id, values.secondary_posting_ids])

  return (
    <>
      <PageBody>
        <FormPreview data={values} title="General information">
          <Group>
            <FormPreview.Item
              title="Full name"
              field="candidate.full_name"
              to={
                canViewCandidate && values.candidate?.id
                  ? () =>
                      pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, {
                        id: values.candidate?.id,
                      })
                  : undefined
              }
            />
            <FormPreview.Item title="Email" field="email" />
            <FormPreview.Item title="Mobile" field="phone" />
            <FormPreview.Item title="Current company" field="current_company" />
            {(values.source.id === 'sourcing' || values.source.id === 'referral') && (
              <>
                {!values.country && values.location ? (
                  <FormPreview.Item title="Current candidate location" field="location" />
                ) : (
                  <FormPreview.Item title="Current country" field="country.name" />
                )}
              </>
            )}
            {values.preferred_locations && (
              <FormPreview.Item<NewCandidateInterface>
                title="Preferred Locations"
                insert={data => data.preferred_locations?.join(', ')}
              />
            )}
          </Group>
        </FormPreview>
        <Box mt="s-16">
          <FormPreview data={values} title="Work details">
            <Group>
              <FormPreview.Item<ReferCandidateInterface>
                title="Resume"
                centered
                insert={resp =>
                  resp.resume?.id ? (
                    <Tooltip
                      placement="top"
                      hide={!resp.resume.name}
                      text={resp.resume.name}
                    >
                      <IconButton
                        useIcon={Document}
                        size={16}
                        color={Color.BLUE}
                        onClick={() => setDocumentSidebarOpen(true)}
                      />
                    </Tooltip>
                  ) : (
                    '-'
                  )
                }
              />
              {linkedin && (
                <FormPreview.Item
                  title="LinkedIn profile"
                  insert={() => (
                    <a
                      href={linkedin}
                      rel="noreferrer noopener"
                      target="_blank"
                      color="transparent"
                    >
                      <LogoLinkedIn size={16} color={Color.BLUE} />
                    </a>
                  )}
                />
              )}
              {links?.map((link, idx) => (
                <FormPreview.Item
                  key={idx}
                  title={`Additional link ${idx + 1}`}
                  insert={() => (
                    <Link href={link} rel="noreferrer noopener" target="_blank">
                      {getURLOrigin(link)}...
                    </Link>
                  )}
                />
              ))}
            </Group>
          </FormPreview>
        </Box>
        <Box mt="s-16">
          <FormPreview data={values} title="Position details">
            <Group>
              <FormPreview.Item
                title="Main posting"
                insert={() => (
                  <Link
                    href={pathToUrl(ROUTES.FORMS.JOB_POSTING.PREVIEW, {
                      id: mainPosting?.id,
                      specId: mainPosting?.specialisation?.id,
                    })}
                    target="_blank"
                  >
                    {mainPosting?.name || '-'}
                  </Link>
                )}
              />
              {!!secondaryPostings?.length && (
                <FormPreview.Item
                  title="Additional postings"
                  insert={() =>
                    secondaryPostings?.map(item => (
                      <Link
                        key={item.id}
                        href={pathToUrl(ROUTES.FORMS.JOB_POSTING.PREVIEW, {
                          id: item.id,
                          specId: item.specialisation?.id,
                        })}
                        target="_blank"
                        display="block"
                      >
                        {item.name || '-'}
                      </Link>
                    )) || '-'
                  }
                />
              )}

              <FormPreview.Item<ReferCandidateInterface>
                title="Recruiter assigned"
                insert={resp =>
                  resp.candidate ? <UserWithAvatar {...resp.candidate.recruiter} /> : '-'
                }
              />

              <FormPreview.Item title="Seniority" field="seniority.name" />
            </Group>
          </FormPreview>
        </Box>
        {'work_experiences' in values && (
          <Box mt="s-16">
            <WorkExperienceInfo data={values.work_experiences} />
          </Box>
        )}
        {'educations' in values && (
          <Box mt="s-16">
            <EducationInfo data={values.educations} />
          </Box>
        )}
        {'application_form_data' in values &&
          values?.application_form_data?.sections?.map(section => (
            <CandidateApplicationFormSection
              key={section.internal_data_id}
              section={section}
            />
          ))}
        {(values.source.id === 'sourcing' || values.source.id === 'referral') && (
          <Box mt="s-16">
            <FormPreview data={values}>
              <FormPreview.Details
                title={`Notes${values.source.id === 'referral' ? ' from referrer' : ''}`}
                field="notes"
              />
            </FormPreview>
          </Box>
        )}
      </PageBody>
      <CVPreviewSidebar
        file={
          {
            ...values.resume,
            extension: values.resume?.name?.split('.').slice(-1)[0] || '',
          } as CVFileType
        }
        isOpen={documentSidebarOpen}
        onClose={() => setDocumentSidebarOpen(false)}
      />
    </>
  )
}

export default CandidateFormPreview

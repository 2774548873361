import React from 'react'
import { useSelector } from 'react-redux'
import { Cell, Flex, MoreBar } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'

import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { RowInterface } from '@src/interfaces/data'
import { BudgetPoolInterface } from '@src/interfaces/budgetPools'
import { selectPermissions } from '@src/store/auth/selectors'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { useTable } from '@src/components/Table/hooks'
import {
  budgetPoolCreatedOnColumn,
  budgetPoolHeadcountColumn,
  budgetPoolNameColumn,
  budgetPoolOwnerColumn,
  budgetPoolStatusColumn,
  budgetPoolTypeColumn,
} from '@src/constants/columns/budgetPools'
import { PermissionTypes } from '@src/store/auth/types'
import Stat from '@src/components/Stat/Stat'
import { getBudgetPools } from '@src/api/budgetPools'
import SettingsButtons from '@src/features/SettingsButtons'
import { Statuses } from '@src/interfaces'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'

const ROW: RowInterface<BudgetPoolInterface> = {
  linkToForm: ({ id }) => navigateTo(pathToUrl(ROUTES.FORMS.BUDGET_POOL.PREVIEW, { id })),
  cells: [
    {
      ...budgetPoolNameColumn,
      width: 160,
    },
    {
      ...budgetPoolTypeColumn,
      width: 100,
    },
    {
      ...budgetPoolOwnerColumn,
      width: 100,
    },
    {
      ...budgetPoolHeadcountColumn,
      width: 100,
    },
    {
      ...budgetPoolCreatedOnColumn,
      width: 100,
    },
    {
      ...budgetPoolStatusColumn,
      width: 100,
    },
  ],
}

const BudgetPools = () => {
  const permissions = useSelector(selectPermissions)

  const table = useTable<BudgetPoolInterface>({ getItems: getBudgetPools }, [
    {
      columnName: 'status',
      filters: [{ id: Statuses.active, name: Statuses.active }],
      nonResettable: true,
    },
  ])

  return (
    <Cell>
      <Flex flexDirection="column" width="100%">
        <Stat
          label="Budget pools"
          val={table?.loading ? undefined : table?.count}
          mb="s-16"
        />

        {permissions.includes(PermissionTypes.AddBudgetPoolConfig) && (
          <SettingsButtons mb="s-16">
            <MoreBar.Action
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.BUDGET_POOL.GENERAL)}
              useIcon={Plus}
            >
              Add new budget pool
            </MoreBar.Action>
          </SettingsButtons>
        )}

        <Flex style={{ position: 'relative' }} flex="1 0">
          <AdjustableTable
            name={TableNames.CompensationBudgetPools}
            useWindowScroll
            row={ROW}
            {...table}
            noDataMessage="Budget pools will appear here."
          />
        </Flex>
      </Flex>
    </Cell>
  )
}

export default BudgetPools

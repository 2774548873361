import React, { useEffect, useRef } from 'react'
import {
  PerformanceReviewTypes,
  ReviewCategory,
  ReviewerRelation,
  ReviewScorecardInterface,
} from '@src/interfaces/performance'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Card, CardField } from '@src/pages/Forms/EmployeePerformanceLayout/Card'
import {
  CardContentTypes,
  CommonCardProps,
  getRoundedRating,
  GradeOption,
  onPrefillCultureValue,
  onPrefillWithGrade,
  ratingOptions,
  updateValueRating,
  usePrefillSkillsWithPreviousQuarter,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { getNormalizedCards } from '@src/utils/performance'
import { get, set } from 'lodash'
import { StatusPopup, useStatusPopup } from '@revolut/ui-kit'
import { LoadingCard } from '@src/pages/Forms/EmployeePerformanceLayout/components/LoadingCard'

export const SkillsCard = connect(
  ({ selectedField, setSelectedField }: CommonCardProps) => {
    const { values, errors } = useLapeContext<ReviewScorecardInterface>()
    const ref = useRef<HTMLDivElement>(null)
    const statusPopup = useStatusPopup()

    const canViewSkills = !!values?.review_data?.functional_skills

    useEffect(() => {
      if (
        errors.review_data?.functional_skills?.cards &&
        !errors.review_data?.deliverables?.cards
      ) {
        ref?.current?.scrollIntoView({ behavior: 'smooth' })
      }
    }, [errors.review_data])

    const { prefillCompleted } = usePrefillSkillsWithPreviousQuarter(
      values,
      values?.review_data?.functional_skills?.cards,
    )

    if (!canViewSkills) {
      return null
    }

    const hasManagerValues = !!values.review_data?.manager_values

    const onSkip = (value?: string) => {
      if (hasManagerValues) {
        values.review_data.manager_values!.skipped_section_justification = value
      } else {
        values.review_data.functional_skills!.skipped_section_justification = value
      }
    }

    const managerValuesCards = values?.review_data?.manager_values?.cards || []
    const managerValuesFields =
      managerValuesCards.map((card, ind) => ({
        field: `review_data.manager_values.cards.${ind}`,
        title: card.name,
        grades: ratingOptions,
        cardIndex: ind,
      })) || []
    const functionalSkillsCards = getNormalizedCards(
      values.review_data?.functional_skills?.cards || [],
    )
    const functionalSkillsFields = functionalSkillsCards.map((card, ind) => ({
      field: `review_data.functional_skills.cards.${ind}`,
      title: card.name,
      grades: ratingOptions,
      cardIndex: ind,
    }))
    const skillsFields = hasManagerValues ? managerValuesFields : functionalSkillsFields

    const onSelectGrade = (grade: GradeOption, field: CardField) => {
      if (hasManagerValues) {
        onPrefillCultureValue(values, grade, field.field)
        updateValueRating({ values, path: field.field })
      } else {
        const currentRating = get(values, `${field.field}.rating`)
        set(values, `${field.field}.rating`, grade.key)
        try {
          onPrefillWithGrade(
            values,
            grade,
            PerformanceReviewTypes.skills,
            field.cardIndex,
          )
        } catch {
          set(values, `${field.field}.rating`, currentRating)
          statusPopup.show(
            <StatusPopup variant="error">
              <StatusPopup.Title>Could not recalculate rating</StatusPopup.Title>
            </StatusPopup>,
          )
        }
      }
    }

    if (!prefillCompleted) {
      return <LoadingCard />
    }

    return (
      <Card
        data={values}
        type={CardContentTypes.SKILLS}
        title="Functional skills"
        icon="Palette"
        fields={skillsFields}
        selectedField={selectedField}
        setSelectedField={setSelectedField}
        isGradeSelectedRule={(field, grade) => {
          const ratingValue = get(values, field)?.rating
          if (!ratingValue) {
            return false
          }
          return hasManagerValues
            ? ratingValue === grade.key
            : getRoundedRating(ratingValue) === grade.key
        }}
        onSelectGrade={onSelectGrade}
        allowSkip={
          values.category === ReviewCategory.Performance &&
          values.reviewer_relation !== ReviewerRelation.FunctionalManager
        }
        onSkip={onSkip}
        justification={
          values?.review_data?.manager_values?.skipped_section_justification ||
          values?.review_data?.functional_skills?.skipped_section_justification
        }
        headerRef={ref}
      />
    )
  },
)

import React from 'react'
import { Flex } from '@revolut/ui-kit'
import { Redirect, Route, Switch, useParams } from 'react-router-dom'

import { useIsNewLayout } from '@src/pages/EmployeeProfile/Layout/helpers'
import { EmployeeInterface, EmployeeStats } from '@src/interfaces/employees'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import PersonalKPI from './PersonalKPI'
import RelevantKPI from './RelevantKPI'
import { ColoredPercent } from '@components/ColumnInserts/ColoredPercent/ColoredPercent'

interface Props {
  data: EmployeeInterface
  stats?: EmployeeStats
}

export const KPI = ({ stats, ...props }: Props) => {
  const params = useParams<{ id: string }>()
  const isNewLayout = useIsNewLayout()

  const tabs = [
    {
      path: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.KPI.PERSONAL,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.KPI.PERSONAL, params),
      title: 'Personal',
      quickSummary: stats && !isNewLayout && (
        <ColoredPercent percent={stats.kpi_performance_percent * 100} />
      ),
      component: PersonalKPI,
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.KPI.RELEVANT,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.KPI.RELEVANT, params),
      title: isNewLayout ? 'Relevant' : 'Relevant KPIs',
      component: RelevantKPI,
    },
  ]

  const navigation = (
    <Flex alignItems="flex-start" mb="s-24">
      <TabBarNavigation isSubtab tabs={tabs} key={stats?.kpi_performance_percent} />
    </Flex>
  )

  return (
    <>
      {!isNewLayout && navigation}
      <Switch>
        {tabs.map(tab => (
          <Route exact path={tab.path} key={tab.path}>
            <tab.component {...props} navigation={isNewLayout ? navigation : undefined} />
          </Route>
        ))}
        <Redirect to={tabs[0].path} />
      </Switch>
    </>
  )
}

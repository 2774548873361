import {
  Box,
  Cell,
  Flex,
  Tag,
  VStack,
  Text,
  HStack,
  MoreBar,
  Token,
  Icon,
  useStatusPopup,
  StatusPopup,
  Popup,
} from '@revolut/ui-kit'
import { getStatusColor } from '@src/components/CommonSC/General'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { approveGoal, goalsRequests, requestChange } from '@src/api/goals'
import { IdAndName, Statuses } from '@src/interfaces'
import { GoalsInterface } from '@src/interfaces/goals'
import React, { ReactNode, useState } from 'react'
import { StatusUpdate } from './StatusUpdate'
import { RequestChange } from './RequestChange'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { PermissionTypes } from '@src/store/auth/types'

const HeaderBanner = ({
  title,
  status,
  subtitle,
  actions,
}: {
  title: string
  status: IdAndName<Statuses | ApprovalStatuses>
  subtitle: ReactNode
  actions: ReactNode
}) => {
  const statusBadge = (
    <Tag
      variant="outlined"
      color={getStatusColor(status.id) || Token.color.greyTone50}
      data-testid="goal-status"
    >
      {status.name}
    </Tag>
  )

  return (
    <Box mt="s-8">
      <Cell pt="s-24" px="s-16" pb="s-16">
        <VStack gap="s-16" overflow="hidden">
          <Flex flex={1} alignItems="center" gap="s-16">
            <VStack gap="s-4">
              <Text variant="h1" whiteSpace="pre-wrap">
                {title}
              </Text>
              <HStack space="s-8" align="center">
                {statusBadge}
                {subtitle}
              </HStack>
            </VStack>
          </Flex>
          {actions}
        </VStack>
      </Cell>
    </Box>
  )
}

type Action = 'approve' | 'request_change' | 'update_status'

export const GoalsPreviewHeader = () => {
  const { values } = useLapeContext<GoalsInterface>()
  const [pending, setPending] = useState<Action>()
  const statusPopup = useStatusPopup()
  const [statusUpdateShown, setStatusUpdateShown] = useState(false)
  const [requestChangeShown, setRequestChangeShown] = useState(false)
  const { entity } = useOrgEntity()

  const showError = (title: string, description: string) => {
    statusPopup.show(
      <StatusPopup variant="error">
        <StatusPopup.Title>{title}</StatusPopup.Title>
        <StatusPopup.Description>{description}</StatusPopup.Description>
      </StatusPopup>,
    )
  }

  const showSuccess = (title: string, description?: string) => {
    statusPopup.show(
      <StatusPopup variant="success">
        <StatusPopup.Title>{title}</StatusPopup.Title>
        {description && <StatusPopup.Description>{description}</StatusPopup.Description>}
      </StatusPopup>,
    )
  }

  const approvalStatus = values.approval_status
  const isPendingApproval = approvalStatus.id !== ApprovalStatuses.Approved
  const isApproved = approvalStatus.id === ApprovalStatuses.Approved

  const isAllowed = (key: keyof typeof PermissionTypes): boolean =>
    !!values.field_options?.permissions?.includes(PermissionTypes[key])

  const accessControl = {
    approve: isAllowed('CanApproveGoal'),
    requestChange: isAllowed('CanRejectGoal'),
    change: isAllowed('CanChangeGoal'),
  }

  const actions = (
    <MoreBar>
      {accessControl.approve && isPendingApproval && (
        <MoreBar.Action
          variant="accent"
          useIcon="Check"
          pending={pending === 'approve'}
          onClick={async () => {
            setPending('approve')
            try {
              await approveGoal(values.id)
              values.approval_status = { id: ApprovalStatuses.Approved, name: 'Approved' }
              showSuccess('Goal approved')
            } catch (err) {
              showError('Failed to approve', 'Something went wrong. Please try again.')
            } finally {
              setPending(undefined)
            }
          }}
        >
          Approve goal
        </MoreBar.Action>
      )}
      {accessControl.requestChange && approvalStatus.id === ApprovalStatuses.Pending && (
        <MoreBar.Action
          disabled={!!pending}
          onClick={() => setRequestChangeShown(true)}
          pending={pending === 'request_change'}
          useIcon="HelpChat"
        >
          Request changes
        </MoreBar.Action>
      )}
      {accessControl.change && isApproved && (
        <MoreBar.Action
          useIcon="Plus"
          variant="accent"
          disabled={!!pending}
          pending={pending === 'update_status'}
          onClick={() => setStatusUpdateShown(true)}
        >
          Update status
        </MoreBar.Action>
      )}
      {accessControl.change && (
        <MoreBar.Action
          disabled={!!pending}
          pending={!entity}
          useIcon="Pencil"
          onClick={() => {
            navigateTo(pathToUrl(ROUTES.FORMS.GOAL.EDIT, { id: values.id }), { entity })
          }}
        >
          Edit goal
        </MoreBar.Action>
      )}
    </MoreBar>
  )
  const subtitle = (
    <HStack space="s-8" color={Token.color.greyTone50} align="center">
      <HStack space="s-4" align="center">
        <Icon name="Profile" size={16} />
        <Text variant="caption">{values.owner.display_name}</Text>
      </HStack>

      <HStack space="s-4" align="center">
        <Icon name="Services" size={16} />
        <Text variant="caption">{values.content_object.name}</Text>
      </HStack>
    </HStack>
  )
  const backUrl = 'FIXME'

  return (
    <>
      <PageHeader
        backUrl={backUrl}
        title={
          <HeaderBanner
            title={values.name}
            status={isPendingApproval ? approvalStatus : values.status}
            actions={actions}
            subtitle={subtitle}
          />
        }
      />
      <Popup
        size="md"
        open={statusUpdateShown}
        onClose={() => setStatusUpdateShown(false)}
      >
        <StatusUpdate
          currentStatus={values.status.id}
          onCancel={() => setStatusUpdateShown(false)}
          onSubmit={async nextStatus => {
            setStatusUpdateShown(false)
            setPending('update_status')
            try {
              // todo: send comment when BE implements
              const response = await goalsRequests.update(
                { status: { id: nextStatus, name: nextStatus } },
                { id: String(values.id) },
              )

              values.status = response.data.status
            } catch (err) {
              showError(
                'Failed to add status update',
                'Something went wrong. Please try again.',
              )
            } finally {
              setPending(undefined)
            }
          }}
        />
      </Popup>
      <Popup
        size="md"
        open={requestChangeShown}
        onClose={() => setRequestChangeShown(false)}
      >
        <RequestChange
          onCancel={() => setRequestChangeShown(false)}
          onSubmit={async comment => {
            setRequestChangeShown(false)
            setPending('update_status')
            try {
              await requestChange(values.id, comment)
              values.approval_status = {
                id: ApprovalStatuses.RequiresChanges,
                name: 'Requires changes',
              }
            } catch (err) {
              showError(
                'Failed to request change',
                'Something went wrong. Please try again.',
              )
            } finally {
              setPending(undefined)
            }
          }}
        />
      </Popup>
    </>
  )
}

import React, { useState } from 'react'
import {
  Avatar,
  Banner,
  Box,
  Button,
  Color,
  Flex,
  Group,
  Item,
  Subheader,
  Text,
  Token,
  VStack,
} from '@revolut/ui-kit'
import { Document } from '@revolut/icons'

import { PageBody } from '@src/components/Page/PageBody'
import FileUploader from '@src/components/Inputs/FileUploader/FileUploader'
import { createDocumentUploadSession, importDocumentsFile } from '@src/api/importData'
import { PageActions } from '@src/components/Page/PageActions'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { formatFileSize } from '@src/utils/format'
import Code from '@components/Code/Code'
import { BooleanRadioSwitch } from '@src/pages/OnboardingChecklist/components/RadioSwitch'
import { Recommended } from '@src/pages/OnboardingChecklist/components/Recommended'

const MAX_UPLOADING_FILES_COUNT = 5

type FileState = 'pending' | 'uploading' | 'done' | 'error'

interface FileItemType {
  tempId: number
  file: File
  state: FileState
  error?: string
}

type FileItemProps = Pick<FileItemType, 'file' | 'state' | 'error'>

const FileItem = ({ file, state, error }: FileItemProps) => {
  const description = (() => {
    if (state === 'done') {
      return formatFileSize(file.size)
    }
    if (state === 'pending') {
      return 'Pending...'
    }
    if (state === 'uploading') {
      return 'Uploading...'
    }
    if (state === 'error') {
      const fileWontBeIncludedMessage = 'This file will not be included.'
      return `${fileWontBeIncludedMessage} ${error || 'Error has occurred'}`
    }
    return null
  })()

  return (
    <Item>
      <Item.Avatar>
        <Avatar useIcon={Document} />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{file.name}</Item.Title>
        <Item.Description color={state === 'error' ? Color.RED : undefined}>
          {description}
        </Item.Description>
      </Item.Content>
    </Item>
  )
}

interface DocumentsUploadFilesProps {
  customActions?: (continueDisabled: boolean, sessionId?: number) => React.ReactNode
}

export const DocumentsUploadFiles = ({ customActions }: DocumentsUploadFilesProps) => {
  const [files, setFiles] = useState<FileItemType[]>([])
  const [isOrganizeByEmployeeMode, setIsOrganizeByEmployeeMode] = useState(true)
  const [sessionId, setSessionId] = useState<number>()

  const continueDisabled =
    files.length === 0 ||
    files.some(file => file.state === 'pending' || file.state === 'uploading') ||
    !files.some(file => file.state === 'done')

  if (sessionId) {
    const pendingFiles = files.filter(f => f.state === 'pending')
    const uploadingFiles = files.filter(f => f.state === 'uploading')

    const availableToUploadCount = MAX_UPLOADING_FILES_COUNT - uploadingFiles.length

    const nextToUpload = pendingFiles.slice(0, availableToUploadCount)

    if (nextToUpload.length) {
      const nextToUploadIds = nextToUpload.map(f => f.tempId)

      setFiles(prev =>
        prev.map(file =>
          nextToUploadIds.includes(file.tempId) ? { ...file, state: 'uploading' } : file,
        ),
      )

      nextToUpload.forEach(file => {
        importDocumentsFile(
          sessionId,
          file.file,
          isOrganizeByEmployeeMode && file.file.webkitRelativePath
            ? `${file.file.webkitRelativePath
                .split('/')
                .at(-2)} - ${file.file.webkitRelativePath.split('/').at(-1)}`
            : undefined,
        )
          .then(() => {
            setFiles(prev =>
              prev.map(f => (f.tempId === file.tempId ? { ...f, state: 'done' } : f)),
            )
          })
          .catch(error => {
            const errorMessage =
              error?.response?.data?.detail || error?.response?.data?.file

            setFiles(prev =>
              prev.map(f =>
                f.tempId === file.tempId
                  ? {
                      ...f,
                      state: 'error',
                      error: typeof errorMessage === 'string' ? errorMessage : undefined,
                    }
                  : f,
              ),
            )
          })
      })
    }
  }

  return (
    <>
      <PageBody>
        <VStack space="s-16">
          <Box>
            <Subheader>
              <Subheader.Title>
                Do you want to organise your folders or rename files?
              </Subheader.Title>
            </Subheader>
            <BooleanRadioSwitch
              variant="horizontal"
              value={isOrganizeByEmployeeMode}
              onChange={setIsOrganizeByEmployeeMode}
              yesLabel={
                <Flex>
                  Organise by employee
                  <Recommended />
                </Flex>
              }
              yesDescription="Separate all your documents by employee folders, and upload it all at once"
              noLabel="Rename files"
              noDescription="I want to rename documents to automatically assign employee & category"
            />
          </Box>

          {isOrganizeByEmployeeMode ? (
            <Banner>
              <Banner.Content>
                <Banner.Title>
                  Organise your documents by creating employee folders and we
                  automatically assign them
                </Banner.Title>
                <Banner.Description>
                  <br />
                  <ul style={{ marginLeft: Token.space.s12 }}>
                    <li>
                      Create one folder for each employee and group all their documents
                      inside of it.
                    </li>
                    <li>
                      Make sure name them with the employee <b>email</b>
                    </li>
                  </ul>
                  <br />
                  <b>(Optional) Automatically assign a category</b>
                  <br />
                  <br />
                  <ul style={{ marginLeft: Token.space.s12 }}>
                    <li>
                      To automatically assign a category as well, use the following
                      structure:
                    </li>
                  </ul>
                  <br />
                  <Code>
                    {'<document category>'} - {'<document name>'}
                    .format
                  </Code>
                  <br />
                  <em>Example: Contract - employment_contract.pdf</em>
                </Banner.Description>
              </Banner.Content>
            </Banner>
          ) : (
            <Banner>
              <Banner.Content>
                <Banner.Title>
                  Rename your documents as followed and automatically assign them to
                  employees and category
                </Banner.Title>
                <Banner.Description>
                  <br />
                  Rename your documents using this structure to automatically assign them
                  to an employee: <br />
                  <Code>
                    {"<employee's email>"} - {'<document name>'}.format
                  </Code>
                  . You should use <Code>{' - '}</Code> as a separator.
                  <br />
                  <em>Example: john.doe@email.com - employment_contract.pdf</em>
                  <br />
                  <Text variant="bullet">Automatically assign a category</Text>
                  <br />
                  To automatically assign a category as well, use the following structure:
                  <br />
                  <Code>
                    {"<employee's email>"} - {'<document category>'} - {'<document name>'}
                    .format
                  </Code>
                  <br />
                  <em>
                    Example: john.doe@email.com - Contract - employment_contract.pdf
                  </em>
                </Banner.Description>
              </Banner.Content>
            </Banner>
          )}

          <Group>
            <Box p="s-12">
              <FileUploader
                onChange={async event => {
                  if (Array.isArray(event)) {
                    if (!files.length && !sessionId) {
                      const response = await createDocumentUploadSession()
                      setSessionId(response.data.id)
                    }

                    setFiles(prev => [
                      ...event.map(file => ({
                        tempId: Math.random(),
                        file,
                        state: 'pending' as const,
                      })),
                      ...prev,
                    ])
                  }
                }}
                multiple
                directory={isOrganizeByEmployeeMode}
                attachButtonText="Click to attach documents"
                accept={
                  'image/jpeg, image/png, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .csv, text/csv, application/csv'
                }
                bottomInfo="Allowed document formats: .pdf, .jpeg, .jpg, .png, .xlsx, .csv"
              />
            </Box>

            {files.map(file => (
              <FileItem {...file} key={file.tempId} />
            ))}
          </Group>
        </VStack>
      </PageBody>

      {customActions?.(continueDisabled, sessionId) || (
        <PageActions>
          <Button
            onClick={() =>
              navigateReplace(
                pathToUrl(ROUTES.FORMS.IMPORT_DATA.DOCUMENTS.SESSION, { id: sessionId }),
              )
            }
            disabled={continueDisabled}
            elevated
          >
            Continue
          </Button>
        </PageActions>
      )}
    </>
  )
}

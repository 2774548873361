import React from 'react'
import styled from 'styled-components'
import { PageBody } from '@components/Page/PageBody'
import { Box, BREAKPOINTS, Flex, Text, Widget } from '@revolut/ui-kit'
import CareersPositionLocationsList from '@src/pages/Careers/CareersPosition/CareersPositionLocationsList'
import { useParams } from 'react-router-dom'
import { useFetchPosition } from '@src/api/recruitment/careers'
import { getIdFromPositionSlug } from '@src/pages/Careers/utils'
import DOMPurify from 'dompurify'
import CareersPositionApplyButton from '@src/pages/Careers/CareersPosition/CareersPositionApplyButton'
import CareersPositionMetadata from '@src/pages/Careers/CareersPosition/CareersPositionMetadata'

const Content = styled(Box)`
  wordBreak: 'break-word';
  lineHeight: 1.5;
  margin: auto;
  
  a: {
    color: blue;
  },
`

const CareersPosition = () => {
  const { id } = useParams<{ id: string }>()
  const { data: position } = useFetchPosition(getIdFromPositionSlug(id))

  if (!position) {
    return null
  }

  return (
    <PageBody maxWidth={BREAKPOINTS.lg}>
      <CareersPositionMetadata position={position} />
      <Box pb="s-56">
        <Text variant="h1" textAlign="center" display="block" mb="s-16">
          {position?.text}
        </Text>
        <Flex justifyContent="center" mx={{ all: 0, lg: '-s-32' }}>
          <CareersPositionLocationsList locations={position.locations} />
        </Flex>
        <Box hide="*-lg" mt="s-24">
          <Flex justifyContent="center">
            <CareersPositionApplyButton
              positionId={position.id}
              positionTitle={position.text}
            />
          </Flex>
        </Box>
        <Widget p="s-32" mt="s-72">
          <Text variant="secondary">
            <Content
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(position.description),
              }}
            />
          </Text>

          <Flex justifyContent="center" mt="s-32">
            <CareersPositionApplyButton
              positionId={position.id}
              positionTitle={position.text}
            />
          </Flex>
        </Widget>
      </Box>
    </PageBody>
  )
}

export default CareersPosition

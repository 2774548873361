import { PageBody } from '@src/components/Page/PageBody'
import React from 'react'
import Navigation from './Navigation'
import { selectorKeys } from '@src/constants/api'
import { Redirect, useLocation } from 'react-router-dom'
import SelectFields from '@src/components/BulkEdit/SelectFields'
import { createContractsBulkEdit } from '@src/api/contractsBulkEdit'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { BulkEditData } from '@src/interfaces/bulkEdit'

const FIELDS = [
  {
    id: 'specialisation',
    name: 'Specialisation',
    selector: selectorKeys.approved_specialisations,
  },
  {
    id: 'pay_group',
    name: 'Paygroup',
    selector: selectorKeys.active_pay_groups,
  },
  {
    id: 'seniority',
    name: 'Seniority',
    selector: selectorKeys.seniority,
  },
]

const SelectFieldsPage = () => {
  const { state: bulkEditData } = useLocation<BulkEditData>()

  if (!bulkEditData || bulkEditData?.selectedIds?.length === 0) {
    return <Redirect to={pathToUrl(ROUTES.FORMS.BULK_EDIT_CONTRACTS.SELECT)} />
  }

  return (
    <PageBody pb="s-32">
      <Navigation selectedIds={bulkEditData?.selectedIds || []} />
      <SelectFields
        fields={FIELDS}
        createBulkEdit={createContractsBulkEdit}
        nextStepRoute={ROUTES.FORMS.BULK_EDIT_CONTRACTS.REVIEW}
      />
    </PageBody>
  )
}

export default SelectFieldsPage

import { useMemo } from 'react'
import { TalentType } from '@src/interfaces/talent/talent'
import { OptionInterface } from '@src/interfaces/selectors'

export const isCompanyType = (type: TalentType) => type === TalentType.Company

export const isDepartmentType = (talentType: TalentType) =>
  talentType === TalentType.Team || talentType === TalentType.Department

export const isFunctionType = (talentType: TalentType) =>
  talentType === TalentType.Function ||
  talentType === TalentType.Role ||
  talentType === TalentType.Specialisation

export const performanceClarification =
  'These stats are calculated using most recent performance grades and don’t depend on the chosen cycle'

export const useGetCycleSelector = (selector: OptionInterface[]) => {
  const buildCycleSelector = async (): Promise<{
    options: OptionInterface[]
  }> => {
    return {
      options:
        selector?.map(cycle => {
          return {
            id: cycle.cycle_id!,
            name: cycle.name,
          }
        }) || [],
    }
  }

  return useMemo(() => buildCycleSelector, [selector])
}

export const isActivePerformanceCycle = (
  id: number | string,
  cycles: OptionInterface[],
): boolean => {
  return !!cycles.find(({ cycle_id }) => Number(id) === Number(cycle_id))
    ?.performance_reviews_selected_cycle
}

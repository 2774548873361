import React from 'react'

import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import { BudgetPoolInterface } from '@src/interfaces/budgetPools'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { getStatusColor } from '@src/components/CommonSC/General'

export const budgetPoolNameColumn: ColumnInterface<BudgetPoolInterface> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'id',
  selectorsKey: selectorKeys.budget_pool_configs,
  title: 'Budget pool',
}

export const budgetPoolTypeColumn: ColumnInterface<BudgetPoolInterface> = {
  type: CellTypes.text,
  idPoint: 'pool_type.id',
  dataPoint: 'pool_type.name',
  sortKey: 'pool_type',
  filterKey: 'pool_type',
  selectorsKey: selectorKeys.budget_pool_config_types,
  title: 'Type',
}

export const budgetPoolOwnerColumn: ColumnInterface<BudgetPoolInterface> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: 'owner__full_name',
  filterKey: 'owner__id',
  selectorsKey: selectorKeys.employee,
  title: 'Owner',
  insert: ({ data }) => <UserWithAvatar {...data.owner} />,
}

export const budgetPoolHeadcountColumn: ColumnInterface<BudgetPoolInterface> = {
  type: CellTypes.text,
  filterType: FilterType.range,
  idPoint: 'headcount',
  dataPoint: 'headcount',
  sortKey: 'headcount',
  filterKey: 'headcount',
  selectorsKey: selectorKeys.none,
  title: 'Headcount',
}

export const budgetPoolCreatedOnColumn: ColumnInterface<BudgetPoolInterface> = {
  type: CellTypes.dateInUtc,
  filterType: FilterType.date,
  idPoint: 'creation_date_time',
  dataPoint: 'creation_date_time',
  sortKey: 'creation_date_time',
  filterKey: 'creation_date_time',
  selectorsKey: selectorKeys.none,
  title: 'Created on',
}

export const budgetPoolStatusColumn: ColumnInterface<BudgetPoolInterface> = {
  type: CellTypes.text,
  idPoint: 'status.id',
  dataPoint: 'status.name',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.budget_pool_config_statuses,
  colors: data => getStatusColor(data.status.id),
  title: 'Status',
}

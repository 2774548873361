import { Delete, People, Plus } from '@revolut/icons'
import {
  ActionButton,
  Avatar,
  Box,
  Button,
  Checkbox,
  FilterButton,
  Flex,
  Text,
  Group,
  H6,
  IconButton,
  Item,
  Search,
  chain,
  Bar,
  List,
  ItemSkeleton,
} from '@revolut/ui-kit'
import {
  useAudiences,
  useCompReviewSettings,
  patchCompReviewSettings,
} from '@src/api/compSettings'
import { useGetSelectors } from '@src/api/selectors'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import { Audience, CompReviewSettings } from '@src/interfaces/compSettings'
import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { groupBy, uniq, debounce } from 'lodash'
import SideBar from '@src/components/SideBar/SideBar'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { PageBody } from '@src/components/Page/PageBody'
import PageError from '@src/components/PageError'
import { selectorKeys } from '@src/constants/api'
import { IdAndName } from '@src/interfaces'

const nameAndHeadcount = (audience?: Audience) => {
  return chain(audience?.audience_type?.name, `${audience?.headcount} employees`)
}

type CellAddItemProps = {
  onClick: () => void
}

const DEBOUNCE_TIME = 500

const CellAddItem = ({ onClick }: CellAddItemProps) => {
  return (
    <Item onClick={onClick} use="button" mb="s-8">
      <Item.Avatar>
        <Avatar useIcon={Plus} />
      </Item.Avatar>
      <Item.Content>
        <Item.Title color="blue">Add members</Item.Title>
      </Item.Content>
    </Item>
  )
}

type CellItemProps = {
  name: string
  description: string | ReactNode[]
  onDelete?: () => void
}

const CellItem = ({ name, description, onDelete }: CellItemProps) => {
  return (
    <Item>
      <Item.Avatar>
        <Avatar useIcon={People} />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{name}</Item.Title>
        <Item.Description>{description}</Item.Description>
      </Item.Content>
      <Item.Side>
        <IconButton
          useIcon={Delete}
          color="grey-80"
          onClick={onDelete}
          title={`Delete ${name}`}
        />
      </Item.Side>
    </Item>
  )
}

type SelectableCellItemProps = Omit<CellItemProps, 'onDelete'> & {
  selected: boolean
  onClick: () => void
}

const SelectableCellItem = ({
  name,
  description,
  selected,
  onClick,
}: SelectableCellItemProps) => {
  return (
    <Item>
      <Item.Prefix>
        <Checkbox checked={selected} onChange={onClick} aria-label={name} />
      </Item.Prefix>
      <Item.Avatar>
        <Avatar useIcon={People} />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{name}</Item.Title>
        <Item.Description>{description}</Item.Description>
      </Item.Content>
    </Item>
  )
}

type AddItemModalProps = {
  open: boolean
  setOpen: (isOpen: boolean) => void
  refetchSettings: () => void
  currentSettings: CompReviewSettings
  addLocation: LocationType
}

const AddItemModal = ({
  open,
  setOpen,
  refetchSettings,
  currentSettings,
  addLocation,
}: AddItemModalProps) => {
  const [searchQuery, setSearchQuery] = useState('')

  const [selectedItems, setSelectedItems] = useState<number[]>([])
  const [selectedFilters, setSelectedFilters] = useState<string[]>([])
  const [page, setPage] = useState(1)
  const selectedCount = selectedItems.length

  const addAudiences = async (audienceIds: number[]) => {
    const newAudiences = audienceIds.map(id => {
      const audience = audiences?.results.find(aud => aud.id === id)
      return {
        id: audience?.id,
        name: nameAndHeadcount(audience),
      }
    })

    await patchCompReviewSettings({
      [addLocation]: [...currentSettings[addLocation], ...newAudiences],
    })

    refetchSettings()
    setSearchQuery('')
    setSelectedItems([])
  }

  const debouncedSetSearchQuery = useCallback(debounce(setSearchQuery, DEBOUNCE_TIME), [])

  const { data: audiences, isLoading } = useAudiences({
    search: searchQuery,
    audience_type: selectedFilters.join(','),
    page,
  })

  useEffect(() => {
    setPage(1)
  }, [searchQuery, selectedFilters])

  const { data: selectors } = useGetSelectors<IdAndName<string>>(
    selectorKeys.audience_types,
  )

  const toggleFilter = (filter: string) => {
    if (selectedFilters.includes(filter)) {
      setSelectedFilters(selectedFilters.filter(f => f !== filter))
      return
    }

    setSelectedFilters([...selectedFilters, filter])
  }

  const audienceResults = audiences?.results

  const allVisibleSelected =
    audienceResults?.every(audience => selectedItems.includes(audience.id)) &&
    audienceResults.length > 0

  const toggleSelectAllVisible = () => {
    if (!audienceResults) {
      return
    }

    if (allVisibleSelected) {
      setSelectedItems([])
      return
    }

    const allVisibleIds = audienceResults.map(audience => audience.id)

    setSelectedItems(uniq([...selectedItems, ...allVisibleIds]))
  }

  const audiencesByType = groupBy(
    audienceResults,
    audience => audience.audience_type.name,
  )

  const onSelect = (id: number) => {
    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter(item => item !== id))
    } else {
      setSelectedItems([...selectedItems, id])
    }
  }

  return (
    <SideBar
      isOpen={open}
      onClose={() => setOpen(false)}
      variant="wide"
      title="Add members"
      data-testid="add-members-modal"
    >
      <Search onChange={value => debouncedSetSearchQuery(value)} placeholder="Search" />
      <Flex overflow="auto" gap="s-4" my="s-16">
        {selectors?.map(selector => (
          <FilterButton
            key={selector.id}
            active={selectedFilters.includes(selector.id)}
            onClick={() => toggleFilter(selector.id)}
          >
            {selector.name}
          </FilterButton>
        ))}
      </Flex>
      <Item>
        <Item.Prefix>
          <Checkbox checked={allVisibleSelected} onChange={toggleSelectAllVisible} />
        </Item.Prefix>
        <Item.Content>
          <Item.Title>Select all</Item.Title>
        </Item.Content>
      </Item>
      <Flex flexDirection="column" gap="s-12">
        {isLoading && (
          <List gap="s-12" mt="s-12">
            {[...Array(10)].map((_, i) => (
              <ItemSkeleton key={i} />
            ))}
          </List>
        )}
        {Object.keys(audiencesByType).map(audienceType => (
          <Box key={audienceType}>
            <H6 color="grey-tone-50" fontWeight={500} my="s-16">
              {audienceType}
            </H6>
            <Group>
              {audiencesByType[audienceType].map((audience: Audience) => (
                <SelectableCellItem
                  name={audience.name}
                  key={audience.id}
                  description={`${audience.headcount} employees`}
                  selected={selectedItems.includes(audience.id)}
                  onClick={() => onSelect(audience.id)}
                />
              ))}
            </Group>
          </Box>
        ))}
      </Flex>
      {!!audiences && audiences.pages.total > 1 && (
        <Bar alignSelf="center" mt="s-16">
          <ActionButton onClick={() => setPage(page - 1)} disabled={page === 1}>
            &lt;
          </ActionButton>
          <Text>
            Page {page} of {audiences?.pages.total}
          </Text>
          <ActionButton
            onClick={() => setPage(page + 1)}
            disabled={!audiences?.pages.next}
          >
            &gt;
          </ActionButton>
        </Bar>
      )}
      <Button
        onClick={() => {
          addAudiences(selectedItems)
          setOpen(false)
        }}
        mt="s-24"
        disabled={selectedCount === 0}
      >
        Add selected {selectedCount > 0 && `(${selectedCount})`}
      </Button>
    </SideBar>
  )
}

type LocationType = 'all_employees' | 'eligible' | 'eligible_for_bonus'

const Settings = () => {
  const { data, isLoading, refetch, isError } = useCompReviewSettings()

  const { data: audiences, isError: audiencesError } = useAudiences()

  const [isAddModalOpen, setIsAddModalOpen] = useState(false)
  const [addLocation, setAddLocation] = useState<LocationType>('all_employees')

  const onAddItem = (location: LocationType) => {
    setIsAddModalOpen(true)
    setAddLocation(location)
  }

  if (isError || audiencesError) {
    return (
      <PageError
        title="Sorry!"
        text="There was an error loading the settings. Please try again later."
      />
    )
  }

  if (isLoading || !audiences || !data) {
    return <PageLoading />
  }

  const deleteAudience = async (audienceId: number, location: LocationType) => {
    await patchCompReviewSettings({
      [location]: data[location].filter(
        (audience: Audience) => audience.id !== audienceId,
      ),
    })

    refetch()
  }

  return (
    <>
      <PageBody>
        <AutoStepper>
          <NewStepperTitle title="Compensation eligibility groups" />
          <Flex flexDirection="column" gap="s-4">
            <Box>
              <H6 color="grey-tone-50" fontWeight={500} my="s-4">
                Group 1: Employees that should appear in the compensation review tool
              </H6>
              <CellAddItem onClick={() => onAddItem('all_employees')} />
              <Group>
                {data?.all_employees.map(item => (
                  <CellItem
                    name={item.name}
                    key={item.id}
                    description={nameAndHeadcount(item)}
                    onDelete={() => deleteAudience(item.id, 'all_employees')}
                  />
                ))}
              </Group>
            </Box>
            <Box>
              <H6 color="grey-tone-50" fontWeight={500} my="s-4">
                Group 2: Employees eligible for compensation review
              </H6>
              <CellAddItem onClick={() => onAddItem('eligible')} />
              <Group>
                {data?.eligible.map(item => (
                  <CellItem
                    name={item.name}
                    key={item.id}
                    description={nameAndHeadcount(item)}
                    onDelete={() => deleteAudience(item.id, 'eligible')}
                  />
                ))}
              </Group>
            </Box>
            <Box>
              <H6 color="grey-tone-50" fontWeight={500} my="s-4">
                Group 3: Employees eligible for bonus
              </H6>
              <CellAddItem onClick={() => onAddItem('eligible_for_bonus')} />
              <Group>
                {data?.eligible_for_bonus.map(item => (
                  <CellItem
                    name={item.name}
                    key={item.id}
                    description={nameAndHeadcount(item)}
                    onDelete={() => deleteAudience(item.id, 'eligible_for_bonus')}
                  />
                ))}
              </Group>
            </Box>
          </Flex>
        </AutoStepper>
      </PageBody>
      <AddItemModal
        open={isAddModalOpen}
        setOpen={setIsAddModalOpen}
        refetchSettings={refetch}
        currentSettings={data}
        addLocation={addLocation}
      />
    </>
  )
}

export default Settings

import React, { PropsWithChildren } from 'react'
import { useParams } from 'react-router-dom'

import { performanceReviewLayoutRequests } from '@src/api/performanceReview'
import { connect } from 'lape'
import { Flex, VStack } from '@revolut/ui-kit'
import {
  ReviewCategory,
  ReviewScorecardInterface,
  ReviewerRelation,
} from '@src/interfaces/performance'
import { useLapeContext } from '@src/features/Form/LapeForm'
import PerformanceCycleClosed from '@components/ScorecardGeneral/PerformanceCycleClosed'
import Form from '@src/features/EmployeePerformance/Form'
import PerformancePage from '@src/pages/Forms/EmployeePerformance/components/PerformancePage'
import PerformanceErrorWidget from '@src/pages/Forms/EmployeePerformance/components/PerformanceErrorWidget'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { usePutFirstScorecardOpening } from '@src/utils/performance'
import { SummarySidebarProvider } from '@src/features/EmployeePerformance/SummarySidebarProvider'
import { ScorecardContent } from '@src/pages/Forms/EmployeePerformanceLayout/ScorecardContent'
import { PerformanceHeader } from '@src/pages/Forms/EmployeePerformanceLayout/components/PerformanceHeader'
import PerformanceHeaderCompact from '@src/pages/Forms/EmployeePerformance/components/PerformanceHeader'

const EmptyWrapper = ({ children }: PropsWithChildren<unknown>) => <>{children}</>

const EmployeePerformance = connect(() => {
  const { values } = useLapeContext<ReviewScorecardInterface>()
  const { data: performanceSettings } = useGetPerformanceSettings()
  usePutFirstScorecardOpening(values, ReviewCategory.Performance)

  if (!performanceSettings) {
    return <PageLoading />
  }

  if (!values.review_data) {
    return <PerformanceErrorWidget />
  }

  const shouldWrapWithSummaryProvider =
    values.category === ReviewCategory.Performance &&
    values.reviewer_relation &&
    [
      ReviewerRelation.FunctionalManager,
      ReviewerRelation.LineManager,
      ReviewerRelation.HeadOfFunction,
    ].includes(values.reviewer_relation)

  const SummaryProvider = shouldWrapWithSummaryProvider
    ? SummarySidebarProvider
    : EmptyWrapper

  return (
    <PerformancePage showHeader={false}>
      {!values.current_user_can_edit_review ? (
        <>
          <PerformanceHeaderCompact />
          <PerformanceCycleClosed employeeId={values.reviewed_employee.id!} />
        </>
      ) : (
        <>
          <Flex flex="1 0">
            <SummaryProvider
              hideCategoryFilter
              category={values.category}
              employeeSeniorityId={3}
              cycleId={String(values.cycle?.id)}
              employeeId={values.reviewed_employee.id}
              cycleName={values.cycle?.name}
            >
              {shouldWrapWithSummaryProvider ? (
                <SummarySidebarProvider
                  hideCategoryFilter
                  category={values.category}
                  employeeSeniorityId={values.reviewed_employee.seniority?.id}
                  cycleId={String(values.cycle?.id)}
                  employeeId={values.reviewed_employee.id}
                  cycleName={values.cycle?.name}
                >
                  <VStack width="100%">
                    <PerformanceHeader />
                    <ScorecardContent />
                  </VStack>
                </SummarySidebarProvider>
              ) : (
                <VStack width="100%">
                  <PerformanceHeader />
                  <ScorecardContent />
                </VStack>
              )}
            </SummaryProvider>
          </Flex>
        </>
      )}
    </PerformancePage>
  )
})

export const EmployeePerformanceLayout = connect(() => {
  const { id, employeeId } = useParams<{ id: string; employeeId: string }>()

  return (
    <Form
      api={performanceReviewLayoutRequests}
      storageKey={`${ReviewCategory.Performance}-${id}-${employeeId}`}
    >
      <EmployeePerformance />
    </Form>
  )
})

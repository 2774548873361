import React from 'react'
import { LinkExternal } from '@revolut/icons'
import { Button, ButtonProps } from '@revolut/ui-kit'
import { ReviewCategory } from '@src/interfaces/performance'
import { useLocation } from 'react-router-dom'
import { getReviewsUrl } from './common'

interface Props extends ButtonProps {
  category?: ReviewCategory
  cycleId?: string
  employeeId?: number
}

const OpenReviewButton = ({ category, cycleId, employeeId, ...buttonProps }: Props) => {
  const location = useLocation()

  return (
    <Button
      use="a"
      useIcon={LinkExternal}
      variant="secondary"
      size="sm"
      href={getReviewsUrl(category, cycleId, employeeId, location)}
      target="_blank"
      data-testid="open-review-btn"
      {...buttonProps}
    >
      Open Review
    </Button>
  )
}

export default OpenReviewButton

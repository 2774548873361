import React from 'react'
import { useGetKPI } from '@src/api/kpis'
import SideBar from '@components/SideBar/SideBar'
import { Flex, Link } from '@revolut/ui-kit'
import { Loading } from '@revolut/icons'
import Preview from '@src/pages/Forms/KpiForm/Preview'
import { getEntity } from '@src/utils/kpi'
import LapeForm from '../Form/LapeForm'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

interface KPIPreviewSideProps {
  isOpen: boolean
  kpiId: number | string | null
  onClose: () => void
}

const KPIPreviewSide = ({ isOpen, kpiId, onClose }: KPIPreviewSideProps) => {
  const { data, isLoading, isError } = useGetKPI(kpiId)

  return (
    <SideBar
      title={
        isError ? (
          'Error'
        ) : (
          <Link
            href={pathToUrl(ROUTES.FORMS.KPI.PREVIEW, { id: kpiId })}
            target="_blank"
            color="inherit"
          >
            {data?.name}
          </Link>
        )
      }
      subtitle={data && getEntity(data)}
      isOpen={isOpen}
      onClose={onClose}
      variant="wide"
    >
      {isLoading || isError ? (
        <Flex justifyContent="center" alignItems="center" height="100%">
          {isLoading ? <Loading size={24} color="grey-tone-50" /> : null}
        </Flex>
      ) : (
        <Flex height={0}>
          <LapeForm initialValues={data} onSubmit={() => Promise.resolve({})}>
            <Preview embedded data={data} />
          </LapeForm>
        </Flex>
      )}
    </SideBar>
  )
}

export default KPIPreviewSide

import React, { useState } from 'react'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import SideBar from '@components/SideBar/SideBar'
import { Profile } from '@revolut/icons'
import { Button, MoreBar, Portal, Side } from '@revolut/ui-kit'
import { bulkEditCandidate } from '@src/api/recruitment/candidates'
import { selectorKeys } from '@src/constants/api'
import { InterviewRecruiterInterface } from '@src/interfaces/interviewTool'
import pluralize from 'pluralize'
import BulkStatusPopup from '@src/components/BulkStatusPopup/BulkStatusPopup'

type ReassignRecruiterActionProps = {
  disabled: boolean
  onClick: () => void
}

export const ReassignRecruiterAction = ({
  disabled,
  onClick,
}: ReassignRecruiterActionProps) => {
  return (
    <MoreBar.Action onClick={onClick} disabled={disabled} useIcon={Profile}>
      Reassign recruiter
    </MoreBar.Action>
  )
}

type ReassignRecruiterSidebarProps = {
  candidateIds: string[]
  open: boolean
  onClose: () => void
}

export const ReassignRecruiterSidebar = ({
  candidateIds,
  open,
  onClose,
}: ReassignRecruiterSidebarProps) => {
  const [recruiter, setRecruiter] = useState<InterviewRecruiterInterface | null>(null)
  const [success, setSuccess] = useState<boolean | null>(null)
  const [loading, setLoading] = useState(false)
  const onSubmit = async () => {
    if (!recruiter) {
      return
    }
    try {
      setLoading(true)
      await bulkEditCandidate({
        candidate_ids: candidateIds,
        edit_fields: {
          recruiter: recruiter.id,
        },
      })
      setSuccess(true)
    } catch (e) {
      setSuccess(false)
    } finally {
      setLoading(false)
    }
  }
  const handleClose = () => {
    setRecruiter(null)
    setSuccess(null)
    onClose()
  }
  return (
    <>
      <SideBar
        isOpen={open}
        onClose={handleClose}
        title={`Reassign recruiter for ${pluralize(
          'candidates',
          candidateIds.length,
          true,
        )}`}
      >
        <RadioSelectInput<InterviewRecruiterInterface>
          label="Recruiter"
          selector={selectorKeys.employee}
          onChange={option => {
            if (option) {
              setRecruiter(option)
            }
          }}
          value={recruiter}
        />
        <Side.Actions horizontal>
          <Button variant="secondary" onClick={handleClose} autoFocus>
            Cancel
          </Button>
          <Button onClick={onSubmit} disabled={!recruiter || loading}>
            Submit
          </Button>
        </Side.Actions>
      </SideBar>
      <Portal>
        <BulkStatusPopup isSuccess={success} onClose={handleClose} />
      </Portal>
    </>
  )
}

export default ReassignRecruiterSidebar

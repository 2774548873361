import React, { useEffect } from 'react'
import { Box, Flex, Text } from '@revolut/ui-kit'
import useResizeObserver from 'use-resize-observer'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { TabBarTableNavigationInterface } from '@src/interfaces/data'
import { connect } from 'lape'
import { css } from 'styled-components'
import mainHeaderState from './MainHeaderState'
import { GlobalSearch } from './GlobalSearch'
import { HelpCenter } from './HelpCenter'
import { Settings } from '@src/features/MainHeader/Settings'
import { Warning } from '@src/features/MainHeader/Warning'
import { UserProfileLink } from '@src/features/MainHeader/UserProfileLink'
import Notifications from '../Notifications/Notifications'

interface MainHeaderProps {
  title?: string
  tabs?: TabBarTableNavigationInterface[] | null
  withGlobalSearch?: boolean
  children?: React.ReactNode
}

const stickCss = css<{ opacity?: boolean }>`
  position: sticky;
  top: 0;
  z-index: ${props => props.theme.zIndex.aboveMain + 1};
  background-color: ${p => p.theme.colors['layout-background']};
`

const containerCss = css`
  position: sticky;
  z-index: ${props => props.theme.zIndex.aboveMain + 1};
  top: 0;
`

const MainHeader = ({ title, tabs, withGlobalSearch, children }: MainHeaderProps) => {
  const { ref: stickyRef, height: headerHeight } = useResizeObserver()

  useEffect(() => {
    if (headerHeight) {
      mainHeaderState.height = headerHeight + 14
    }
  }, [headerHeight])

  return (
    <Box width="100%" pt="14px" css={stickCss} ref={stickyRef}>
      <Flex pb="s-16" justifyContent="space-between" alignItems="center">
        <Text variant="h5">{title}</Text>
        <Flex alignItems="center" gap="s-8">
          {withGlobalSearch && <GlobalSearch />}
          <Notifications />
          <HelpCenter />
          <Settings />
          <Warning />
          <UserProfileLink />
        </Flex>
      </Flex>
      <Box css={containerCss}>
        {children}
        {tabs != null ? (
          <Box mb="s-16">
            <TabBarNavigation isSmall tabs={tabs} />
          </Box>
        ) : null}
      </Box>
    </Box>
  )
}

export default connect(MainHeader)

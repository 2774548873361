import React, { useEffect, useState } from 'react'
import { Redirect, Route, Switch, useParams } from 'react-router-dom'

import { ROUTES } from '@src/constants/routes'
import { EmployeeInterface } from '@src/interfaces/employees'
import { getEmployeeWithManagerFilter } from '@src/api/employees'
import Loader from '@components/CommonSC/Loader'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { FormTitleObsolete } from '@src/pages/EmployeeProfile/Preview/components/FormTitleObsolete'
import Page404 from '@src/pages/Page404/Page404'
import { PerformanceHistory } from '@src/pages/EmployeeProfile/Preview/Performance/PerformanceHistory/PerformanceHistory'
import { pathToUrl } from '@src/utils/router'
import { Skills } from '@src/pages/EmployeeProfile/Preview/Performance/Skills'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { Flex } from '@revolut/ui-kit'

const backUrl = ROUTES.PEOPLE.EMPLOYEES

export const PerformanceLayoutHistory = () => {
  const params = useParams<{ id?: string }>()
  const { data: performanceSettings } = useGetPerformanceSettings()

  const [data, setData] = useState<EmployeeInterface>()
  const [loading, setLoading] = useState(params.id && !isNaN(parseFloat(params.id)))

  useEffect(() => {
    if (params.id && !isNaN(parseFloat(params.id))) {
      setLoading(true)
      getEmployeeWithManagerFilter(params.id, 'line_manager__id')
        .then(res => {
          setData(res.data)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [params.id])

  if (loading) {
    return (
      <PageWrapper>
        <Loader />
      </PageWrapper>
    )
  }
  if (!data) {
    return <Page404 />
  }

  const tabs = [
    {
      title: 'History' as const,
      path: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_TIMELINE.HISTORY,
      url: pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE_TIMELINE.HISTORY, params),
      component: PerformanceHistory,
      visibilityCheck: true,
    },
    {
      title: 'Skills' as const,
      path: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_TIMELINE.SKILLS,
      url: pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE_TIMELINE.SKILLS, params),
      component: Skills,
      visibilityCheck: !!performanceSettings?.enable_skill_assessment,
    },
  ]

  const filteredTabs = tabs.filter(tab => tab.visibilityCheck)

  return (
    <PageWrapper>
      <PageHeader title={<FormTitleObsolete data={data} />} backUrl={backUrl} />
      <Flex alignItems="flex-start" mb="s-16">
        <TabBarNavigation isSubtab tabs={filteredTabs} />
      </Flex>
      <Switch>
        {filteredTabs.map(tab => (
          <Route exact path={tab.path} key={tab.title}>
            <tab.component data={data} />
          </Route>
        ))}
        <Redirect to={tabs[0].path} />
      </Switch>
    </PageWrapper>
  )
}

import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { ROUTES, WORKSPACES } from '@src/constants/routes'
import Login from '@src/pages/Login/Login'
import PasswordChange from '@src/pages/Login/PasswordChange'
import { MagicLinkLogin } from '@src/pages/Login/MagicLinkLogin'
import { ResetPassword } from '@src/pages/Login/ResetPassword'
import { Absolute, Layout } from '@revolut/ui-kit'
import { Workspace } from './Workspace'
import { FindWorkspace } from './FindWorkspace'
import { isWorkspacesEnabled } from '@src/utils'
import { LoginThemeToggle } from '@src/features/UIKitWithThemeProvider/LoginThemeToggle'

const LoginRouter = () => {
  return (
    <>
      <Absolute zIndex={1} right={50} top={30}>
        <LoginThemeToggle />
      </Absolute>

      <Layout>
        <Layout.Actions style={{ padding: 0 }} />
        <Layout.Main>
          <Switch>
            <Route
              exact
              path={[ROUTES.LOGIN.MAIN, ROUTES.LOGIN.ONBOARDING]}
              component={Login}
            />
            <Route exact path={ROUTES.LOGIN.PASSWORD_CHANGE} component={PasswordChange} />
            <Route exact path={ROUTES.LOGIN.RESET_PASSWORD} component={ResetPassword} />
            <Route exact path={ROUTES.LOGIN.MAGIC_LINK} component={MagicLinkLogin} />
            <Route path={WORKSPACES.ANY} exact>
              {isWorkspacesEnabled() ? (
                <Switch>
                  <Route path={WORKSPACES.MAIN} exact>
                    <Workspace />
                  </Route>
                  <Route path={WORKSPACES.FIND} exact>
                    <FindWorkspace />
                  </Route>
                </Switch>
              ) : (
                <Redirect to={ROUTES.LOGIN.MAIN} />
              )}
            </Route>
          </Switch>
        </Layout.Main>
      </Layout>
    </>
  )
}

export default LoginRouter

import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams, Switch, Route, Redirect } from 'react-router-dom'
import { Flex } from '@revolut/ui-kit'

import { EmployeeInterface } from '@src/interfaces/employees'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import EmployeeSummary from './Summary'
import { Salary as EmployeeSalary } from './Salary'
import { Benefits as EmployeeBenefits } from './Benefits'
import { Benefits as EmployeeNewBenefits } from './NewBenefits'
import { Bonuses as EmployeeBonuses } from './Bonuses'
import { SalarySacrifice as EmployeeSalarySacrifice } from './SalarySacrifice'

interface Props {
  data: EmployeeInterface
}

export const Rewards = (props: Props) => {
  const params = useParams<{ id: string }>()
  const permissions = useSelector(selectPermissions)

  const [currency, setCurrency] = useState<string>()

  const tabs = [
    {
      path: ROUTES.FORMS.EMPLOYEE.REWARDS.SUMMARY,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.REWARDS.SUMMARY, params),
      title: 'Summary',
      component: EmployeeSummary,
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.REWARDS.SALARY,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.REWARDS.SALARY, params),
      title: 'Salary',
      component: EmployeeSalary,
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.REWARDS.BONUSES,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.REWARDS.BONUSES, params),
      title: 'Bonuses',
      component: EmployeeBonuses,
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.REWARDS.BENEFITS,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.REWARDS.BENEFITS, params),
      title: 'Benefits',
      component: permissions.includes(PermissionTypes.ViewBenefits)
        ? EmployeeNewBenefits
        : EmployeeBenefits,
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.REWARDS.SALARY_SACRIFICE,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.REWARDS.SALARY_SACRIFICE, params),
      title: 'Salary Sacrifice',
      component: EmployeeSalarySacrifice,
    },
  ]

  return (
    <Flex flexDirection="column" flex={1} minHeight={0}>
      <Flex alignItems="flex-start" mb="s-12">
        <TabBarNavigation isSubtab tabs={tabs} />
      </Flex>
      <Switch>
        {tabs.map(tab => (
          <Route exact path={tab.path} key={tab.path}>
            <tab.component {...props} currency={currency} setCurrency={setCurrency} />
          </Route>
        ))}
        <Redirect to={tabs[0].path} />
      </Switch>
    </Flex>
  )
}

import { useFetch } from '@src/utils/reactQuery'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import { InterviewType, BulkEditCandidateInterface } from '@src/interfaces/interviewTool'
import { Statuses } from '@src/interfaces'
import { API } from '@src/constants/api'
import { apiWithoutHandling } from '@src/api/index'

export type CandidatesPipelineViewType = {
  id?: string
  label: string
  count: number
  filters?: (string | number)[]
  tab?: string
  stage_type: InterviewType
  status: Statuses
  filter_field_name?: string
}

export const useGetCandidatesPipelineView = (
  filters: FetchDataQueryInterface['filters'] | null,
  staleTime?: number,
) => {
  const context = useFetch<CandidatesPipelineViewType[]>(
    filters !== null ? '/interviews/candidates/pipelineStats' : null,
    undefined,

    {
      params: filterSortPageIntoQuery(undefined, filters || undefined),
    },
    undefined,
    {
      staleTime,
    },
  )

  return {
    ...context,
    data: context.data
      ?.filter(item => item.status !== Statuses.archived || item.count)
      ?.map(item => ({
        ...item,
        id: `${item.label}_${item.filters?.join('_')}`,
      })),
  }
}

export const bulkEditCandidate = (data: BulkEditCandidateInterface) =>
  apiWithoutHandling.post(API.CANDIDATE_BULK_EDIT, data)

export const useGetStages = (candidateIds?: string[], specialisationIds?: number[]) =>
  useFetch<CandidatesPipelineViewType[]>(
    candidateIds?.length ? `/interviews/candidates/pipelineStats` : null,
    undefined,
    {
      params: {
        id: candidateIds?.join(','),
        active_specialisation: specialisationIds?.join(','),
      },
    },
  )

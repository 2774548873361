import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import {
  Box,
  Button,
  InputGroup,
  Header,
  Link,
  MoreBar,
  Side,
  Token,
} from '@revolut/ui-kit'
import { connect } from 'lape'
import { ArrowThinRight, Plus } from '@revolut/icons'
import ActionWidget from '@src/components/ActionWidget/ActionWidget'
import Form from '@src/features/Form/Form'
import {
  contractingCountriesRequests,
  entitiesRequestsNew,
  getContractingCountries,
} from '@src/api/entities'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ContractingCountryInterface, EntityInterface } from '@src/interfaces/enitities'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import { selectorKeys } from '@src/constants/api'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import { RowInterface } from '@src/interfaces/data'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import SettingsButtons, { DeleteButton } from '@src/features/SettingsButtons'
import SideBar from '@components/SideBar/SideBar'
import { useTable } from '@src/components/Table/hooks'
import { regionHiringCountryColumn } from '@src/constants/columns/entity'
import { navigateReplace } from '@src/actions/RouterActions'
import { PermissionTypes } from '@src/store/auth/types'
import { newTabTo, pathToUrl } from '@src/utils/router'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { TableNames } from '@src/constants/table'
import { getPaygroups } from '@src/api/payroll'
import { PaygroupInterface } from '@src/interfaces/payroll'
import {
  paygroupCountry,
  paygroupHeadcount,
  paygroupName,
  paygroupStatus,
} from '@src/constants/columns/paygroups'
import { useGetOrganisationSettings, useGetPayrollSettings } from '@src/api/settings'
import { selectPermissions } from '@src/store/auth/selectors'
import { goBack } from '@src/actions/RouterActions'
import LapeDeleteOrgUnitButton from '@src/features/SettingsButtons/DeleteOrgUnitButton/LapeDeleteOrgUnitButton'

const paygroupRows: RowInterface<PaygroupInterface> = {
  cells: [
    {
      ...paygroupCountry,
      width: 150,
    },
    {
      ...paygroupName,
      width: 220,
    },
    {
      ...paygroupHeadcount,
      width: 70,
    },
    {
      ...paygroupStatus,
      width: 100,
    },
  ],
}

const regionRows: RowInterface<ContractingCountryInterface> = {
  cells: [
    {
      ...regionHiringCountryColumn,
      width: 100,
    },
    {
      ...paygroupStatus,
      width: 300,
    },
  ],
}

interface EntitiesProps {
  isOpen?: boolean
  onAfterSave?: () => void
  onClose?: () => void
  setSelectedPaygroup: (paygroup?: PaygroupInterface) => void
  setRegionFormVisible: (visible: boolean) => void
  setSelectedRegion: (paygroup?: ContractingCountryInterface) => void
  setEntityId: (id: number) => void
  refreshPaygroups: React.MutableRefObject<() => void>
  refreshRegions: React.MutableRefObject<() => void>
  useSidebar?: boolean
}

export const Entities = ({
  isOpen,
  onAfterSave,
  onClose,
  setRegionFormVisible,
  setSelectedRegion,
  setEntityId,
  refreshPaygroups,
  refreshRegions,
  useSidebar,
}: EntitiesProps) => {
  const { values } = useLapeContext<EntityInterface>()
  const permissions = useSelector(selectPermissions)

  const { data: organisationSettings } = useGetOrganisationSettings()
  const { data: payrollSettings } = useGetPayrollSettings()

  useEffect(() => {
    refreshPaygroups.current = table.refresh
    refreshRegions.current = contractingCountriesTable.refresh
  }, [])

  useEffect(() => {
    setEntityId(values.id)
  }, [values.id])

  const table = useTable({ getItems: getPaygroups }, [
    {
      filters: [{ name: `${values.id}`, id: values.id }],
      columnName: 'company_entity__id',
      nonResettable: true,
    },
  ])
  const contractingCountriesTable = useTable({ getItems: getContractingCountries }, [
    {
      filters: [{ name: `${values.id}`, id: values.id }],
      columnName: 'entity__id',
      nonResettable: true,
    },
  ])

  const canViewPaygroup = permissions.includes(PermissionTypes.ViewPaygroup)
  const showPaygroups = Boolean(canViewPaygroup && payrollSettings?.enabled && values.id)

  const backUrl = ROUTES.APPS.ENTITIES

  const renderContent = () => {
    return (
      <AutoStepper>
        <NewStepperTitle title="Entity details" />

        <InputGroup>
          <LapeNewInput name="name" label="Name" required />
          <LapeRadioSelectInput
            name="registration_country"
            label="Registration country"
            selector={selectorKeys.countries}
          />
          <LapeNewInput name="registration_number" label="Registration number" required />
          <LapeNewTextArea name="description" label="Description" rows={4} />
        </InputGroup>

        {!organisationSettings?.enable_location_limitations && showPaygroups && (
          <>
            <NewStepperTitle title="Pay groups" />

            <ActionWidget
              title="Pay groups have to be configured in the payroll app"
              text="Please go to payroll app to add pay groups for existing entity."
            >
              <Button
                size="sm"
                variant="secondary"
                useIcon={ArrowThinRight}
                use={Link}
                href={pathToUrl(ROUTES.SETTINGS.PAYROLL.CONFIGURATION)}
                target="_blank"
              >
                Go to payroll
              </Button>
            </ActionWidget>

            <Box mt="s-16">
              <AdjustableTable
                name={TableNames.EntityPaygroups}
                {...table}
                noDataMessage="No paygroups"
                row={paygroupRows}
                hideCount
                onRowClick={data => {
                  newTabTo(
                    pathToUrl(ROUTES.APPS.PAYROLL.PAY_GROUP.PREVIEW, {
                      id: data.id,
                    }),
                  )
                }}
              />
            </Box>
          </>
        )}

        {!!organisationSettings?.enable_location_limitations && (
          <>
            {showPaygroups && (
              <>
                <NewStepperTitle title="Internal hiring (Paygroups)" />
                <ActionWidget
                  title="We can only hire employees in active paygroups"
                  avatarColor={Token.color.red}
                  text="Some roles can only be hired in certain entities, if you want a specific location to be available for hiring you must make it active as a paygroup."
                >
                  <Button
                    size="sm"
                    variant="secondary"
                    useIcon={ArrowThinRight}
                    use={Link}
                    href={pathToUrl(ROUTES.SETTINGS.PAYROLL.CONFIGURATION)}
                    target="_blank"
                  >
                    Go to payroll
                  </Button>
                </ActionWidget>

                <Box mt="s-16">
                  <AdjustableTable
                    name={TableNames.EntityPaygroups}
                    {...table}
                    noDataMessage="No paygroups"
                    row={paygroupRows}
                    hideCount
                    onRowClick={data => {
                      newTabTo(
                        pathToUrl(ROUTES.APPS.PAYROLL.PAY_GROUP.PREVIEW, {
                          id: data.id,
                        }),
                      )
                    }}
                  />
                </Box>
              </>
            )}

            <NewStepperTitle title="External hiring" />
            {values.id ? (
              <>
                <ActionWidget
                  title="External employees cannot be treated as internal employees"
                  avatarColor={Token.color.red}
                  text="Unless specified otherwise (by the risk and legal department) external employees are not eligible for performance reviews, probation periods, benefits, promotions, etc."
                >
                  <Button
                    size="sm"
                    variant="secondary"
                    useIcon={Plus}
                    onClick={() => {
                      setSelectedRegion(undefined)
                      setRegionFormVisible(true)
                    }}
                  >
                    Add region
                  </Button>
                </ActionWidget>

                <Box mt="s-16">
                  <AdjustableTable
                    name={TableNames.EntityRegions}
                    {...contractingCountriesTable}
                    noDataMessage="No regions"
                    row={regionRows}
                    hideCount
                    onRowClick={data => {
                      setSelectedRegion(data)
                      setRegionFormVisible(true)
                    }}
                  />
                </Box>
              </>
            ) : (
              <ActionWidget
                title="You must register an entity before creating regions"
                avatarColor={Token.color.red}
              />
            )}
          </>
        )}
      </AutoStepper>
    )
  }

  const renderSettingsButtons = () => {
    return (
      <>
        {values.id && (
          <MoreBar>
            <LapeDeleteOrgUnitButton
              deleteApi={entitiesRequestsNew.delete!}
              displayName="entity"
              onAfterDelete={() => goBack(backUrl)}
            />
          </MoreBar>
        )}
      </>
    )
  }

  if (!useSidebar) {
    return (
      <PageWrapper>
        <PageHeader title={values.id ? values.name : 'New entity'} backUrl={backUrl} />
        <PageBody>
          {renderSettingsButtons()}
          {renderContent()}
        </PageBody>
        <PageActions>
          <NewSaveButtonWithPopup
            successText="Entity saved successfully"
            onAfterSubmit={entity => {
              table.refresh()
              navigateReplace(pathToUrl(ROUTES.FORMS.ENTITY.GENERAL, { id: entity.id! }))
            }}
            useValidator
          />
        </PageActions>
      </PageWrapper>
    )
  }

  return (
    <SideBar
      headerContent={<Header.Bar>{renderSettingsButtons()}</Header.Bar>}
      isOpen={isOpen}
      onClose={onClose}
      title={values.id ? values.name : 'New entity'}
      variant="wide"
    >
      {renderContent()}
      <Side.Actions>
        <NewSaveButtonWithPopup
          successText="Entity saved successfully"
          onAfterSubmit={onAfterSave}
          useValidator
        />
      </Side.Actions>
    </SideBar>
  )
}

interface RegionSideHelpFormProps {
  entityId?: number
  onRegionRefresh: () => void
  onCloseRegion: () => void
}

export const RegionSideHelpForm = ({
  entityId,
  onRegionRefresh,
  onCloseRegion,
}: RegionSideHelpFormProps) => {
  const { values } = useLapeContext<ContractingCountryInterface>()

  useEffect(() => {
    if (!values.id && entityId) {
      values.entity = { id: entityId, name: '' }
    }
  }, [entityId, values.id])

  return (
    <>
      {values.id && (
        <SettingsButtons mb="s-16">
          <DeleteButton
            deleteApi={contractingCountriesRequests.delete!}
            title="Region"
            onAfterDelete={() => {
              onRegionRefresh()
              onCloseRegion()
            }}
          />
        </SettingsButtons>
      )}

      <InputGroup>
        <LapeRadioSelectInput
          name="country"
          label="Country"
          selector={selectorKeys.countries}
        />
        <LapeRadioSelectInput
          name="status"
          label="Status"
          selector={selectorKeys.contracting_country_status}
          required
        />
      </InputGroup>

      <NewSaveButtonWithPopup
        noPopup
        onAfterSubmit={() => {
          onRegionRefresh()
          onCloseRegion()
        }}
        mt="s-16"
        useValidator
      />
    </>
  )
}

export default connect(() => {
  const [regionFormVisible, setRegionFormVisible] = useState(false)
  const [selectedPaygroup, setSelectedPaygroup] = useState<PaygroupInterface>()
  const [selectedRegion, setSelectedRegion] = useState<ContractingCountryInterface>()
  const [entityId, setEntityId] = useState<number>()
  const [sideHelpRenderKey, setSideHelpRenderKey] = useState(0)

  const refreshPaygroups = useRef(() => {})
  const refreshRegions = useRef(() => {})

  useEffect(() => {
    setSideHelpRenderKey(prev => prev + 1)
  }, [selectedPaygroup, selectedRegion])

  const onCloseRegion = () => {
    setSelectedRegion(undefined)
    setRegionFormVisible(false)
  }

  return (
    <>
      <Form api={entitiesRequestsNew}>
        <Entities
          setSelectedPaygroup={setSelectedPaygroup}
          setRegionFormVisible={setRegionFormVisible}
          setSelectedRegion={setSelectedRegion}
          setEntityId={setEntityId}
          refreshPaygroups={refreshPaygroups}
          refreshRegions={refreshRegions}
        />
      </Form>
      <SideBar
        isOpen={regionFormVisible}
        onClose={onCloseRegion}
        title={`${selectedRegion ? 'Edit' : 'Add'} region`}
      >
        <Form
          api={contractingCountriesRequests}
          forceParams={{
            ...(selectedRegion?.id ? { id: `${selectedRegion.id}` } : { new: 'new' }),
          }}
          key={sideHelpRenderKey}
        >
          <RegionSideHelpForm
            entityId={entityId}
            onRegionRefresh={refreshRegions.current}
            onCloseRegion={onCloseRegion}
          />
        </Form>
      </SideBar>
    </>
  )
})

import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  Bar,
  Button,
  Flex,
  Subheader,
  Link,
  InputGroup,
  Copyable,
  DetailsCell,
} from '@revolut/ui-kit'
import { Plus, Minus } from '@revolut/icons'
import { connect } from 'lape'
import styled from 'styled-components'
import set from 'lodash/set'

import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageBody } from '@src/components/Page/PageBody'
import { ROUTES } from '@src/constants/routes'
import { useLapeContext } from '@src/features/Form/LapeForm'
import Form from '@src/features/Form/Form'
import { customFieldsRequest } from '@src/api/customFields'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { CustomFieldsInterface, SectionOptions } from '@src/interfaces/customFields'
import { CustomFieldTypes } from '@src/constants/customFields'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import { selectorKeys } from '@src/constants/api'
import LapeNewRadioButtons from '@src/components/Inputs/LapeFields/LapeNewRadioButtons'
import { selectUser, canDeleteCustomField } from '@src/store/auth/selectors'
import LapeNewMultiSelect from '@src/components/Inputs/LapeFields/LapeNewMultiSelect'
import { pathToUrl } from '@src/utils/router'
import { DeleteButtonLape } from '../FormButtons'

import { ArchiveButton } from './ArchiveButton'
import { Statuses } from '@src/interfaces'
import { PageActions } from '@src/components/Page/PageActions'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { navigateReplace } from '@src/actions/RouterActions'
import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { useQuery } from '@src/utils/queryParamsHooks'
import { FilterTable } from '@src/pages/Forms/CustomFieldsForm/FilterTable/FilterTable'

const OptionsForDropdownSubheader = styled(Subheader)`
  padding-bottom: 0;
`

const General = () => {
  const { values, initialValues } = useLapeContext<CustomFieldsInterface>()

  const user = useSelector(selectUser)
  const canDelete = useSelector(canDeleteCustomField)

  const { id } = useParams<{ id: any }>()
  const { query } = useQuery<{ section_id?: SectionOptions }>()

  useEffect(() => {
    const inputType = values.input_type?.id
    /* Cleaning regex and errors when field type changed */
    if (
      inputType !== CustomFieldTypes.Text &&
      (values.validation_checks?.validation_regex ||
        values.validation_checks?.validation_error)
    ) {
      values.validation_checks.validation_regex = undefined
      values.validation_checks.validation_error = undefined
    }

    /* Cleaning validation choices when field type changed */
    if (
      inputType !== CustomFieldTypes.Dropdown &&
      inputType !== CustomFieldTypes.MultipleSelector &&
      values.validation_checks?.validation_choices
    ) {
      set(values, 'validation_checks.validation_choices', undefined)
    }

    if (
      (inputType === CustomFieldTypes.Dropdown ||
        inputType === CustomFieldTypes.MultipleSelector) &&
      !values.validation_checks?.validation_choices
    ) {
      set(values, 'validation_checks.validation_choices', [''])
    }
  }, [values.input_type])

  useEffect(() => {
    if (!values.created_by && user) {
      values.created_by = { id: user.id, name: user.full_name }
    }
  }, [])

  useEffect(() => {
    if (!values.section && query.section_id) {
      values.section = { id: query.section_id, name: '' } // Only id is needed for BE
    }
  }, [query.section_id])

  const backUrl = ROUTES.SETTINGS.CUSTOM_FIELDS

  const renderExtraFields = () => {
    switch (values.input_type?.id) {
      case CustomFieldTypes.Text:
        return (
          <>
            <LapeNewInput
              name="validation_checks.validation_regex"
              label="Validation RegEx"
              message="Regex string used to validate the input. If the check fails the validation error defined below will be displayed under the field."
              onAfterChange={value => {
                if (!value) {
                  set(values, 'validation_checks.validation_regex', null)
                }
              }}
            />
            <LapeNewInput
              name="validation_checks.validation_error"
              label="Validation error"
              message="Displayed under the field when the regex check fails."
              onAfterChange={value => {
                if (!value) {
                  set(values, 'validation_checks.validation_error', null)
                }
              }}
            />
          </>
        )
      case CustomFieldTypes.Dropdown:
      case CustomFieldTypes.MultipleSelector:
        return (
          <>
            <OptionsForDropdownSubheader>
              Options for{' '}
              {values.input_type.id === CustomFieldTypes.Dropdown
                ? 'dropdown'
                : 'multiple select'}
            </OptionsForDropdownSubheader>
            {values.validation_checks?.validation_choices?.map?.((_, index) => {
              return (
                <LapeNewInput
                  label="Option"
                  name={`validation_checks.validation_choices[${index}]`}
                  required
                  key={index}
                />
              )
            })}
            <Flex>
              <Button
                onClick={e => {
                  e.preventDefault()
                  values.validation_checks?.validation_choices?.push('')
                }}
                useIcon={Plus}
                variant="secondary"
                size="sm"
                data-testid="add-option"
              />
              <Button
                onClick={e => {
                  const length = values.validation_checks?.validation_choices?.length
                  e.preventDefault()
                  if (length && length > 1) {
                    values.validation_checks?.validation_choices?.pop()
                  }
                }}
                useIcon={Minus}
                variant="secondary"
                size="sm"
                ml="14px"
                data-testid="remove-option"
              />
            </Flex>
          </>
        )
      default:
        return null
    }
  }

  const renderTitle = () => {
    if (values.id) {
      return (
        initialValues.name +
        (initialValues.status?.id === Statuses.archived ? ' · Archived' : '')
      )
    }
    return 'Add custom field'
  }

  return (
    <PageWrapper>
      <PageHeader title={renderTitle()} backUrl={backUrl} />

      <PageBody>
        {!!values.id && (
          <Bar>
            <ArchiveButton />
            {canDelete && (
              <DeleteButtonLape
                data={values}
                backUrl={backUrl}
                deleteApi={customFieldsRequest.delete!}
                title="custom field"
              />
            )}
          </Bar>
        )}

        <AutoStepper>
          <NewStepperTitle title="Basic information" />
          <InputGroup>
            {values.id ? (
              <DetailsCell>
                <DetailsCell.Title>Unique identifier</DetailsCell.Title>
                <DetailsCell.Note>
                  <Copyable
                    value={values.field_name}
                    labelButtonCopy="Copy"
                    onCopy={() => {
                      pushNotification({
                        value: 'Field name copied to clipboard',
                        duration: SUCCESS_DEFAULT_DURATION,
                        type: NotificationTypes.success,
                      })
                    }}
                  >
                    {values.field_name}
                  </Copyable>
                </DetailsCell.Note>
              </DetailsCell>
            ) : null}
            <LapeNewInput
              name="name"
              label="Field name"
              message="Name of the custom field that appears inside text field and informs user what information is requested. Should be short and clear."
              required
            />
            <LapeNewTextArea
              name="tooltip"
              label="Helper text"
              message="Text that will appear below the text field, providing additional information. If empty the helper text will not be displayed."
              rows={2}
            />
            <LapeRadioSelectInput
              name="created_by"
              label="Owner"
              selector={selectorKeys.employee}
            />
            <LapeRadioSelectInput
              name="input_type"
              label="Field type"
              selector={selectorKeys.custom_field_input_types}
              required
            />
            {query.section_id && values.section ? null : (
              <LapeRadioSelectInput
                disabled={!!values.id}
                name="section"
                label="Section"
                message="Section of the People platform where this field will be included."
                selector={selectorKeys.custom_field_sections}
                onAfterChange={() => {
                  values.sub_section = null
                  values.dynamic_groups = []
                }}
              />
            )}
            {values.section?.id === SectionOptions.EmployeeProfile ? (
              <LapeRadioSelectInput
                name="sub_section"
                label="Subsection"
                message="Subsection of the Employee profile where this field will be included."
                selector={selectorKeys.employee_profile_sub_sections}
              />
            ) : null}
            {values.section?.id === SectionOptions.EmployeeProfile ? (
              <LapeNewMultiSelect
                name="dynamic_groups"
                selector={selectorKeys.dynamic_groups}
                placeholder="Eligibility groups"
                message={
                  <>
                    Please define which employee groups will have this field in their
                    profile. If you need a new group it can be defined{' '}
                    <Link
                      href={pathToUrl(ROUTES.FORMS.DYNAMIC_GROUPS.GENERAL)}
                      target="_blank"
                    >
                      here
                    </Link>
                  </>
                }
                required
              />
            ) : null}

            <LapeNewMultiSelect
              name="labels"
              selector={selectorKeys.custom_fields_labels}
              placeholder="Labels"
            />
          </InputGroup>

          <NewStepperTitle title="Settings" />
          <InputGroup>
            <LapeNewRadioButtons
              name="mandatory"
              options={[
                { label: 'Optional field', value: false },
                { label: 'Mandatory field', value: true },
              ]}
              defaultOptionIndex={0}
              variant="cell"
            />
            <LapeRadioSelectInput
              name="employee_access"
              label="Employee access"
              selector={selectorKeys.custom_field_employee_accesses}
            />
            {renderExtraFields()}
          </InputGroup>

          {values.section?.id === SectionOptions.Departments ||
          values.section?.id === SectionOptions.Roles ||
          values.section?.id === SectionOptions.Requisitions ? (
            <FilterTable
              type={values.section?.id}
              filter={initialValues.table_filters}
              onFilterChange={tableFilters => {
                values.table_filters = tableFilters
              }}
            />
          ) : null}
        </AutoStepper>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          onAfterSubmit={res => {
            if (!id) {
              navigateReplace(
                pathToUrl(ROUTES.FORMS.CUSTOM_FIELDS.GENERAL, { id: res.id }),
              )
            }
          }}
          successText={
            id ? 'Custom field successfully updated' : 'Custom field successfully created'
          }
          useValidator
        />
      </PageActions>
    </PageWrapper>
  )
}

export default connect(() => (
  <Form api={customFieldsRequest}>
    <General />
  </Form>
))

import React from 'react'
import { Box } from '@revolut/ui-kit'
import RadioSelectInput, {
  RadioSelectOption,
} from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import {
  ReviewDataAdditionalScorecardsQuestionInterface,
  ReviewerRelation,
  ReviewScorecardInterface,
} from '@src/interfaces/performance'
import { useLapeContext } from '@src/features/Form/LapeForm'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeNewRadioButtons from '@components/Inputs/LapeFields/LapeNewRadioButtons'
import LapeNewMultiSelect from '@components/Inputs/LapeFields/LapeNewMultiSelect'
import { RadioOption } from '@components/Inputs/NewRadioButtons/NewRadioButtons'

type QuestionContentProps = {
  question: ReviewDataAdditionalScorecardsQuestionInterface
  questionName: string
  viewMode?: boolean
  relation?: ReviewerRelation
}

export const QuestionContent = ({
  question,
  questionName,
  viewMode,
  relation = ReviewerRelation.Self,
}: QuestionContentProps) => {
  const { values } = useLapeContext<ReviewScorecardInterface>()
  const type = question.question_type
  const placeholder = question.placeholder_text
  const options =
    question.options?.map(item => ({
      label: item.text,
      value: { ...item, name: item.text },
    })) || []
  const questionValue = !viewMode ? question.value : question.answers?.[relation]

  const checkpointValue = options?.filter(option => {
    return Array.isArray(questionValue) && questionValue.find(el => el === option.label)
  })
  const onCheckpointChange = (
    selectedOptions: RadioSelectOption<{ name: string; id: number; text: string }>[],
  ) => {
    if (selectedOptions && values.review_data.cycle_sections) {
      question.value = selectedOptions.map(option => option.value.text)
    }
  }

  const optionValue = options?.find(option => option.label === questionValue)?.value
  const onOptionChange = (option: RadioOption) => {
    if (values.review_data.cycle_sections && option) {
      question.value = option.value.text
    }
  }

  const onTextChange = (
    event: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    if (values.review_data.cycle_sections) {
      question.value = event.currentTarget.value
    }
  }

  const onDropdownChange = (value: { name: string; id: number; text: string } | null) => {
    if (values.review_data.cycle_sections && value) {
      question.value = value.text
    }
  }

  switch (type) {
    case 'checkbox':
      return (
        <LapeNewMultiSelect
          name={questionName}
          placeholder={placeholder || 'Select'}
          options={options}
          value={checkpointValue}
          onChange={onCheckpointChange}
          required
          disabled={viewMode}
        />
      )
    case 'option':
      return (
        <LapeNewRadioButtons
          name={questionName}
          options={options}
          value={optionValue}
          onChange={onOptionChange}
          renderRadio={radio => <Box py="s-8">{radio}</Box>}
          disabled={viewMode}
        />
      )
    case 'textarea':
      return (
        <LapeNewTextArea
          name={questionName}
          label={placeholder || 'Type here'}
          onChange={onTextChange}
          required
          rows={3}
          disabled={viewMode}
        />
      )
    case 'text':
      return (
        <LapeNewInput
          name={questionName}
          label={placeholder || 'Type here'}
          onChange={onTextChange}
          required
          disabled={viewMode}
        />
      )
    case 'dropdown':
      return (
        <RadioSelectInput
          name={questionName}
          label={placeholder || 'Select'}
          options={options}
          value={optionValue}
          onChange={onDropdownChange}
          disabled={viewMode}
        />
      )
    default:
      return null
  }
}

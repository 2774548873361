import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { getPerformanceReviewSummary } from '@src/api/performanceReview'
import { connect } from 'lape'
import { Box } from '@revolut/ui-kit'
import { ReviewCategory, ReviewSummaryInterface } from '@src/interfaces/performance'
import LapeForm, { useLapeContext } from '@src/features/Form/LapeForm'
import PerformancePage from '@src/pages/Forms/EmployeePerformance/components/PerformancePage'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { useQuery } from '@src/utils/queryParamsHooks'
import { Queries } from '@src/constants/api'
import { PerformanceHeader } from '@src/pages/Forms/EmployeePerformanceLayout/components/PerformanceHeader'
import NoReviewsWidget from '@src/pages/EmployeeProfile/Preview/Performance/Common/NoReviewsWidget'
import { ScorecardContentView } from '@src/pages/Forms/EmployeePerformanceViewLayout/ScorecardContentView'

const EmployeePerformanceView = connect(() => {
  const { query } = useQuery()
  const params = useParams<{ id: string; employeeId: string }>()
  const [loading, setLoading] = useState(true)
  const [isBadFilter, setBadFilter] = useState(false)
  const form = useLapeContext<ReviewSummaryInterface>()
  const { initialValues } = form
  const cycleId = params.id

  useEffect(() => {
    const initializeForm = async () => {
      if (!!query[Queries.ReviewerId] || !!query[Queries.CheckpointNumber]) {
        const data = await fetchSummary()
        if (data) {
          form.values = data
        }
      } else {
        form.values = initialValues as ReviewSummaryInterface
        setBadFilter(false)
      }
      setLoading(false)
    }

    initializeForm()
  }, [query])

  const fetchSummary = async () => {
    const perfFilters = [
      {
        filters: query[Queries.ReviewerId]
          ? query[Queries.ReviewerId].split(',').map(val => ({
              name: `${val}`,
              id: `${val}`,
            }))
          : [],
        columnName: 'reviewer__id',
      },
      {
        filters: query[Queries.CheckpointNumber]
          ? query[Queries.CheckpointNumber].split(',').map(val => ({
              name: `${val}`,
              id: `${val}`,
            }))
          : [],
        columnName: 'probation_checkpoint__number',
      },
    ]
    const result = await getPerformanceReviewSummary(
      cycleId,
      +params.employeeId,
      ReviewCategory.Performance,
      perfFilters,
    )

    if (!result.data.summary) {
      setBadFilter(true)
      return null
    }

    setBadFilter(false)
    return result.data
  }

  if (loading) {
    return null
  }

  return (
    <PerformancePage showHeader={false}>
      <PerformanceHeader isViewMode />
      {isBadFilter ? (
        <Box maxWidth={628}>
          <NoReviewsWidget filtersApplied />
        </Box>
      ) : (
        <ScorecardContentView />
      )}
    </PerformancePage>
  )
})

export const EmployeePerformanceViewLayout = connect(() => {
  const params = useParams<{ id: string; employeeId: string }>()
  const [initialValues, setInitialValues] = useState<ReviewSummaryInterface>()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)

    const fetchSummary = async () => {
      const result = await getPerformanceReviewSummary(
        params.id,
        +params.employeeId,
        ReviewCategory.Performance,
      )

      setInitialValues(result.data)
      setLoading(false)
    }

    fetchSummary()
  }, [])

  if (loading) {
    return <PageLoading />
  }

  if (!initialValues) {
    return null
  }

  return (
    <LapeForm
      onSubmit={() => {
        return Promise.resolve({})
      }}
      validation={{}}
      initialValues={initialValues}
    >
      <EmployeePerformanceView />
    </LapeForm>
  )
})

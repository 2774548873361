import React, { useEffect, useMemo, useState } from 'react'
import isNil from 'lodash/isNil'
import * as Sentry from '@sentry/react'

import { ROUTES } from '@src/constants/routes'
import { useParams } from 'react-router-dom'
import { PageWrapper } from '@components/Page/Page'
import { Flex } from '@revolut/ui-kit'
import AssignKPIsForm from './AssignKPIsForm'
import Loader from '@components/CommonSC/Loader'
import { notificationsKPIsToAssign } from '@src/api/notifications'
import { Redirect } from 'react-router'
import { AssignKPIsParams, AssignKPIsQuery } from '@src/pages/Forms/AssignKPIs/common'
import { reviewCyclesRequests, reviewCyclesRequestsNew } from '@src/api/reviewCycles'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { HeaderRouter } from '@src/pages/Forms/AssignKPIs/routers'
import { useGetEntityWeightModes } from '@src/api/kpiWeights'
import { useQuery } from '@src/utils/queryParamsHooks'
import { KPIWeightModes } from '@src/interfaces/kpis'

enum NotificationState {
  active = 'active',
  disabled = 'disabled',
  noNotification = 'noNotification',
  unknown = 'unknown',
}

const AssignKPIs = () => {
  const params = useParams<AssignKPIsParams>()
  const { query } = useQuery<AssignKPIsQuery>()

  const [notificationId, setNotificationId] = useState<number>()
  const [reviewCycle, setReviewCycle] = useState<ReviewCyclesInterface>()
  const [notificationState, setNotificationState] = useState<NotificationState>(
    NotificationState.unknown,
  )
  const { data: entityWeightModes } = useGetEntityWeightModes(params.entity, params.id)

  const defaultWeightMode = useMemo(() => {
    if (entityWeightModes && reviewCycle) {
      return (
        entityWeightModes.results.find(
          weightMode => weightMode.review_cycle?.id === reviewCycle.id,
        )?.weight_mode || KPIWeightModes.auto
      )
    }
    return null
  }, [reviewCycle, entityWeightModes])

  useEffect(() => {
    if (params.notificationId) {
      notificationsKPIsToAssign()
        .getItems({
          filters: [
            {
              filters: [{ id: params.notificationId, name: params.notificationId }],
              columnName: 'item_id',
            },
          ],
        })
        .then(response => {
          const fetchedNotification = response.data.results?.[0]
          setNotificationId(fetchedNotification?.item_id)

          setNotificationState(
            fetchedNotification ? NotificationState.active : NotificationState.disabled,
          )

          const cycleId = fetchedNotification?.cycle_id

          if (!isNil(cycleId)) {
            reviewCyclesRequestsNew
              .get({ id: String(cycleId) })
              .then(res => setReviewCycle(res.data))
          } else {
            Sentry.captureException('[Assign KPIs]: no cycle_id in notification data')
          }
        })
    } else if (query.review_cycle__offset && !reviewCycle) {
      reviewCyclesRequests
        .getItems({
          filters: [],
        })
        .then(response => {
          const cycle = response.data?.results.find(
            elm => elm.offset === +query.review_cycle__offset,
          )
          cycle && setReviewCycle(cycle)

          setNotificationState(NotificationState.noNotification)
        })
    }
  }, [params.notificationId])

  if (notificationState === NotificationState.disabled) {
    return <Redirect to={ROUTES.APPS.TODO.KPIS_TO_ASSIGN} />
  }

  const isNotificationStateKnown = notificationState !== NotificationState.unknown

  return (
    <PageWrapper>
      <HeaderRouter entityType={params.entity} id={params.id} />

      {isNotificationStateKnown && reviewCycle && defaultWeightMode ? (
        <AssignKPIsForm
          id={params.id}
          noNotification={notificationState === NotificationState.noNotification}
          notificationId={notificationId}
          reviewCycle={reviewCycle}
          entityType={params.entity}
          defaultWeightMode={defaultWeightMode}
        />
      ) : (
        <Flex
          color="grey-tone-50"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="50%"
        >
          <Loader size="massive" />
        </Flex>
      )}
    </PageWrapper>
  )
}

export default AssignKPIs

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Button, Flex, Spinner, Token, Widget } from '@revolut/ui-kit'
import ArchiveOpportunityContent from '@src/pages/Forms/ArchiveOpportunity/General/ArchiveOpportunityContent'
import {
  ArchiveButton,
  ArchivingProvider,
  BulkArchiveButton,
  RememberedDataInterface,
  saveRememberArchivingData,
  useArchiving,
  usePrefillRememberedChoice,
} from '@src/pages/Forms/ArchiveOpportunity/common'
import { CandidateInterface } from '@src/interfaces/interviewTool'
import { SendCandidateEmailInterface, WhenToSend } from '@src/interfaces/hiringProccess'
import SendEmailCommon from '@src/pages/Forms/SendEmail/SendEmailCommon'
import {
  bulkCandidatesEmailsRequests,
  candidateEmailsRequests,
} from '@src/api/hiringProcess'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import SideBar from '@components/SideBar/SideBar'
import { Side } from '@revolut/ui-kit'
import NewCheckbox from '@components/Inputs/NewCheckbox/NewCheckbox'
import pluralize from 'pluralize'
import { clearCVArchivingChoice } from '@src/pages/Forms/CVScreening/utils'
import useGetPrefilledPlaceholdersEmail from '@src/pages/Forms/SendEmail/useGetPrefilledPlaceholdersEmail'

type Props = {
  candidate?: CandidateInterface
  candidateIds?: (number | string)[]
  open: boolean
  onClose: () => void
  onAfterArchive?: () => void
  onLoading?: (isLoading: boolean) => void
  noSuccessPopup?: boolean
  noSidebar?: boolean
  showInvalidPlaceholdersWarning?: boolean
}

const ArchivingCandidateSidebar = ({
  candidate,
  candidateIds,
  onClose,
  open,
  onLoading,
  onAfterArchive,
  noSuccessPopup,
  noSidebar,
  showInvalidPlaceholdersWarning,
}: Props) => {
  const [rememberChoiceChecked, setRememberChoiceChecked] = useState(false)
  const { values } = useLapeContext<SendCandidateEmailInterface>()
  const { setRoundId, sendEmail, reason, comments, archiveAll, roundId } = useArchiving()

  const { loading: loadingFetchEmail, fetchEmail: getPrefilledPlaceholdersEmail } =
    useGetPrefilledPlaceholdersEmail()

  const { loading } = usePrefillRememberedChoice(
    setRememberChoiceChecked,
    candidate,
    candidateIds,
  )

  const dataToRemember: RememberedDataInterface = useMemo(
    () => ({
      reason,
      comments,
      sendEmail,
      archiveAll,
      when_to_send: values.when_to_send,
      custom_sending_datetime: values.custom_sending_datetime,
      email_template: values.email_template,
      subject: values.subject,
      email_body: values.email_body,
      recipients_bcc: values.recipients_bcc,
      recipients_cc: values.recipients_cc,
      sender_type: values.sender_type,
      attachments:
        values.attachments?.map(item => ('id' in item ? item : null))?.filter(Boolean) ||
        [],
    }),
    [
      reason,
      comments,
      sendEmail,
      archiveAll,
      values.when_to_send,
      values.custom_sending_datetime,
      values.email_template,
      values.subject,
      values.email_body,
      values.recipients_bcc,
      values.recipients_cc,
      values.sender_type,
      values.attachments,
    ],
  )

  const saveRememberChoiceData = useCallback(() => {
    if (rememberChoiceChecked) {
      saveRememberArchivingData(dataToRemember)
    }
  }, [dataToRemember, rememberChoiceChecked])

  useEffect(() => {
    saveRememberChoiceData()
  }, [dataToRemember, rememberChoiceChecked])

  useEffect(() => {
    if (candidateIds) {
      values.candidate_ids = candidateIds
    }
  }, [candidateIds])

  useEffect(() => {
    if (!candidate) {
      return
    }

    const roundData = candidate.active_interview_round

    if (roundData) {
      setRoundId(candidate.active_interview_round?.id)
    }
  }, [candidate])

  const fetchEmail = (templateId: number) => {
    return getPrefilledPlaceholdersEmail(templateId, candidate?.id)
  }

  const renderContent = () => {
    if (loading) {
      return (
        <Flex justifyContent="center" alignItems="center" data-testid="loader">
          <Spinner color="blue" size={96} />
        </Flex>
      )
    }
    return (
      <>
        <ArchiveOpportunityContent />
        {sendEmail && (
          <Box mt="s-24">
            <SendEmailCommon
              fetchEmail={fetchEmail}
              insideSidebar
              showInvalidPlaceholdersWarning={showInvalidPlaceholdersWarning}
              actions={null}
            />
          </Box>
        )}
        <Widget p="s-16" mt="s-16">
          <NewCheckbox
            label="Remember my choice"
            checked={rememberChoiceChecked}
            onChange={e => {
              const checked = e.target.checked
              if (checked) {
                saveRememberChoiceData()
              } else {
                clearCVArchivingChoice()
              }
              setRememberChoiceChecked(checked)
            }}
          />
        </Widget>
        <Side.Actions horizontal style={{ padding: Token.space.s24 }}>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          {candidate && (
            <ArchiveButton
              candidateId={candidate.id}
              pending={loadingFetchEmail || loading}
              sendEmail={sendEmail}
              step={sendEmail ? 'email' : 'archive'}
              scheduled={values.when_to_send?.id !== WhenToSend.NOW}
              onAfterArchive={onAfterArchive}
              onLoading={onLoading}
              disabled={!roundId || !reason}
              noSuccessPopup={noSuccessPopup}
            />
          )}
          {candidateIds && (
            <BulkArchiveButton
              candidateIds={candidateIds}
              pending={loadingFetchEmail || loading}
              sendEmail={sendEmail}
              scheduled={values.when_to_send?.id !== WhenToSend.NOW}
              onAfterArchive={onAfterArchive}
              onLoading={onLoading}
              disabled={!reason}
            />
          )}
        </Side.Actions>
      </>
    )
  }

  if (noSidebar) {
    return <>{renderContent()}</>
  }

  return (
    <>
      <SideBar
        variant="wide"
        isOpen={open}
        onClose={onClose}
        title={
          candidateIds
            ? `Archive ${candidateIds.length} ${pluralize(
                'candidate',
                candidateIds.length,
              )}`
            : 'Archive candidate'
        }
        subtitle="This will also cancel all the scheduled interviews for the archived opportunity"
      >
        {renderContent()}
      </SideBar>
    </>
  )
}

export default (props: Props) =>
  // we have to re-mount the form to reset existing data state inside the Form (we call setIsExistingData(true) after submit and we don't need it here)
  props.open ? (
    <ArchivingProvider>
      <Form
        api={props.candidateIds ? bulkCandidatesEmailsRequests : candidateEmailsRequests}
        forceParams={{
          new: 'new',
          candidateId: props.candidate?.id ? String(props.candidate.id) : undefined,
        }}
      >
        <ArchivingCandidateSidebar {...props} />
      </Form>
    </ArchivingProvider>
  ) : null

import React, { useEffect, useState } from 'react'

import { selectorKeys } from '@src/constants/api'
import {
  CommitteeResultInterface,
  ProbationManagersGrade,
  ProbationRecommendationInterface,
  ProbationResults,
} from '@src/interfaces/probationReview'
import {
  getProbationManagersGrade,
  getProbationRecommendations,
} from '@src/api/probationReview'
import { useParams } from 'react-router-dom'
import SummarySidebar from '@src/pages/EmployeeProfile/Preview/Performance/Summary/SummarySidebar'
import { ReviewCategory } from '@src/interfaces/performance'
import { employeesRequests } from '@src/api/employees'
import { EmployeeInterface } from '@src/interfaces/employees'
import ProbationDecision from '@src/pages/Forms/FinalResultForm/ProbationDecision'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import * as yup from 'yup'
import { Box, createChain, Link, MoreBar } from '@revolut/ui-kit'
import { FinalGradeToString } from '@src/interfaces/scorecard'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageBody } from '@components/Page/PageBody'
import { PageActions } from '@components/Page/PageActions'
import SwitchButton from '@components/SwitchButton/SwitchButton'
import LapeNewRadioButtons from '@components/Inputs/LapeFields/LapeNewRadioButtons'
import { useGetSelectors } from '@src/api/selectors'
import Section from '@components/CommonSC/Section'
import LapeNewDatePicker from '@components/Inputs/LapeFields/LapeNewDatePicker'
import ExtensionDateWarning from '@src/pages/Forms/CommitteeResultForm/ExtensionDateWarning'
import SaveButton from '@src/pages/Forms/CommitteeResultForm/SaveButton'
import Loader from '@components/CommonSC/Loader'

export const validator = {
  final_grade: yup.object().required(),
  result: yup.string().required(),
  justification: yup.string().required(),
  seniority: yup.object().when('result', {
    is: (result: ProbationResults) => result === ProbationResults.ChangeSeniority,
    then: yup.object().required('Seniority is required'),
  }),
  end_of_probation_date_time: yup
    .string()
    .nullable()
    .when('result', {
      is: (result: ProbationResults) => result === ProbationResults.Extend,
      then: yup.string().nullable().required('Extension date is required'),
    }),
}

const General = () => {
  const [loading, setLoading] = useState(false)
  const { values } = useLapeContext<CommitteeResultInterface>()
  const [employee, setEmployee] = useState<EmployeeInterface>()
  const [recommendations, setRecommendations] =
    useState<ProbationRecommendationInterface[]>()
  const [managersGrade, setManagersGrade] = useState<ProbationManagersGrade>()
  const { cycleId, employeeId } = useParams<{ cycleId: any; employeeId: any }>()
  const permissions = useSelector(selectPermissions)
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const { data: performanceGrades } = useGetSelectors(selectorKeys.performance_grades)
  const { data: seniorities } = useGetSelectors(selectorKeys.seniority)

  const backUrl = pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE.PERFORMANCE, {
    id: employeeId,
  })

  const canEdit = permissions?.includes(PermissionTypes.ChangePerformanceDecision)

  const endOfProbationDate =
    values.cycle?.end_date_time || employee?.end_of_probation_date_time

  useEffect(() => {
    const fetchRecommendations = async () => {
      const { data } = await getProbationRecommendations(employeeId, cycleId)
      setRecommendations(data.results)
    }

    const fetchManagersGrade = async () => {
      const { data } = await getProbationManagersGrade(employeeId, cycleId)
      setManagersGrade(data)
    }

    fetchRecommendations()
    fetchManagersGrade()
  }, [employeeId])

  useEffect(() => {
    const fetchEmployee = async () => {
      setLoading(true)

      try {
        const { data } = await employeesRequests.getItem(employeeId)
        setEmployee(data)
      } finally {
        setLoading(false)
      }
    }

    fetchEmployee()
  }, [employeeId])

  useEffect(() => {
    if (employee?.seniority && !values.seniority) {
      values.seniority = employee.seniority
    }
  }, [employee])

  if (loading) {
    return (
      <PageWrapper>
        <Loader />
      </PageWrapper>
    )
  }

  if (!recommendations || !employee) {
    return null
  }

  return (
    <PageWrapper>
      <PageHeader
        title={
          <>
            Committee probation result for{' '}
            <Link
              href={pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
                id: employee.id,
              })}
              target="_blank"
            >
              {employee.display_name}
            </Link>
          </>
        }
        subtitle={
          <Link
            href={pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, {
              id: employee.specialisation?.id,
            })}
            target="_blank"
            color="grey-tone-50"
          >
            {createChain(' - ')(employee.specialisation?.name, employee.seniority?.name)}
          </Link>
        }
        backUrl={backUrl}
      />
      <PageBody>
        <MoreBar>
          <SwitchButton
            checked={sidebarOpen}
            onClick={() => setSidebarOpen(!sidebarOpen)}
          >
            Show reviews
          </SwitchButton>
        </MoreBar>

        <Box my="s-24">
          <Section>
            <Section.Item>
              <Section.IndexedTitle>
                What is your decision on the probation?
              </Section.IndexedTitle>
              <Section.Content>
                <ProbationDecision disabled={!canEdit} />
              </Section.Content>
            </Section.Item>

            {values.result === ProbationResults.Extend && (
              <Section.Item>
                <Section.IndexedTitle>
                  Till when do you want to extend {employee.first_name}’s probation?
                </Section.IndexedTitle>
                <Section.Content>
                  <Box maxWidth={336}>
                    <LapeNewDatePicker
                      name="end_of_probation_date_time"
                      disabledDays={{
                        before:
                          endOfProbationDate && new Date(endOfProbationDate) > new Date()
                            ? new Date(endOfProbationDate)
                            : new Date(),
                      }}
                    />
                  </Box>
                  {values.end_of_probation_date_time && (
                    <ExtensionDateWarning
                      extensionDate={values.end_of_probation_date_time}
                      endOfProbationDate={endOfProbationDate}
                    />
                  )}
                </Section.Content>
              </Section.Item>
            )}

            {values.result === ProbationResults.ChangeSeniority && (
              <Section.Item>
                <Section.IndexedTitle>
                  What seniority in your opinion is relevant for {employee.first_name}?
                </Section.IndexedTitle>
                <Section.Content>
                  {seniorities && (
                    <LapeNewRadioButtons
                      name="seniority"
                      options={seniorities.map(item => ({
                        label: item.name,
                        value: item,
                      }))}
                      disabled={!canEdit}
                      renderRadio={radio => <Box py="s-8">{radio}</Box>}
                    />
                  )}
                </Section.Content>
              </Section.Item>
            )}

            <Section.Item>
              <Section.IndexedTitle
                subtitle={`(System aggregated grade:
                    ${
                      managersGrade?.final_managers_calculated_grade
                        ? /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
                          FinalGradeToString[
                            managersGrade.final_managers_calculated_grade
                          ]
                        : '-'
                    })`}
              >
                What is the final calibrated grade?
              </Section.IndexedTitle>
              <Section.Content>
                {performanceGrades && (
                  <LapeNewRadioButtons
                    name="final_grade"
                    options={performanceGrades.map(item => ({
                      label: item.name,
                      value: item,
                    }))}
                    disabled={!canEdit}
                    renderRadio={radio => <Box py="s-8">{radio}</Box>}
                  />
                )}
              </Section.Content>
            </Section.Item>

            <Section.Item>
              <Section.IndexedTitle>
                What is your reasoning behind this decision?
              </Section.IndexedTitle>
              <Section.Content>
                <LapeNewTextArea
                  name="justification"
                  label="Enter concrete evidence to support the above choices"
                  required
                  rows={5}
                  disabled={!canEdit}
                  data-testid="justification"
                />
              </Section.Content>
            </Section.Item>
          </Section>
        </Box>
        <PageActions>
          <SaveButton
            backUrl={backUrl}
            lineManager={employee.line_manager.display_name!}
            endProbationDate={employee.end_of_probation_date_time}
            employeeSeniority={employee.seniority}
          />
        </PageActions>
      </PageBody>

      <SummarySidebar
        isOpen={sidebarOpen}
        onClose={() => setSidebarOpen(false)}
        cycleId={cycleId}
        employeeId={employee.id}
        employeeSeniorityId={employee.seniority?.id}
        cycleName={'Probation'}
        category={ReviewCategory.Probation}
      />
    </PageWrapper>
  )
}

export default General

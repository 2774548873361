import { getStatusColor } from '@src/components/CommonSC/General'
import { CompanyCompensationReviewsFormInterface } from '@src/interfaces/compensation'
import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { selectorKeys } from '../api'

export const companyCompensationReviewNameColumn: ColumnInterface<CompanyCompensationReviewsFormInterface> =
  {
    type: CellTypes.text,
    idPoint: 'year',
    dataPoint: 'year',
    sortKey: 'year',
    filterKey: 'id',
    selectorsKey: selectorKeys.company_compensation_reviews,
    title: 'Name',
  }

export const companyCompensationReviewCycleStartColumn: ColumnInterface<CompanyCompensationReviewsFormInterface> =
  {
    type: CellTypes.dateInUtc,
    filterType: FilterType.date,
    idPoint: 'start_date_time',
    dataPoint: 'start_date_time',
    sortKey: 'start_date_time',
    filterKey: 'start_date_time',
    selectorsKey: selectorKeys.none,
    title: 'Cycle start',
  }

export const companyCompensationReviewCycleEndColumn: ColumnInterface<CompanyCompensationReviewsFormInterface> =
  {
    type: CellTypes.dateInUtc,
    filterType: FilterType.date,
    idPoint: 'end_date_time',
    dataPoint: 'end_date_time',
    sortKey: 'end_date_time',
    filterKey: 'end_date_time',
    selectorsKey: selectorKeys.none,
    title: 'Cycle end',
  }

export const companyCompensationReviewCycleStatusColumn: ColumnInterface<CompanyCompensationReviewsFormInterface> =
  {
    type: CellTypes.text,
    idPoint: 'status.id',
    dataPoint: 'status.name',
    sortKey: 'status',
    filterKey: 'status',
    selectorsKey: selectorKeys.company_compensation_review_statuses,
    colors: data => getStatusColor(data.status.id),
    title: 'Status',
  }

import React, { useEffect, useState } from 'react'
import { PromotionNominationInterface } from '@src/interfaces/promotions'
import {
  useGetNomineeCurrentSalary,
  useGetPromotionSalaryBand,
} from '@src/api/promotions'
import {
  Avatar,
  Box,
  Group,
  HStack,
  Link,
  Spacer,
  Text,
  VStack,
  Spinner,
  Flex,
  TransitionCollapse,
} from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { getInitials, getAvatarUrl } from '@src/utils/employees'
import { ChevronLeft, ChevronUp, LogoRevolut, ArrowThinRight } from '@revolut/icons'
import { FormPreview } from '@components/FormPreview/FormPreview'
import { formatNumber } from '@src/utils/format'
import { EmployeeInterface, NameIdInterface } from '@src/interfaces/employees'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { SeniorityInterface } from '@src/interfaces/seniority'
import { OptionInterface } from '@src/interfaces/selectors'

interface Props {
  nomination: PromotionNominationInterface
  employee: EmployeeInterface
  employeeUrl: string
  targetSeniority: SeniorityInterface
  targetSpecialisation: NameIdInterface | OptionInterface
  hideBanner?: boolean
}

const NomineeSummary = ({
  nomination,
  employee,
  employeeUrl,
  targetSpecialisation,
  targetSeniority,
  hideBanner = false,
}: Props) => {
  const [currency, setCurrency] = useState<string>()
  const [benchmarkOpen, setBenchmarkOpen] = useState(false)
  const { data: rewards } = useGetNomineeCurrentSalary(
    nomination.id,
    employee.id,
    currency,
  )

  const { data: currentSalaryBand } = useGetPromotionSalaryBand({
    specialisationId: nomination.initial_specialisation.id,
    seniorityId: nomination.initial_seniority.id,
    locationId: employee.location.id,
    nominationId: nomination.id,
  })

  const { data: targetSalaryBand } = useGetPromotionSalaryBand({
    specialisationId:
      nomination.target_specialisation?.id || nomination.initial_specialisation.id,
    seniorityId: nomination.target_seniority?.id || nomination.initial_seniority.id,
    locationId: employee.location.id,
    nominationId: nomination.id,
  })

  useEffect(() => {
    if (!targetSalaryBand || !currentSalaryBand) {
      return
    }
    if (targetSalaryBand !== 'none') {
      setCurrency(targetSalaryBand.currency.iso_code)
    } else if (currentSalaryBand !== 'none') {
      setCurrency(currentSalaryBand.currency.iso_code)
    } else {
      setCurrency('GBP')
    }
  }, [targetSalaryBand, currentSalaryBand])

  return (
    <>
      {targetSalaryBand === 'none' && !hideBanner && (
        <ActionWidget
          mb="s-16"
          title="Benchmark for nominated position missing"
          text={`Salary benchmark for ${targetSeniority.name} ${targetSpecialisation.name} 
            for ${employee.location.location_name} location is currently missing.`}
        />
      )}
      <FormPreview<PromotionNominationInterface> data={nomination}>
        <Group>
          <>
            <VStack p="s-16" space="s-4">
              <HStack>
                <VStack space="s-4">
                  <Link href={employeeUrl} target="_blank" color="inherit">
                    <Text variant="h5" use="h5">
                      {employee.full_name}
                    </Text>
                  </Link>
                  <Link
                    target="_blank"
                    color="inherit"
                    href={pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, {
                      id: nomination.initial_specialisation.id,
                    })}
                  >
                    <Text variant="caption" fontWeight={500}>
                      {nomination.initial_specialisation.name}
                    </Text>
                  </Link>
                </VStack>
                <Spacer />
                <Avatar image={getAvatarUrl(employee.avatar)} size={56}>
                  {employee.avatar ? null : getInitials(employee.full_name)}
                  <Avatar.Badge
                    useIcon={LogoRevolut}
                    bg="background"
                    color="foreground"
                  />
                </Avatar>
              </HStack>
              <Link
                target="_blank"
                color="inherit"
                href={pathToUrl(ROUTES.FORMS.TEAM.SUMMARY, {
                  id: employee.team.id,
                })}
              >
                <Text variant="caption" color="grey-tone-50">
                  {employee.team.name}
                </Text>
              </Link>
            </VStack>
          </>
          <FormPreview.Item<PromotionNominationInterface>
            title="Proposed seniority"
            insert={d => {
              if (
                !d.target_seniority ||
                d.target_seniority.id === d.initial_seniority.id
              ) {
                return `${d.initial_seniority.name} (No change)`
              }
              return (
                <Flex alignItems="center">
                  <Text>{d.initial_seniority.name}</Text>
                  <Box px="s-8">
                    <ArrowThinRight color="grey-tone-20" size={16} />
                  </Box>
                  <Text>{d.target_seniority.name}</Text>
                </Flex>
              )
            }}
          />
          <FormPreview.Item<PromotionNominationInterface>
            title="Proposed Role (Specialisation)"
            insert={d =>
              `${d.target_specialisation?.name || d.initial_specialisation.name}${
                d.is_role_change_promotion ? '' : ' (No change)'
              }`
            }
          />
          <Box
            onClick={() => setBenchmarkOpen(!benchmarkOpen)}
            style={{ cursor: 'pointer' }}
            p="12px 16px"
          >
            <Flex justifyContent="space-between" alignItems="center">
              <Text color="grey-tone-50" fontWeight={500}>
                Benchmark Information
              </Text>
              <Box>
                {benchmarkOpen ? (
                  <ChevronUp size={20} color="grey-tone-50" />
                ) : (
                  <ChevronLeft size={20} color="grey-tone-50" />
                )}
              </Box>
            </Flex>
          </Box>
          <TransitionCollapse duration={200} in={benchmarkOpen}>
            <FormPreview.Item<PromotionNominationInterface>
              title="Current salary"
              insert={() => {
                if (!rewards) {
                  return <Spinner size={14} />
                }
                return (
                  <Text>
                    {rewards.result_currency}{' '}
                    {formatNumber(rewards.current_salary) || '-'}
                  </Text>
                )
              }}
            />
            <FormPreview.Item
              title="Benchmark for current position"
              insert={() => {
                if (!currentSalaryBand) {
                  return <Spinner size={14} />
                }
                if (currentSalaryBand === 'none') {
                  return <Text color="warning">Pending</Text>
                }
                return `${currentSalaryBand.currency.iso_code} ${formatNumber(
                  currentSalaryBand.lower_band,
                )} - ${formatNumber(currentSalaryBand.upper_band)}`
              }}
            />
            <FormPreview.Item
              title="Benchmark for proposed position"
              insert={() => {
                if (!targetSalaryBand) {
                  return <Spinner size={14} />
                }
                if (targetSalaryBand === 'none') {
                  return <Text color="warning">Pending</Text>
                }
                return `${targetSalaryBand.currency.iso_code} ${formatNumber(
                  targetSalaryBand.lower_band,
                )} - ${formatNumber(targetSalaryBand.upper_band)}`
              }}
            />
          </TransitionCollapse>
        </Group>
      </FormPreview>
    </>
  )
}

export default NomineeSummary

import React, { useEffect, useRef, useState } from 'react'
import {
  FinalGrade,
  ManagerRecommendationInterface,
  ReviewDataInterface,
  ReviewScorecardInterface,
  ReviewSummaryInterface,
} from '@src/interfaces/performance'
import { connect } from 'lape'
import { FormError, useLapeContext } from '@src/features/Form/LapeForm'
import {
  Avatar,
  Badge,
  Box,
  Flex,
  Text,
  Token,
  useTooltip,
  Tooltip as UIKitTooltip,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import {
  getJustificationViewList,
  RecommendationType,
} from '@components/ScorecardRecommendation/ScorecardRecommendation'
import { RadioButton } from '@components/Inputs/RadioButtons/RadioButtons'
import RecommendationGrade from '@src/pages/Forms/EmployeePerformance/components/RecommendationGrade'
import set from 'lodash/set'
import ExpandableText from '@components/ExpandableText/ExpandableText'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import OverallFeedback from '@src/pages/Forms/EmployeePerformance/OverallFeedback'
import { SCROLL_ERROR_HASH } from '@src/constants/performance'
import { useLocation } from 'react-router-dom'
import BottomText from '@components/Inputs/partials/BottomText'
import { get } from 'lodash'
import { EmployeePerformanceViewSummaryContent } from '@src/pages/Forms/EmployeePerformanceView/Summary'
import { FinalGradeToString } from '@src/interfaces/scorecard'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'

interface Props {
  isViewMode: boolean
  type?: RecommendationType
  recommendationData?: ManagerRecommendationInterface
  reviews?: ManagerRecommendationInterface[]
}

const GradeRatingWithTooltip = ({
  option,
  isViewMode,
  items,
}: {
  option: { key: FinalGrade; items: string[] }
  isViewMode: boolean
  items?: ManagerRecommendationInterface[]
}) => {
  const ratingTooltip = useTooltip()

  return (
    <Flex gap="s-8" {...ratingTooltip.getAnchorProps()} width="fit-content">
      <RecommendationGrade value={option.key} fontWeight={500} pb="s-8" />
      {isViewMode && !!items?.length && (
        <>
          <Badge
            minWidth="s-20"
            height="s-16"
            mt="s-2"
            backgroundColor={Token.color.deepGrey}
            style={{ fontWeight: 500 }}
          >
            {items.length}
            <UIKitTooltip
              {...ratingTooltip.getTargetProps()}
              maxWidth={200}
              placement="right-start"
            >
              <VStack>
                <Text variant="small" fontWeight="bold">
                  {FinalGradeToString[option.key]}
                </Text>
                {items.map(item => (
                  <Text key={item.reviewer?.id} variant="small">
                    {item.reviewer?.full_name}
                  </Text>
                ))}
              </VStack>
            </UIKitTooltip>
          </Badge>
        </>
      )}
    </Flex>
  )
}

export const Feedback = connect(
  ({ isViewMode, recommendationData, type, reviews }: Props) => {
    const { values, errors, submitFailed } = useLapeContext<
      ReviewScorecardInterface | ReviewSummaryInterface
    >()
    const [prosElement, setProsElement] = useState<HTMLTextAreaElement | null>(null)
    const tooltipAnchor = useRef<HTMLDivElement[]>([])
    const ref = useRef<HTMLDivElement>(null)
    const { hash } = useLocation()

    const performanceOptions = recommendationData?.employee_project_performance.options

    const reviewData = isViewMode
      ? (values as ReviewSummaryInterface).summary
      : (values as ReviewScorecardInterface).review_data
    const formErrors = errors as FormError<ReviewScorecardInterface>

    const user = useSelector(selectUser)
    const reviewedEmployeeId = isViewMode
      ? (values as ReviewSummaryInterface).reviews?.[0]?.reviewed_employee?.id
      : (values as ReviewScorecardInterface).reviewed_employee.id
    const isSelfReview = reviewedEmployeeId === user.id

    useEffect(() => {
      if (!(reviewData as ReviewDataInterface)?.overall_feedback) {
        set(values, 'review_data.overall_feedback', {
          pros: [],
          cons: [],
        })
      }
    }, [])

    useEffect(() => {
      if (submitFailed) {
        let key

        switch (type) {
          case 'lm':
            key = `review_data.line_manager_extra_section`
            break

          case 'fm':
            key = `review_data.functional_manager_extra_section`
            break

          case 'peer':
            key = `review_data.peer_extra_section`
            break
        }

        const hasError = key
          ? get(errors, key)?.employee_project_performance?.value
          : false
        const hasBarRaiserError = key
          ? get(errors, key)?.keeper_test_section?.questions
          : false
        if (
          hasError &&
          !hasBarRaiserError &&
          !formErrors.review_data?.deliverables?.cards &&
          !formErrors.review_data?.functional_skills?.cards &&
          !formErrors.review_data?.manager_skills?.cards
        ) {
          ref?.current?.scrollIntoView({ behavior: 'smooth' })
        }
      }
    }, [formErrors.review_data])

    useEffect(() => {
      if (hash === SCROLL_ERROR_HASH && formErrors.review_data?.overall_feedback) {
        !!prosElement && prosElement.focus()
      }
    }, [hash])

    const onRadioChange = (value: FinalGrade) => {
      let key

      switch (type) {
        case 'lm':
          key =
            'review_data.line_manager_extra_section.employee_project_performance.value'
          break

        case 'fm':
          key =
            'review_data.functional_manager_extra_section.employee_project_performance.value'
          break

        case 'peer':
          key = 'review_data.peer_extra_section.employee_project_performance.value'
          break
      }

      if (key) {
        set(values, key, value)
      }
    }

    const renderError = () => {
      if (!submitFailed) {
        return null
      }

      let key

      switch (type) {
        case 'lm':
          key = `review_data.line_manager_extra_section.employee_project_performance.value`
          break

        case 'fm':
          key = `review_data.functional_manager_extra_section.employee_project_performance.value`
          break

        case 'peer':
          key = `review_data.peer_extra_section.employee_project_performance.value`
          break
      }

      if (!key || !get(errors, key) || get(values, key)) {
        return null
      }

      return <BottomText error="Please select one of the options" />
    }

    const renderJustification = () => {
      let textAreaName: string = ''

      switch (type) {
        case 'lm':
          textAreaName =
            'review_data.line_manager_extra_section.employee_project_performance.justification'
          break

        case 'fm':
          textAreaName =
            'review_data.functional_manager_extra_section.employee_project_performance.justification'
          break

        case 'peer':
          textAreaName =
            'review_data.peer_extra_section.employee_project_performance.justification'
          break
      }

      if (isViewMode && reviews) {
        return <ExpandableText list={getJustificationViewList(reviews)} />
      }
      return (
        <LapeNewTextArea
          label="Grade justification for calibration"
          name={textAreaName}
          required
        />
      )
    }

    return (
      <Widget p="s-16">
        <VStack space="s-16">
          <Flex alignItems="center" justifyContent="space-between">
            <Flex alignItems="center" gap="s-16" width="30%">
              <Avatar useIcon="40/SubtractStar" />
              <Text variant="primary">Feedback</Text>
            </Flex>
          </Flex>
          {recommendationData && (
            <>
              <Text variant="primary" ref={ref}>
                {recommendationData.employee_project_performance.name}
              </Text>
              {performanceOptions?.map((option, optionIdx) => {
                const items = reviews?.filter(
                  item => item.employee_project_performance.value === option.key,
                )
                return (
                  <Box
                    key={optionIdx}
                    px="s-16"
                    mb="s-16"
                    data-testid={`feedback-${option.key}`}
                  >
                    <Flex>
                      <RadioButton
                        label={
                          <div>
                            <Box p="s-12">
                              <GradeRatingWithTooltip
                                option={option}
                                isViewMode={isViewMode}
                                items={items}
                              />
                              <ul
                                style={{
                                  paddingInlineStart: 16,
                                  margin: 0,
                                  color: Token.color.greyTone50,
                                }}
                              >
                                {option.items.map((item, i) => (
                                  <li key={i}>
                                    <Text
                                      variant="caption"
                                      color={Token.color.greyTone50}
                                    >
                                      {item}
                                    </Text>
                                  </li>
                                ))}
                              </ul>
                            </Box>
                          </div>
                        }
                        checked={
                          isViewMode
                            ? !!items?.length
                            : recommendationData.employee_project_performance.value ===
                              option.key
                        }
                        disabled={isViewMode}
                        onChange={() => onRadioChange(option.key)}
                        inputRef={el => {
                          tooltipAnchor.current[optionIdx] = el
                        }}
                        alignTop
                        capitalize={false}
                      />
                    </Flex>
                  </Box>
                )
              })}
              {renderError()}
              {renderJustification()}
            </>
          )}
          <Box>
            {!isSelfReview && (
              <Text variant="primary" mt="s-8">
                Employee feedback
              </Text>
            )}
            {isViewMode ? (
              <EmployeePerformanceViewSummaryContent
                values={values as ReviewSummaryInterface}
                width="100%"
                showStepperTitle={false}
                withBorder
                hideAdditional
              />
            ) : (
              <OverallFeedback
                noMargin
                pros={(reviewData as ReviewDataInterface)?.overall_feedback?.pros}
                cons={(reviewData as ReviewDataInterface)?.overall_feedback?.cons}
                onChangePros={val => {
                  set(values, 'review_data.overall_feedback.pros', val.split('\n'))
                }}
                onChangeCons={val => {
                  set(values, 'review_data.overall_feedback.cons', val.split('\n'))
                }}
                onGetProsElement={elem => {
                  setProsElement(elem)
                }}
              />
            )}
          </Box>
        </VStack>
      </Widget>
    )
  },
)

import React from 'react'
import { Redirect, Route, Switch, useLocation, useParams } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import ValueTargetForm from '@src/features/FormTabs/Kpi/KPITargets/ValueTargets/ValueTargetForm'
import {
  KPIRoadmapTargetLocationState,
  KPIValueTargetLocationState,
} from '@src/features/FormTabs/Kpi/KPITargets/common/types'
import { pathToUrl } from '@src/utils/router'
import RoadmapTargetForm from '@src/features/FormTabs/Kpi/KPITargets/RoadmapTargets/RoadmapTargetForm'
import { getLocationDescriptor } from '@src/actions/RouterActions'

const KPITarget = () => {
  const { kpiId } = useParams<{ kpiId: string }>()
  const { state } = useLocation<
    KPIValueTargetLocationState | KPIRoadmapTargetLocationState
  >()

  if (!state?.kpi) {
    return (
      <Redirect
        to={getLocationDescriptor(pathToUrl(ROUTES.FORMS.KPI.GENERAL, { id: kpiId }))}
      />
    )
  }

  return (
    <Switch>
      <Route exact path={ROUTES.FORMS.KPI_TARGET.VALUE}>
        <ValueTargetForm locationState={state as KPIValueTargetLocationState} />
      </Route>
      <Route exact path={ROUTES.FORMS.KPI_TARGET.ROADMAP}>
        <RoadmapTargetForm locationState={state as KPIRoadmapTargetLocationState} />
      </Route>
    </Switch>
  )
}

export default KPITarget

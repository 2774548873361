import React, { useEffect, useMemo, useState } from 'react'
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict'
import pluralize from 'pluralize'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { IdAndName } from '@src/interfaces'
import { useGetFreezingPeriod } from '@src/api/recruitment/interviews'
import { MoreBar } from '@revolut/ui-kit'
import { pathToUrlWithBaseUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import isNumber from 'lodash/isNumber'
import isFuture from 'date-fns/isFuture'
import add from 'date-fns/add'
import { CandidateInterface } from '@src/interfaces/interviewTool'

type Props = {
  candidateId?: string | number
  specialisation?: IdAndName
  showOpenProfileButton?: boolean
  existingCandidates?: CandidateInterface[]
}

const CandidateFreezingPeriodWarning = ({
  candidateId: forcedCandidateId,
  specialisation,
  showOpenProfileButton,
  existingCandidates,
}: Props) => {
  const [candidateId, setCandidateId] = useState<number | string>()

  const { data: freezingPeriod } = useGetFreezingPeriod(
    forcedCandidateId || candidateId,
    specialisation?.id,
  )

  const isFreezingPeriod = useMemo(
    () =>
      isNumber(freezingPeriod?.freezing_period_months) &&
      freezingPeriod?.archived_date_time
        ? isFuture(
            add(new Date(freezingPeriod.archived_date_time), {
              months: freezingPeriod.freezing_period_months,
            }),
          )
        : false,
    [freezingPeriod],
  )

  useEffect(() => {
    setCandidateId(
      existingCandidates?.find(item => item.matching_fields?.includes('email'))?.id,
    )
  }, [existingCandidates])

  if (!specialisation || !isFreezingPeriod || (!candidateId && !forcedCandidateId)) {
    return null
  }

  return (
    <ActionWidget
      title="The freezing period hasn't ended"
      text={
        <>
          This candidate failed interview for the {specialisation.name}{' '}
          {formatDistanceToNowStrict(new Date(freezingPeriod!.archived_date_time!), {
            addSuffix: true,
          })}
          . <br /> The freezing period is{' '}
          {pluralize('month', freezingPeriod!.freezing_period_months!, true)}. <br />
          This candidate can be added to the job posting in{' '}
          {formatDistanceToNowStrict(
            add(new Date(freezingPeriod!.archived_date_time!), {
              months: freezingPeriod!.freezing_period_months!,
            }),
          )}
          .
        </>
      }
      data-testid="freezing-period-warning"
    >
      {showOpenProfileButton && (
        <MoreBar>
          <MoreBar.Action
            use="a"
            href={pathToUrlWithBaseUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, {
              id: candidateId,
            })}
            target="_blank"
          >
            Open candidate profile
          </MoreBar.Action>
        </MoreBar>
      )}
    </ActionWidget>
  )
}

export default CandidateFreezingPeriodWarning

import React, { useEffect, useState } from 'react'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import { Statuses } from '@src/interfaces'
import { getRequisitionRecruiter } from '@src/api/recruitmentGroups'
import {
  getRequisitionsCommentsAPI,
  requisitionsRequestsNew,
  useGetRequisitionApprovals,
} from '@src/api/requisitions'
import { Box, Group, InputGroup, MoreBar, Subheader, VStack } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { LockOutline, SwitchOff, SwitchOn } from '@revolut/icons'
import { PageBody } from '@src/components/Page/PageBody'
import ReapprovalBanner from '@src/features/ApprovalFlow/ReapprovalBanner'
import ReopenRequisitionButton from '@src/pages/Forms/RequisitionForm/Buttons/ReopenButton'
import CloseRequisitionButton from '@src/pages/Forms/RequisitionForm/Buttons/CloseButton'
import SettingsButtons, {
  ApproveButton,
  ArchiveButton,
  CopyButton,
  DeleteButton,
  EditButton,
  RejectButton,
} from '@src/features/SettingsButtons'
import PublishButton from '@src/pages/Forms/RequisitionForm/Buttons/PublishButton'
import { EntityPermissions, PermissionTypes } from '@src/store/auth/types'
import CommentsSection from '@src/features/Comments/CommentsSection'
import { Redirect, useParams } from 'react-router-dom'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { formatDate, formatDateDistance } from '@src/utils/format'
import ApprovalFlow from '@src/features/ApprovalFlow/ApprovalFlow'
import { changelogApiRequisition } from '@src/pages/Forms/RequisitionForm/General/General'
import { usePreviewPendingChanges } from '@components/FormPreview/changelog'
import { getPendingRequisitionChangesRequest } from '@src/api/changelog'
import { handleError } from '@src/api'
import PipelineSidebar from '@src/pages/Forms/RequisitionForm/General/PipelineSidebar'
import RequisitionCompensationBand from '@src/components/LocationCompensationBand/RequisitionCompensationBand'
import { useGetRequisitionCompensationBands } from '@src/api/benchmarks'
import { allCompensationBandsAvailable } from '@src/components/LocationCompensationBand/utils'
import ReferCandidateButton from '@components/ReferCandidateButton/ReferCandidateButton'
import MrtWidget from '@src/pages/Forms/RequisitionForm/Mrt/MrtWidget'
import BudgetImpact from '@src/pages/Forms/RequisitionForm/General/BudgetImpact'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import PreviewLocations from '@src/pages/Forms/RequisitionForm/Preview/PreviewLocations'
import { INTERNAL_MOVE_PROCESS } from '@src/constants/externalLinks'
import RegulatedButton from '@src/pages/Forms/RequisitionForm/Buttons/RegulatedButton'
import {
  getApproveButtonTooltipLabel,
  useGetHiringRiskLocations,
} from '@src/pages/Forms/RequisitionForm/utils'
import { useGetRequisitionSettings } from '@src/api/settings'
import PermissionTransferButton from '@src/pages/PermissionTransfer/Button/PermissionTransferFormButton'
import { parseLegacyApprovalSteps } from '@src/utils/approvalFlow'
import { SectionOptions } from '@src/interfaces/customFields'

const Preview = () => {
  const form = useLapeContext<RequisitionInterface>()
  const params = useParams()
  const { values, initialValues, change, dirty } = form

  const [isSideHelpOpen, setIsSideHelpOpen] = useState(false)
  const [isApproving, setIsApproving] = useState<boolean>(false)

  const { data: requisitionSettings } = useGetRequisitionSettings()

  const {
    data: approvalSteps,
    isRefetching: isApprovalLoading,
    refetch: refetchApproval,
  } = useGetRequisitionApprovals(
    requisitionSettings?.enable_approvals ? values.id : undefined,
  )

  const { highRiskLocations, mediumRiskLocations } = useGetHiringRiskLocations()

  const [showPendingChanges, setShowPendingChanges] = useState(true)
  const pendingChangelog = usePreviewPendingChanges<RequisitionInterface>({
    api: changelogApiRequisition,
    data: values,
    showPendingChanges,
    disabled: values.status !== Statuses.pending,
    pendingChangesRequest: getPendingRequisitionChangesRequest,
  })
  const { data: compensationBands, isLoading: isCompensationBandsLoading } =
    useGetRequisitionCompensationBands(
      requisitionSettings?.enable_budget_impact ? values.id : null,
      values.locations
        ?.filter(item => item.posting_compensation_enabled)
        ?.map(item => item.id),
    )

  useEffect(() => {
    // in General component (Settings tab) we replace form.values with previous changelog values and if we switched tab, the data on Email tab would be outdated
    if (form.initialValues) {
      form.reset(form.initialValues as RequisitionInterface)
    }
  }, [])

  const canViewComments = values.field_options?.actions?.includes(
    EntityPermissions.AccessComments,
  )
  const canViewBackfilledEmployees = values.field_options?.permissions?.includes(
    PermissionTypes.ViewBackfillsRequisitions,
  )
  const canClose = values.field_options?.actions?.includes(EntityPermissions.Close)
  const canReopen = values.field_options?.actions?.includes(EntityPermissions.Reopen)
  const canChange = values.field_options?.actions?.includes(EntityPermissions.Change)

  useEffect(() => {
    if (
      !dirty ||
      !values.specialisation?.id ||
      !values.seniority_max?.id ||
      !values.main_location?.id
    ) {
      return
    }

    updateDefaultRecruiter()
  }, [values.specialisation?.id, values.seniority_max?.id, values.main_location?.id])

  if (!values.id) {
    return <Redirect to={pathToUrl(ROUTES.FORMS.REQUISITION.SETTINGS, {})} />
  }

  const updateDefaultRecruiter = () => {
    if (values.main_location) {
      getRequisitionRecruiter(
        +values.specialisation.id,
        values.seniority_max!.id,
        values.main_location.id,
      ).then(recruiter => {
        if (recruiter) {
          change('recruiter', recruiter)
        }
      })
    }
  }

  const compensationBandUnavailable =
    isCompensationBandsLoading || !allCompensationBandsAvailable(compensationBands)

  const isCostControlStep = values.approval_step === 'cost_control'

  const mrtQuestionnaireMissing =
    !!values.is_mrt_jira_ticket_url_required &&
    !values.is_mrt_jira_ticket_url_confirmed &&
    !!requisitionSettings?.enable_is_mrt_jira_ticket_url_confirmed_editing

  const isReapproval = values.is_published && values.status === Statuses.pending
  const isApproveBtnVisible =
    initialValues.status === Statuses.pending && requisitionSettings?.enable_approvals

  const onBeforeApproval = () => setIsApproving(true)
  const onAfterApproval = () => {
    setIsApproving(false)
    refetchApproval()
  }

  return (
    <>
      <PipelineSidebar
        isOpen={isSideHelpOpen}
        onClose={() => setIsSideHelpOpen(false)}
        specialisationId={values.specialisation?.id}
      />
      <PageBody>
        <SettingsButtons mb="s-24">
          <ReopenRequisitionButton isVisible={canReopen} />
          <CloseRequisitionButton isVisible={canClose} />
          <ApproveButton
            isVisible={isApproveBtnVisible}
            entityPermissions={EntityPermissions.Approve}
            notification={{
              updateMsg: 'Requisition successfully approved.',
            }}
            onSubmitFailed={handleError}
            onBeforeSubmit={onBeforeApproval}
            onAfterSubmit={onAfterApproval}
            disabled={
              (isCostControlStep && compensationBandUnavailable) ||
              mrtQuestionnaireMissing
            }
            tooltip={getApproveButtonTooltipLabel(
              mrtQuestionnaireMissing,
              isCostControlStep && compensationBandUnavailable,
            )}
          />
          <RejectButton
            isVisible={isApproveBtnVisible}
            entityPermissions={EntityPermissions.Approve}
            notification={{
              updateMsg: 'Requisition successfully rejected.',
            }}
            dialog={{
              title: 'Reasons for rejection',
              placeholder: 'Please provide reasons for rejection',
              fieldName: 'rejection_comment',
            }}
            onSubmitFailed={handleError}
            onBeforeSubmit={onBeforeApproval}
            onAfterSubmit={onAfterApproval}
          />
          <ApproveButton
            buttonActionText="Approve all"
            statusToSet={Statuses.opened}
            isVisible={isApproveBtnVisible}
            entityPermissions={EntityPermissions.ApproveAll}
            notification={{
              updateMsg: 'Requisition successfully approved.',
            }}
            onSubmitFailed={handleError}
            onBeforeSubmit={onBeforeApproval}
            onAfterSubmit={onAfterApproval}
            disabled={compensationBandUnavailable || mrtQuestionnaireMissing}
            tooltip={getApproveButtonTooltipLabel(
              mrtQuestionnaireMissing,
              compensationBandUnavailable,
            )}
          />
          <EditButton
            route={pathToUrl(ROUTES.FORMS.REQUISITION.SETTINGS, params)}
            isVisible={canChange}
          />
          <ArchiveButton
            notification={{
              updateMsg: 'Requisition successfully archived.',
            }}
          />
          {values.is_confidential && (
            <PermissionTransferButton
              path={ROUTES.FORMS.PERMISSION_MANAGEMENT.ENTITIES.REQUISITION}
            />
          )}
          {values.status === Statuses.opened && requisitionSettings?.enable_apply_button && (
            <MoreBar.Action
              target="_blank"
              rel="noreferrer noopener"
              href={INTERNAL_MOVE_PROCESS}
              use="a"
            >
              Apply
            </MoreBar.Action>
          )}
          <CopyButton afterSubmitUrl={pathToUrl(ROUTES.FORMS.REQUISITION.ROLE, {})} />
          <PublishButton />
          <ReferCandidateButton />
          {requisitionSettings?.enable_is_regulated_editing && <RegulatedButton />}
          <DeleteButton
            backUrl={ROUTES.RECRUITMENT.REQUISITIONS}
            deleteApi={requisitionsRequestsNew.delete!}
            title="Requisition"
          />
        </SettingsButtons>
        {requisitionSettings?.enable_budget_impact && (
          <RequisitionCompensationBand bands={compensationBands} />
        )}
        {requisitionSettings?.enable_is_mrt_jira_ticket_url_confirmed_editing && (
          <MrtWidget />
        )}
        {values.id && requisitionSettings?.enable_approvals && (
          <InputGroup>
            {isReapproval && (
              <ReapprovalBanner
                dataType="Requisition"
                actions={
                  <MoreBar>
                    <MoreBar.Action
                      useIcon={showPendingChanges ? SwitchOn : SwitchOff}
                      onClick={() => setShowPendingChanges(!showPendingChanges)}
                    >
                      Show pending changes
                    </MoreBar.Action>
                  </MoreBar>
                }
              />
            )}
            <ApprovalFlow
              isLoading={isApprovalLoading || isApproving}
              steps={approvalSteps ? parseLegacyApprovalSteps(approvalSteps) : null}
            />
          </InputGroup>
        )}
        <FormPreview<RequisitionInterface>
          data={values}
          title="Basic info"
          changelog={pendingChangelog}
        >
          <Group>
            <FormPreview.Item title="Title" field="requisition_title" />
            <FormPreview.Item<RequisitionInterface>
              title="Role (Specialisation)"
              to={data =>
                data.specialisation?.id
                  ? pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, {
                      id: data.specialisation?.id,
                    })
                  : undefined
              }
              field="specialisation.name"
            />
            {values.seniority_min?.id !== values.seniority_max?.id ? (
              <>
                <FormPreview.Item<RequisitionInterface>
                  title="Lowest seniority"
                  field="seniority_min.name"
                />

                <FormPreview.Item<RequisitionInterface>
                  title="Highest seniority"
                  field="seniority_max.name"
                />
              </>
            ) : (
              <FormPreview.Item<RequisitionInterface>
                title="Seniority"
                field="seniority_min.name"
              />
            )}
            <FormPreview.Item<RequisitionInterface>
              title="Locations"
              field="locations"
              insert={data => (
                <PreviewLocations
                  specialisationId={values.specialisation.id}
                  selectedLocations={data.locations}
                  highRiskLocations={highRiskLocations}
                  mediumRiskLocations={mediumRiskLocations}
                />
              )}
            />
            <FormPreview.Item<RequisitionInterface>
              to={data =>
                data.team?.id
                  ? pathToUrl(ROUTES.FORMS.TEAM.SUMMARY, { id: data.team?.id })
                  : undefined
              }
              title="Team"
              field="team.name"
            />
            <FormPreview.Item<RequisitionInterface>
              title="Type"
              field="backfill"
              insert={data => (data.backfill ? 'Backfill (Replacement)' : 'New hire')}
            />
            <FormPreview.Item title="Headcount" field="headcount" />
            {values.backfill && (
              <FormPreview.Details<RequisitionInterface>
                title="Employee(s) being backfilled"
                insert={data =>
                  canViewBackfilledEmployees ? (
                    <VStack space="s-8">
                      {data.backfill_employees?.length
                        ? data.backfill_employees?.map(employee => (
                            <UserWithAvatar {...employee} key={employee.id} />
                          ))
                        : '-'}
                    </VStack>
                  ) : (
                    <LockOutline color="grey-tone-50" />
                  )
                }
              />
            )}
            <FormPreview.Item title="Hired headcount" field="hired_headcount" />
            {values.is_regulated && (
              <FormPreview.Item
                title="Regulated role"
                field="is_regulated"
                type="boolean"
              />
            )}
            <FormPreview.Details title="Creation reason" field="description" />
            <FormPreview.CustomFields
              sectionId={SectionOptions.Requisitions}
              requisitionId={values.id}
            />
          </Group>
        </FormPreview>
        <FormPreview<RequisitionInterface> data={values} title="Additional details">
          <Group>
            <FormPreview.Item type="employee" title="Recruiter" field="recruiter" />
            <FormPreview.Item type="employee" title="Line manager" field="line_manager" />
          </Group>
        </FormPreview>
        {requisitionSettings?.enable_budget_impact && (
          <Box py="s-16">
            <Subheader variant="nested">
              <Subheader.Title>Budget impact</Subheader.Title>
            </Subheader>
            <BudgetImpact />
          </Box>
        )}
        {requisitionSettings?.enable_hiring_progress && (
          <FormPreview<RequisitionInterface>
            data={values}
            title="Hiring progress"
            linkTitle="View pipeline"
            onEdit={() => setIsSideHelpOpen(true)}
          >
            <Group>
              <FormPreview.Item<RequisitionInterface>
                field=""
                title="Position in queue"
                insert={d =>
                  d.pipeline_queue_position && d.pipeline_queue_total
                    ? `${d.pipeline_queue_position}/${d.pipeline_queue_total}`
                    : '-'
                }
              />
              <FormPreview.Item<RequisitionInterface>
                field=""
                title="Estimated time to hire"
                insert={d =>
                  d.estimated_fill_date
                    ? ` ${formatDateDistance(
                        new Date(d.estimated_fill_date),
                      )} (${formatDate(d.estimated_fill_date)})`
                    : ' -'
                }
              />
              <FormPreview.Item
                type="date"
                title="Ideal start date"
                field="estimated_fill_date"
              />
            </Group>
          </FormPreview>
        )}
        {canViewComments && (
          <Box pt="s-16">
            <InputGroup>
              <CommentsSection
                api={getRequisitionsCommentsAPI(values.id)}
                onSideOpen={() => setIsSideHelpOpen(false)}
              />
            </InputGroup>
          </Box>
        )}
      </PageBody>
    </>
  )
}

export default Preview

import React from 'react'
import { CellInsertParams, RowInterface } from '@src/interfaces/data'
import {
  interviewStageActionColumn,
  interviewStageNameColumn,
  interviewStageRecommendationColumn,
  interviewStageResultColumn,
  interviewStageStatusColumn,
} from '@src/constants/columns/interviewStage'
import {
  InterviewRoundInterface,
  InterviewStageRowChildrenInterface,
  InterviewStageRowWithoutRoundInterface,
  InterviewStageWithoutRoundInterface,
  InterviewType,
  ScheduleSidebarModeType,
} from '@src/interfaces/interviewTool'
import { Box, Color, Flex, Link, Text } from '@revolut/ui-kit'
import { InterviewResultIcon } from '@components/ColumnInserts/InterviewResult/InterviewResultIcon'
import { PermissionTypes } from '@src/store/auth/types'
import AdjustableTable from '@components/Table/AdjustableTable'
import MultipleDetailsSkeleton from '@components/Skeletons/MultipleDetailsSkeleton'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import StageActions from '@src/pages/Forms/Candidate/InterviewProgress/components/StagesTableActions/StageActions'
import FeedbackActions from '@src/pages/Forms/Candidate/InterviewProgress/components/StagesTableActions/FeedbackActions'
import { getColor } from '@src/styles/colors'
import PerformanceRatingLabelTag from '@components/PerformanceRatingLabelTag/PerformanceRatingLabelTag'
import { ratingToColor } from '@src/utils/performance'
import OnlineTestResultStatus from '@src/features/InterviewTool/OnlineTestResultStatus'
import { TableNames } from '@src/constants/table'
import { StagesLoadingStatusType } from '@src/pages/Forms/Candidate/InterviewProgress/useFetchStagesTable'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import pluralize from 'pluralize'
import { LinkExternal } from '@revolut/icons'
import { getAvatarUrl } from '@src/utils/employees'

interface Props {
  round: InterviewRoundInterface
  onClick?: (
    data: InterviewStageRowWithoutRoundInterface,
    mode?: ScheduleSidebarModeType,
    stageType?: InterviewType,
  ) => void
  onRefresh?: (onlyTable?: boolean) => void
  selectedRowId?: number
  hideCount?: boolean
  disableActions?: boolean
  canViewEditOffer: boolean
  stages: InterviewStageWithoutRoundInterface[]
  feedbacks: InterviewStageRowWithoutRoundInterface[]
  status?: StagesLoadingStatusType
  candidateId: number
}

const ROW = ({
  stages,
  roundId,
  selectedRowId,
  currentStageId,
  onClick,
  canCancel,
  canAddFeedback,
  onRefresh,
  canViewEditOffer,
  disableActions,
  candidateId,
}: {
  canCancel: boolean
  canAddFeedback: boolean
  onRefresh?: () => void
  stages: InterviewStageWithoutRoundInterface[]
  roundId: number
  canViewEditOffer: boolean
  onClick?: (
    data: InterviewStageRowWithoutRoundInterface,
    mode?: ScheduleSidebarModeType,
  ) => void
  selectedRowId?: number
  currentStageId?: number
  disableActions?: boolean
  candidateId: number
}): RowInterface<
  InterviewStageRowWithoutRoundInterface | InterviewStageRowChildrenInterface
> => ({
  noChildrenRequest: true,
  highlight: (data, theme) => {
    if (data.id === selectedRowId) {
      return getColor(theme, Color.ACTION_BACKGROUND)
    }
    return ''
  },
  isChildrenOpener: (
    data: InterviewStageRowWithoutRoundInterface | InterviewStageRowChildrenInterface,
  ) => 'children' in data,
  cells: [
    {
      ...interviewStageNameColumn,
      width: 180 - (!disableActions && (canCancel || canAddFeedback) ? 37 : 0),
    },
    {
      ...interviewStageStatusColumn,
      width: 140,
    },
    {
      ...interviewStageRecommendationColumn,
      insert: ({ data }) => {
        if ('children' in data) {
          return (
            <Flex>
              {data.recommendations.map(item => (
                <UserWithAvatar
                  key={item.id}
                  avatar={getAvatarUrl(item.avatar)}
                  full_name={item.full_name}
                  id={item.id}
                  mr="s-8"
                  compact
                />
              ))}
            </Flex>
          )
        }

        if (!data.status) {
          return <Text color="grey-20">-</Text>
        }

        return (
          <InterviewResultIcon result={data.result} status={data.status} showTooltip />
        )
      },
      width: 180 - (!disableActions && (canCancel || canAddFeedback) ? 37 : 0),
    },
    {
      ...interviewStageResultColumn,
      background: (data, theme) =>
        ratingToColor(theme, 'children' in data ? data.average_rating : data.rating) ||
        '',
      width: 120,
      insert: ({ data }) => {
        if ('children' in data) {
          if (data.average_rating) {
            return (
              <PerformanceRatingLabelTag
                rating={data.average_rating}
                fontSize="caption"
                fontWeight={400}
              />
            )
          }

          if (
            data.interview_type === 'online_test' &&
            data.scheduling_status === 'test_completed'
          ) {
            return <OnlineTestResultStatus passed={data.online_test_result?.is_passed} />
          }

          return '-'
        }

        return data.rating ? (
          <PerformanceRatingLabelTag
            fontSize="caption"
            fontWeight={400}
            rating={data.rating}
          />
        ) : (
          '-'
        )
      },
    },
    ...(!disableActions && (canCancel || canAddFeedback)
      ? [
          {
            ...interviewStageActionColumn,
            width: 74,
            insert: ({
              data,
            }: CellInsertParams<
              InterviewStageRowWithoutRoundInterface | InterviewStageRowChildrenInterface
            >) => (
              <div onClick={e => e.stopPropagation()}>
                {'children' in data ? (
                  <StageActions
                    stages={stages}
                    stage={data}
                    roundId={roundId}
                    canCancel={canCancel}
                    canAddFeedback={canAddFeedback}
                    onRefresh={onRefresh}
                    currentStageId={currentStageId}
                    onOpenSidebar={onClick}
                    menuType="dropdown"
                    canViewEditOffer={canViewEditOffer}
                    candidateId={candidateId}
                  />
                ) : (
                  <FeedbackActions
                    feedback={data}
                    canCancel={canCancel}
                    canAddFeedback={canAddFeedback}
                    onRefresh={onRefresh}
                  />
                )}
              </div>
            ),
          },
        ]
      : []),
  ],
})

const StagesTable = ({
  round,
  onClick,
  selectedRowId,
  onRefresh,
  hideCount = false,
  disableActions = false,
  canViewEditOffer,
  stages,
  feedbacks,
  status,
  candidateId,
}: Props) => {
  if (status === 'loading') {
    return <MultipleDetailsSkeleton />
  }

  const canCancel = !!round.field_options?.permissions?.includes(
    PermissionTypes.CancelPendingScorecard,
  )
  const canAddFeedback = !!round?.field_options?.permissions?.includes(
    PermissionTypes.SeeAddFeedbackButton,
  )

  if (!round.latest_interview_stage) {
    return null
  }

  return (
    <Box mt="s-16">
      <AdjustableTable<InterviewStageRowWithoutRoundInterface>
        renderCount={count => (
          <Text color={Color.GREY_TONE_50}>
            Showing {count} {pluralize('Hiring stage', count)}
            {round?.specialisation && (
              <>
                {' '}
                for{' '}
                <Link
                  href={pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, {
                    id: round.specialisation.id,
                  })}
                  target="_blank"
                  color="inherit"
                  display="inline-block"
                >
                  <Flex alignItems="center">
                    {round.specialisation.name}&nbsp;
                    <LinkExternal size={14} />
                  </Flex>
                </Link>
              </>
            )}
          </Text>
        )}
        name={TableNames.CandidateHiringStages}
        dataType="Hiring stage"
        row={ROW({
          canCancel,
          canAddFeedback,
          onRefresh,
          stages,
          roundId: round.id,
          canViewEditOffer,
          onClick,
          selectedRowId,
          currentStageId: round.latest_interview_stage.id,
          disableActions,
          candidateId,
        })}
        onRowClick={(row, parentIndexes) => {
          onClick?.(row, undefined, feedbacks[parentIndexes[0]]?.interview_type)
        }}
        noDataMessage="There are no interviews for this candidate"
        noReset
        enableSettings={false}
        hideCount={hideCount}
        rowHeight="large"
        count={feedbacks?.length}
        data={feedbacks}
      />
    </Box>
  )
}

export default StagesTable

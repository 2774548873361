import { API } from '@src/constants/api'
import { Audience, CompReviewSettings } from '@src/interfaces/compSettings'
import { useFetch } from '@src/utils/reactQuery'
import { api } from '.'
import { GetRequestInterface } from '@src/interfaces'

export const useCompReviewSettings = () => {
  return useFetch<CompReviewSettings>(API.COMP_REVIEW_SETTINGS)
}

export const patchCompReviewSettings = (request: Partial<CompReviewSettings>) => {
  return api.patch(API.COMP_REVIEW_SETTINGS, request)
}

type AudienceRequest = {
  search: string
  audience_type: string
  page: number
}

export const useAudiences = (params?: AudienceRequest) => {
  return useFetch<GetRequestInterface<Audience>>(API.AUDIENCES, 'v1', { params })
}

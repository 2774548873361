import React, { useEffect, useState } from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  NotCompletedReviewInterface,
  NotCompletedReviewsInterface,
  ReviewCategory,
  ReviewScorecardInterface,
  ReviewSummaryInterface,
} from '@src/interfaces/performance'
import { pathToUrl } from '@src/utils/router'
import { navigateReplace } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import {
  Flex,
  DetailsCell,
  Spinner,
  Button,
  chain,
  Token,
  Box,
  VStack,
  Group,
  Item,
  Text,
  Radio,
} from '@revolut/ui-kit'
import SideBar from '@src/components/SideBar/SideBar'
import { NavigationBack, NavigationForward, ViewListMedium } from '@revolut/icons'
import { useParams } from 'react-router-dom'
import { Statuses } from '@src/interfaces'
import { getLastTabLocalStorageKey, useHasNewScorecards } from '@src/utils/performance'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { getStatusThemeColor } from '@components/CommonSC/General'
import upperFirst from 'lodash/upperFirst'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'

const commonButtonProps = {
  variant: 'text',
  size: 'sm',
  color: 'grey-tone-50',
  p: 0,
} as const

interface Props {
  reviews: NotCompletedReviewsInterface
  noMaxWidth?: boolean
}

type SidebarProps = {
  isOpen: boolean
  onClose: () => void
  reviews: NotCompletedReviewInterface[]
  currentReviewIndex: number
  onScorecardSelect: (review: NotCompletedReviewInterface) => void
}

const ReviewsSidebar = ({
  isOpen,
  onClose,
  reviews,
  currentReviewIndex,
  onScorecardSelect,
}: SidebarProps) => {
  if (!isOpen) {
    return null
  }

  return (
    <SideBar
      variant="wide"
      title="Performance review scorecards"
      subtitle="Select the employee you want to assess"
      onClose={onClose}
      isOpen={isOpen}
    >
      <Group>
        {reviews.map((review, index) => (
          <Item
            key={review.id}
            use="button"
            aria-pressed={index === currentReviewIndex}
            onClick={() => onScorecardSelect(review)}
          >
            <Item.Content>
              <Flex alignItems="center" gap="s-16">
                <Radio checked={index === currentReviewIndex} onChange={() => {}} />
                <Box width="s-40">
                  <UserWithAvatar
                    {...review.reviewed_employee}
                    status={undefined}
                    size={40}
                    compact
                  />
                </Box>
                <VStack>
                  <Text variant="primary">{review.reviewed_employee.full_name}</Text>
                  <Text variant="caption" color={Token.color.greyTone50}>
                    {upperFirst(review.category)} review
                  </Text>
                </VStack>
              </Flex>
            </Item.Content>
            <Item.Side>
              <Text variant="caption" color={getStatusThemeColor(review.status)}>
                {upperFirst(review.status)}
              </Text>
            </Item.Side>
          </Item>
        ))}
      </Group>
    </SideBar>
  )
}

export const PerformanceHeaderNavigationBar = ({
  reviews,
  noMaxWidth = false,
}: Props) => {
  const { values } = useLapeContext<ReviewScorecardInterface | ReviewSummaryInterface>()
  const [pending, setPending] = useState(false)
  const [sidebarOpened, setSidebarOpened] = useState(false)
  const { id } = useParams<{ id: string; employeeId: string }>()
  const showNewScorecards = useHasNewScorecards()
  const filteredReviews = reviews.results?.filter(
    review => review.status === Statuses.pending || review.status === Statuses.draft,
  )
  const currentReviewIndex = filteredReviews?.findIndex(
    review => review.id === Number(id),
  )

  useEffect(() => setPending(false), [values])

  if (currentReviewIndex === -1 || currentReviewIndex === undefined) {
    return null
  }

  const switchReview = (review: NotCompletedReviewInterface) => {
    const reviewLastTabRoute = workspaceLocalStorage.getItem(
      getLastTabLocalStorageKey(review.category, review.reviewed_employee.id, review.id),
    )
    switch (review?.category) {
      case ReviewCategory.Upwards:
        setPending(true)
        navigateReplace(
          reviewLastTabRoute ||
            pathToUrl(ROUTES.FORMS.UPWARDS_REVIEW.SKILLS, {
              employeeId: review.reviewed_employee.id,
              id: review.id,
            }),
        )
        break
      case ReviewCategory.Performance:
        setPending(true)
        navigateReplace(
          showNewScorecards
            ? pathToUrl(ROUTES.FORMS.EMPLOYEE_PERFORMANCE_LAYOUT, {
                employeeId: review.reviewed_employee.id,
                id: review.id,
              })
            : reviewLastTabRoute ||
                pathToUrl(ROUTES.FORMS.EMPLOYEE_PERFORMANCE.GENERAL, {
                  employeeId: review.reviewed_employee.id,
                  id: review.id,
                }),
        )
        break
      case ReviewCategory.Probation:
        setPending(true)
        navigateReplace(
          reviewLastTabRoute ||
            pathToUrl(ROUTES.FORMS.PROBATION_REVIEW.DELIVERABLES, {
              employeeId: review.reviewed_employee.id,
              id: review.id,
            }),
        )
        break
    }
  }
  const handlePreviousClick = () => {
    const previousReview =
      currentReviewIndex === 0
        ? filteredReviews[filteredReviews?.length - 1]
        : filteredReviews[currentReviewIndex - 1]
    switchReview(previousReview)
  }

  const handleNextClick = () => {
    const nextReview =
      currentReviewIndex === filteredReviews?.length - 1
        ? filteredReviews[0]
        : filteredReviews[currentReviewIndex + 1]
    switchReview(nextReview)
  }

  return (
    <DetailsCell
      mt="10px"
      mb="s-24"
      color="grey-tone-50"
      style={{ maxWidth: noMaxWidth ? undefined : '1055px' }}
    >
      <DetailsCell.Title
        variant="secondary"
        fontSize="15px"
        lineHeight="22px"
        fontWeight={500}
      >
        {chain(
          'Performance review scorecards',
          `${currentReviewIndex + 1}/${reviews.count}`,
        )}
      </DetailsCell.Title>
      <Flex alignItems="center" style={{ height: '22px', fontWeight: 'normal' }}>
        {pending && <Spinner mr="s-12" />}
        {!pending && (
          <>
            <Button
              {...commonButtonProps}
              onClick={handlePreviousClick}
              useIcon={NavigationBack}
              disabled={pending}
            />
            <Button
              {...commonButtonProps}
              onClick={handleNextClick}
              useEndIcon={NavigationForward}
              disabled={pending}
            />
            <Box ml="s-8" onClick={() => setSidebarOpened(true)}>
              <ViewListMedium color={Token.color.greyTone50} cursor="pointer" />
            </Box>
          </>
        )}
      </Flex>
      <ReviewsSidebar
        isOpen={sidebarOpened}
        onClose={() => setSidebarOpened(false)}
        reviews={filteredReviews}
        currentReviewIndex={currentReviewIndex}
        onScorecardSelect={review => switchReview(review)}
      />
    </DetailsCell>
  )
}

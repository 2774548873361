import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Upload from '@src/pages/Forms/ImportData/KPIs/Upload'
import { ROUTES } from '@src/constants/routes'
import ReviewData from '@src/pages/Forms/ImportData/KPIs/ReviewData'
import StatusPage from '@src/pages/Forms/ImportData/KPIs/StatusPage'
import { Redirect } from 'react-router'

const ImportKPIs = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.FORMS.IMPORT_DATA.KPIS.UPLOAD_FILE}>
        <Upload />
      </Route>
      <Route exact path={ROUTES.FORMS.IMPORT_DATA.KPIS.REVIEW_DATA}>
        <ReviewData />
      </Route>
      <Route exact path={ROUTES.FORMS.IMPORT_DATA.KPIS.STATUS}>
        <StatusPage />
      </Route>
      <Redirect to={ROUTES.FORMS.IMPORT_DATA.KPIS.UPLOAD_FILE} />
    </Switch>
  )
}

export default ImportKPIs

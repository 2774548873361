import React, { useEffect, useState } from 'react'
import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import OnboardingChecklistContent from '../components/OnboardingChecklistContent'
import { probationConfig } from '../common/constants'
import { Intro } from './Intro'
import { CycleSetup } from './CycleSetup'
import { Committee } from './Committee'
import { EligibleEmployees } from './EligibleEmployees'
import { Preview } from './Preview'
import { Apply } from './Apply'
import {
  getProbationTemplates,
  probationTemplateRequests,
} from '@src/api/probationTemplate'
import { ProbationTemplateInterface } from '@src/interfaces/probationTemplate'

export const Probation = () => {
  const [firstTemplate, setFirstTemplate] = useState<ProbationTemplateInterface>()

  useEffect(() => {
    getProbationTemplates({}).then(res => {
      setFirstTemplate(res.data.results[0])
    })
  }, [])

  const form = {
    api: probationTemplateRequests,
    forceParams: { id: firstTemplate?.id },
    disableDataCleanup: true,
  }

  const routes = [
    {
      title: 'Intro',
      path: ROUTES.ONBOARDING_CHECKLIST.PROBATION.INTRO,
      url: ROUTES.ONBOARDING_CHECKLIST.PROBATION.INTRO,
      canView: [PermissionTypes.ViewProbationEmployees],
      component: Intro,
    },
    {
      title: 'Cycle setup',
      path: ROUTES.ONBOARDING_CHECKLIST.PROBATION.SETUP,
      url: ROUTES.ONBOARDING_CHECKLIST.PROBATION.SETUP,
      canView: [PermissionTypes.ViewProbationEmployees],
      component: CycleSetup,
      form,
    },
    {
      title: 'Managing committee',
      path: ROUTES.ONBOARDING_CHECKLIST.PROBATION.COMMITTEE,
      url: ROUTES.ONBOARDING_CHECKLIST.PROBATION.COMMITTEE,
      canView: [PermissionTypes.ViewPerformancePreferences],
      component: Committee,
      hidePageBody: true,
    },
    {
      title: 'Eligible employees',
      path: ROUTES.ONBOARDING_CHECKLIST.PROBATION.ELIGIBILITY,
      url: ROUTES.ONBOARDING_CHECKLIST.PROBATION.ELIGIBILITY,
      canView: [PermissionTypes.ViewProbationEmployees],
      component: EligibleEmployees,
      form,
    },
    {
      title: 'Preview',
      path: ROUTES.ONBOARDING_CHECKLIST.PROBATION.PREVIEW,
      url: ROUTES.ONBOARDING_CHECKLIST.PROBATION.PREVIEW,
      canView: [PermissionTypes.ViewProbationEmployees],
      component: Preview,
      form,
      hidePageBody: true,
    },
    {
      title: 'Apply to existing employees',
      path: ROUTES.ONBOARDING_CHECKLIST.PROBATION.APPLY,
      url: ROUTES.ONBOARDING_CHECKLIST.PROBATION.APPLY,
      canView: [PermissionTypes.ViewProbationEmployees],
      component: Apply,
      form,
      hidePageBody: true,
    },
  ]
  return <OnboardingChecklistContent config={probationConfig} routes={routes} />
}

import React from 'react'
import { useSelector } from 'react-redux'
import { Cell, Flex, MoreBar } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'

import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { RowInterface } from '@src/interfaces/data'
import { selectPermissions } from '@src/store/auth/selectors'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { useTable } from '@src/components/Table/hooks'
import {
  companyCompensationReviewCycleEndColumn,
  companyCompensationReviewCycleStartColumn,
  companyCompensationReviewCycleStatusColumn,
  companyCompensationReviewNameColumn,
} from '@src/constants/columns/companyCompensationReviews'
import { PermissionTypes } from '@src/store/auth/types'
import SettingsButtons from '@src/features/SettingsButtons'
import { CompanyCompensationReviewsFormInterface } from '@src/interfaces/compensation'
import { getCompanyCompensationReviews } from '@src/api/compensation'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'

const ROW: RowInterface<CompanyCompensationReviewsFormInterface> = {
  linkToForm: ({ id }) =>
    navigateTo(pathToUrl(ROUTES.FORMS.COMPANY_COMPENSATION_REVIEW_CYCLE.PREVIEW, { id })),
  cells: [
    {
      ...companyCompensationReviewNameColumn,
      width: 160,
    },
    {
      ...companyCompensationReviewCycleStartColumn,
      width: 160,
    },
    {
      ...companyCompensationReviewCycleEndColumn,
      width: 160,
    },
    {
      ...companyCompensationReviewCycleStatusColumn,
      width: 160,
    },
  ],
}

const ReviewCycles = () => {
  const permissions = useSelector(selectPermissions)

  const table = useTable<CompanyCompensationReviewsFormInterface>({
    getItems: getCompanyCompensationReviews,
  })

  return (
    <Cell>
      <Flex flexDirection="column" width="100%">
        {permissions.includes(PermissionTypes.AddCompanyCompensationReview) && (
          <SettingsButtons mb="s-16">
            <MoreBar.Action
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.COMPANY_COMPENSATION_REVIEW_CYCLE.GENERAL)}
              useIcon={Plus}
            >
              Add new review cycle
            </MoreBar.Action>
          </SettingsButtons>
        )}

        <Flex style={{ position: 'relative' }} flex="1 0">
          <AdjustableTable
            name={TableNames.CompensationReviewCycles}
            useWindowScroll
            row={ROW}
            {...table}
            noDataMessage="Compensation review cycles will appear here."
          />
        </Flex>
      </Flex>
    </Cell>
  )
}

export default ReviewCycles

import SideBar from '@components/SideBar/SideBar'
import React from 'react'
import { Services } from '@revolut/icons'
import { Group, Token, Flex } from '@revolut/ui-kit'
import { Label } from '@components/Label/Label'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { TeamInterface } from '@src/interfaces/teams'
import { navigateTo } from '@src/actions/RouterActions'
import { FormPreview } from '@components/FormPreview/FormPreview'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { CommunicationDetails } from '@src/features/CommunicationGroups/CommunicationDetails'
import { useGetRoadmapSettings } from '@src/api/settings'

interface DetailsSidebarProps {
  open: boolean
  onClose: () => void
  team: TeamInterface
  approvalFlow: React.ReactNode | null
}

export const DetailsSidebar = ({
  open,
  onClose,
  team,
  approvalFlow,
}: DetailsSidebarProps) => {
  const { data: roadmapSettings } = useGetRoadmapSettings()

  return (
    <SideBar
      variant="wide"
      isOpen={open}
      onClose={onClose}
      title="Team details"
      subtitle={
        team.department ? (
          <Label
            name={team.department.name}
            icon="Services"
            url={pathToUrl(ROUTES.FORMS.DEPARTMENT.SUMMARY, {
              id: team.department.id,
            })}
          />
        ) : undefined
      }
      useIcon={Services}
      avatarProps={{ color: Token.color.greyTone20 }}
    >
      {approvalFlow}
      <FormPreview<TeamInterface>
        data={team}
        title="General details"
        onEdit={() => navigateTo(pathToUrl(ROUTES.FORMS.TEAM.SETTINGS, { id: team.id }))}
      >
        <Group>
          <FormPreview.Item<TeamInterface> field="name" title="Team name" />
          <FormPreview.Item<TeamInterface>
            field="team_owner"
            title="Owner"
            type="employee"
          />
          <FormPreview.Details<TeamInterface>
            title="KPI champions"
            description="These people have increased permissions to help when editing and setting KPIs"
            insert={d =>
              d.kpi_champions && d.kpi_champions.length > 0 ? (
                <Flex flexWrap="wrap">
                  {d.kpi_champions.map(kpiChamp => (
                    <UserWithAvatar
                      height="s-32"
                      mr="s-12"
                      key={kpiChamp.id}
                      {...kpiChamp}
                    />
                  ))}
                </Flex>
              ) : (
                '-'
              )
            }
          />
          <FormPreview.Details<TeamInterface> field="mission" title="Mission" />
          <CommunicationDetails
            jiraProjects={team.jira_projects}
            group={team.communication_group}
            linkedGroups={team.linked_communication_groups}
            jiraEnabled={roadmapSettings?.jira_epics_enabled}
          />
        </Group>
      </FormPreview>
    </SideBar>
  )
}

import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import { PayCycleInterface } from '@src/interfaces/payroll'
import { formatDate, formatMoneyWithCode } from '@src/utils/format'
import { getStatusColor } from '@src/components/CommonSC/General'
import { Statuses } from '@src/interfaces'

export const exerciseCycleStartDateColumn: ColumnInterface<PayCycleInterface> = {
  type: CellTypes.insert,
  idPoint: 'start_date',
  dataPoint: 'start_date',
  sortKey: 'start_date',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Exercise cycle',
  insert: ({ data }) => formatDate(data.start_date, 'MMM yyyy'),
}

export const exerciseCycleHeadcountColumn: ColumnInterface<PayCycleInterface> = {
  type: CellTypes.text,
  idPoint: 'headcount',
  dataPoint: 'headcount',
  sortKey: 'headcount',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Headcount',
}

export const exerciseCycleTotalAmountColumn: ColumnInterface<PayCycleInterface> = {
  type: CellTypes.insert,
  idPoint: 'total_gross_pay',
  dataPoint: 'total_gross_pay',
  sortKey: 'total_gross_pay',
  filterKey: 'total_gross_pay',
  filterType: FilterType.range,
  selectorsKey: selectorKeys.none,
  title: 'Total amount',
  insert: ({ data }) =>
    formatMoneyWithCode(
      Number(data.total_gross_pay),
      data.pay_group?.country?.national_currency?.iso_code,
    ),
}

export const exerciseCyclePercentReconciledColumn: ColumnInterface<PayCycleInterface> = {
  type: CellTypes.text,
  idPoint: 'percent_reconciled',
  dataPoint: 'percent_reconciled',
  sortKey: 'percent_reconciled',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Percent reconciled',
}

export const exerciseCycleStatusColumn: ColumnInterface<PayCycleInterface> = {
  type: CellTypes.text,
  idPoint: 'status.id',
  dataPoint: 'status.name',
  sortKey: 'status',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  colors: data => getStatusColor(data.status.id as Statuses),
  title: 'Status',
}

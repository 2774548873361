import {
  BehaviourAssessmentItem,
  CriteriaAssessment,
  DeliverableJustificationValue,
  DeliverableOptions,
  PerformanceRating,
  PerformanceReviewTypes,
  ReviewScorecardInterface,
  SkillCardInterface,
  SummarySkillCardInterface,
  ValueBasedCardInterface,
} from '@src/interfaces/performance'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import {
  CardInterface,
  getData,
  prefillCard,
  PREVIOUS_QUARTER_ID,
  updateSingleCard,
} from '@components/ScorecardGeneral/PrefillScorecardButton'
import { getUpdatedRatingCard, getVisibleSections } from '@src/utils/performance'
import { get, set } from 'lodash'
import { AssessBehaviourButtonTypes } from '@components/AssessButtons/AssessBehaviourButtons'
import { RecommendationType } from '@components/ScorecardRecommendation/ScorecardRecommendation'
import { FormError } from '@src/features/Form/LapeForm'

export const ratingOptions = [
  { key: DeliverableOptions.DONT_KNOW, text: "Don't know" },
  { key: DeliverableOptions.POOR, text: 'Poor' },
  { key: DeliverableOptions.BASIC, text: 'Basic' },
  { key: DeliverableOptions.INTERMEDIATE, text: 'Intermediate' },
  { key: DeliverableOptions.ADVANCED, text: 'Advanced' },
  { key: DeliverableOptions.EXPERT, text: 'Expert' },
]

export const cultureOptions = [
  { key: 'dont_know', text: "Don't know" },
  { key: 'improvement_needed', text: 'Improvement Focus' },
  { key: 'performing', text: 'Performing' },
  { key: 'superpower', text: 'Superpower' },
]

export const kpiRating = [
  {
    key: 'poor' as DeliverableOptions,
    text: 'Poor',
    description: [
      'Fails to deliver most tasks assigned, often shows poor quality in execution.',
      'Makes almost no impact towards the team achieving its goals.',
    ],
  },
  {
    key: 'basic' as DeliverableOptions,
    text: 'Basic',
    description: [
      'Works hard to deliver mostly simple tasks on time and with good quality.',
      'Makes a positive contribution towards the team achieving its goals through medium impact deliverables.',
    ],
  },
  {
    key: 'intermediate' as DeliverableOptions,
    text: 'Intermediate',
    description: [
      'Delivers a mixture of simple and complex tasks on time and with great quality.',
      'Makes an essential contribution towards the team achieving its goals and has an impact on the department goals too.',
    ],
  },
  {
    key: 'advanced' as DeliverableOptions,
    text: 'Advanced',
    description: [
      'Delivers highly complex tasks often before the deadline and always with exceptional quality.',
      'Contributes an essential impact on the department achieving its goals and in turn the associated company goals.',
    ],
  },
  {
    key: 'expert' as DeliverableOptions,
    text: 'Expert',
    description: [
      'Contributes greatly towards the department exceeding its goals and makes essential impacts towards the company exceeding its goals.',
    ],
  },
]

export const deliverablesRating = [
  {
    key: 'dont_know' as DeliverableOptions,
    text: "Don't know",
    description: ["I don't have enough data to assess the deliverables"],
  },
  ...kpiRating,
]

export enum CardContentTypes {
  KPI = 'KPI',
  DELIVERABLES = 'DELIVERABLES',
  SKILLS = 'SKILLS',
  MANAGER_SKILLS = 'MANAGER_SKILLS',
  VALUES = 'VALUES',
  RECOMMENDATION_LM = 'RECOMMENDATION_LM',
  RECOMMENDATION_FM = 'RECOMMENDATION_FM',
  RECOMMENDATION_PEER = 'RECOMMENDATION_PEER',
}

export interface SelectedFieldInterface {
  type: CardContentTypes
  field: string
  cardIndex?: number
}

export interface ScorecardsDeliverableOption {
  key: DeliverableOptions | AssessBehaviourButtonTypes
  text: string
}

export interface GradeOption extends ScorecardsDeliverableOption {
  description?: string[]
}

export interface CommonCardProps {
  selectedField?: SelectedFieldInterface
  setSelectedField: Dispatch<SetStateAction<SelectedFieldInterface | undefined>>
}

export const getRoundedRating = (
  rating?: PerformanceRating,
): DeliverableOptions | null => {
  switch (rating) {
    case PerformanceRating.dont_know:
      return DeliverableOptions.DONT_KNOW
    case PerformanceRating.poor_plus:
    case PerformanceRating.poor:
      return DeliverableOptions.POOR
    case PerformanceRating.basic_plus:
    case PerformanceRating.basic:
    case PerformanceRating.basic_minus:
      return DeliverableOptions.BASIC
    case PerformanceRating.intermediate_plus:
    case PerformanceRating.intermediate:
    case PerformanceRating.intermediate_minus:
      return DeliverableOptions.INTERMEDIATE
    case PerformanceRating.advanced_plus:
    case PerformanceRating.advanced:
    case PerformanceRating.advanced_minus:
      return DeliverableOptions.ADVANCED
    case PerformanceRating.expert_plus:
    case PerformanceRating.expert:
    case PerformanceRating.expert_minus:
      return DeliverableOptions.EXPERT
    default:
      return null
  }
}

export const PerformanceRatingToGraphNumber = {
  [PerformanceRating.skipped]: 0,
  [PerformanceRating.dont_know]: 0,
  [PerformanceRating.poor]: 1,
  [PerformanceRating.poor_plus]: 1,
  [PerformanceRating.basic_minus]: 2,
  [PerformanceRating.basic]: 2,
  [PerformanceRating.basic_plus]: 2,
  [PerformanceRating.intermediate_minus]: 3,
  [PerformanceRating.intermediate]: 3,
  [PerformanceRating.intermediate_plus]: 3,
  [PerformanceRating.advanced_minus]: 4,
  [PerformanceRating.advanced]: 4,
  [PerformanceRating.advanced_plus]: 4,
  [PerformanceRating.expert_minus]: 5,
  [PerformanceRating.expert]: 5,
  [PerformanceRating.expert_plus]: 5,
  [PerformanceRating.improvement_needed]: 0,
  [PerformanceRating.performing]: 1,
  [PerformanceRating.superpower]: 2,
}

const getUpdatedCardRating = async (
  values: ReviewScorecardInterface,
  card: SkillCardInterface,
) => {
  try {
    const resp = await getUpdatedRatingCard(
      values.id,
      values.category,
      values.reviewed_employee.id!,
      card,
    )

    card.rating = resp?.rating
    return card
  } catch (e) {
    return undefined
  }
}

export const onPrefillWithGrade = async (
  values: ReviewScorecardInterface,
  grade: GradeOption,
  type: PerformanceReviewTypes,
  cardIndex?: number,
) => {
  if (cardIndex === undefined) {
    return
  }

  const level = grade.key
  let updatedValues: CardInterface | undefined = getData(values, type, cardIndex)

  if (!updatedValues) {
    return
  }

  updateSingleCard(updatedValues, level)

  // to delay a bit to make switching between options smoother
  setTimeout(async () => {
    if (!updatedValues || 'internal_data_id' in updatedValues) {
      return
    }

    updatedValues = await getUpdatedCardRating(values, updatedValues)
  }, 300)
}

export const onPrefillCultureValue = async (
  values: ReviewScorecardInterface,
  grade: GradeOption,
  field: string,
) => {
  let card = get(values, field)

  if (!card) {
    return
  }

  card?.sections?.forEach((section: BehaviourAssessmentItem) => {
    section.value = grade.key as AssessBehaviourButtonTypes
  })
}

export const countSuperpowers = (cards: ValueBasedCardInterface[]) => {
  return cards.reduce((count, card) => {
    count += card.sections.filter(section => section.value === 'superpower').length
    return count
  }, 0)
}

export const RATING_SCORE_MAP = {
  [AssessBehaviourButtonTypes.positive]: 4,
  [AssessBehaviourButtonTypes.neutral]: 3,
  [AssessBehaviourButtonTypes.negative]: 2,
  [AssessBehaviourButtonTypes.unknown]: 0,
}

// Each rating (improvement/performing/superpower) is given a score; from 2 to 4.
// When calculating the section average, ignore "Don't Know" answers.
// To get the overall section ratings:
// 1 - round the average to the nearest integer. If the score is .5, we should round down.
// 2 - if number of "Don't Know" answers is greater than the others,
// section rating should be "Don't Know"
export const countValueRating = (card: ValueBasedCardInterface) => {
  const scoreSum = card.sections.reduce(
    (acc, val) => acc + (val.value ? RATING_SCORE_MAP[val.value] : 0),
    0,
  )
  // if .5 round down
  const score = -Math.round(-(scoreSum / card.sections.length))

  if (score >= 4) {
    return AssessBehaviourButtonTypes.positive
  }
  if (score >= 3) {
    return AssessBehaviourButtonTypes.neutral
  }
  if (score >= 2) {
    return AssessBehaviourButtonTypes.negative
  }
  return AssessBehaviourButtonTypes.unknown
}

export const updateValueRating = ({
  values,
  path,
}: {
  values: ReviewScorecardInterface
  path: string
}) => {
  const currentCard = get(values, path)
  const rating = countValueRating(currentCard)

  if (rating) {
    set(values, `${path}.rating`, rating)
  }
}

export const cardHasMissingValues = (
  type: CardContentTypes,
  card?: SkillCardInterface | SummarySkillCardInterface,
  errors?: FormError<ReviewScorecardInterface>,
) => {
  if (type === CardContentTypes.DELIVERABLES) {
    const hasJustificationError = errors?.review_data?.deliverables?.justifications?.some(
      item => {
        return (item as DeliverableJustificationValue)?.reference_url
      },
    )
    if (hasJustificationError) {
      return true
    }
    return card?.sections?.some(section => {
      return 'value' in section && !section.value
    })
  }

  const sections = getVisibleSections(card?.sections as CriteriaAssessment[])
  return sections.some(section => {
    if ('items' in section) {
      return section.items?.some(item => !item.value)
    }
    return false
  })
}

export const getReviewerRelation = (type: RecommendationType) => {
  switch (type) {
    case 'lm':
      return 'LM'
    case 'fm':
      return 'FM'
    case 'peer':
      return 'Peer'
    default:
      return ''
  }
}

export const usePrefillSkillsWithPreviousQuarter = (
  values: ReviewScorecardInterface,
  skillsCards?: SkillCardInterface[],
) => {
  const [prefillCompleted, setPrefillCompleted] = useState(false)

  useEffect(() => {
    const firstCard = (skillsCards?.[0]?.sections?.[0] as CriteriaAssessment)?.items?.[0]

    // if first found section item has value, then all cards were already pre-filled
    if (!!skillsCards && !firstCard?.value && firstCard?.previous_values?.[0]?.value) {
      Promise.allSettled(
        skillsCards.map(async card => {
          prefillCard(card, PREVIOUS_QUARTER_ID)
          await getUpdatedCardRating(values, card)
        }),
      ).finally(() => setPrefillCompleted(true))
    } else {
      setPrefillCompleted(true)
    }
  }, [skillsCards])

  return { prefillCompleted }
}

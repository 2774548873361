import React, { useState } from 'react'
import {
  Action,
  Avatar,
  AvatarSkeleton,
  Box,
  Radio,
  Group,
  Item,
  StatusPopup,
  Subheader,
  useStatusPopup,
} from '@revolut/ui-kit'
import { Profile } from '@revolut/icons'
import { useSelector } from 'react-redux'

import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@src/components/Page/PageBody'
import { deleteUserAvatar, uploadUserAvatar, useGetUserAvatar } from '@src/api/settings'
import AvatarUploadPopup from '@src/components/AvatarUploadPopup/AvatarUploadPopup'
import { pushNotification } from '@src/store/notifications/actions'
import { NotificationTypes } from '@src/store/notifications/types'
import { ERROR_DEFAULT_DURATION } from '@src/constants/notifications'
import { dispatch } from '@src/utils/store'
import { selectUser } from '@src/store/auth/selectors'
import { setUserAction } from '@src/store/auth/actions'
import {
  ThemeSetting,
  useAppTheme,
} from '@src/features/UIKitWithThemeProvider/UIKitWithThemeProvider'
import { useGetCanEnableTestCycleSandbox } from '@src/utils/reviewCycles'
import { Sandbox } from '@src/features/Sandbox/Sandbox'
import { AnalyticsEvents, useAnalytics } from '@src/utils/analytics'

export const UserPreferences = () => {
  const [openUpload, setOpenUpload] = useState(false)
  const canEnableSandbox = useGetCanEnableTestCycleSandbox()

  const user = useSelector(selectUser)

  const statusPopup = useStatusPopup()

  const { themeSettingValue, setTheme } = useAppTheme()

  const { data: userAvatar, refetch } = useGetUserAvatar()

  const { sendAnalyticsEvent } = useAnalytics()

  const closePopup = () => setOpenUpload(false)

  const onSavePhoto = async (file: File) => {
    try {
      const response = await uploadUserAvatar(file)

      /** To update the avatar in sidebar without calling /whoami */
      if (response.data.url) {
        dispatch(setUserAction({ ...user, avatar: response.data.url }))
      }

      refetch()
      closePopup()
      statusPopup.show(
        <StatusPopup variant="success">
          <StatusPopup.Title>Profile picture updated</StatusPopup.Title>
        </StatusPopup>,
      )
    } catch {
      pushNotification({
        type: NotificationTypes.error,
        value: 'Failed to update user photo',
        duration: ERROR_DEFAULT_DURATION,
      })
    }
  }

  const onRemovePhoto = async () => {
    if (!userAvatar?.id) {
      return
    }
    try {
      await deleteUserAvatar(userAvatar.id)
      /** To update the avatar in sidebar without calling /whoami */
      dispatch(setUserAction({ ...user, avatar: null }))
      refetch()
      closePopup()
      statusPopup.show(
        <StatusPopup variant="success">
          <StatusPopup.Title>Profile picture removed</StatusPopup.Title>
        </StatusPopup>,
      )
    } catch {
      pushNotification({
        type: NotificationTypes.error,
        value: 'Failed to remove user photo',
        duration: ERROR_DEFAULT_DURATION,
      })
    }
  }

  const onSetTheme = (mode: ThemeSetting) => {
    sendAnalyticsEvent(AnalyticsEvents.change_app_theme, { mode })
    setTheme(mode)
  }

  return (
    <>
      <PageWrapper>
        <PageHeader title="User preferences" backUrl={ROUTES.SETTINGS.ALL} />

        <PageBody>
          <Subheader variant="nested">
            <Subheader.Title>Avatar</Subheader.Title>
          </Subheader>

          <Item>
            <Item.Avatar>
              {userAvatar ? (
                userAvatar?.url ? (
                  <Avatar image={userAvatar?.url} />
                ) : (
                  <Avatar bg="blue-opaque-50" useIcon={<Profile color="background" />} />
                )
              ) : (
                <AvatarSkeleton />
              )}
            </Item.Avatar>

            <Item.Content>
              <Item.Title>Profile picture</Item.Title>
              <Item.Description>
                You can choose to upload a photo for your profile or opt out
              </Item.Description>
            </Item.Content>

            <Item.Side>
              <Action onClick={() => setOpenUpload(true)}>Update photo</Action>
            </Item.Side>
          </Item>

          <Box mt="s-8">
            <Subheader variant="nested">
              <Subheader.Title>Theme</Subheader.Title>
            </Subheader>

            <Group>
              <Item use="label">
                <Item.Prefix>
                  <Radio
                    onClick={() => onSetTheme('light')}
                    checked={themeSettingValue === 'light'}
                    aria-labelledby="theme-light"
                  />
                </Item.Prefix>
                <Item.Content>
                  <Item.Title id="theme-light">Light</Item.Title>
                </Item.Content>
              </Item>
              <Item use="label">
                <Item.Prefix>
                  <Radio
                    onClick={() => onSetTheme('dark')}
                    checked={themeSettingValue === 'dark'}
                    aria-labelledby="theme-dark"
                  />
                </Item.Prefix>
                <Item.Content>
                  <Item.Title id="theme-dark">Dark</Item.Title>
                </Item.Content>
              </Item>
              <Item use="label">
                <Item.Prefix>
                  <Radio
                    onClick={() => onSetTheme('auto')}
                    checked={themeSettingValue === 'auto'}
                    aria-labelledby="theme-system"
                  />
                </Item.Prefix>
                <Item.Content>
                  <Item.Title id="theme-system">System</Item.Title>
                </Item.Content>
              </Item>
            </Group>
          </Box>

          {canEnableSandbox && (
            <Box mt="s-8">
              <Subheader variant="nested">
                <Subheader.Title>Demo Mode</Subheader.Title>
              </Subheader>
              <Sandbox />
            </Box>
          )}
        </PageBody>
      </PageWrapper>

      <AvatarUploadPopup
        open={openUpload}
        onClose={closePopup}
        onSave={onSavePhoto}
        onRemove={userAvatar?.id ? onRemovePhoto : undefined}
        title="Upload user photo"
        minHeight={192}
        minWidth={192}
      />
    </>
  )
}

import React from 'react'
import { BaseHeaderProps } from '@src/pages/Forms/AssignKPIs/common'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { useGetTeam } from '@src/api/teams'
import { Link } from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

const TeamHeader = ({ id }: BaseHeaderProps) => {
  const { data: team } = useGetTeam(id)
  return (
    <PageHeader
      title="Assign team kpis"
      backUrl="/"
      subtitle={
        <Link
          href={pathToUrl(ROUTES.FORMS.TEAM.KPI, { id })}
          target="_blank"
          color="inherit"
        >
          {team?.name}
        </Link>
      }
    />
  )
}

export default TeamHeader

import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { selectAuth } from '@src/store/auth/selectors'
import { logOutAction } from '@src/store/auth/actions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import Cookies from 'js-cookie'
import { COOKIE } from '@src/constants/api'

const MyProfile = () => {
  const auth = useSelector(selectAuth)
  const dispatch = useDispatch()

  const isAuthenticated = auth.authenticated
  // for sandbox mode we need to avoid logout while database is changing to testing version
  const sandboxDbCreating = Cookies.get(COOKIE.SANDBOX_DB_CREATING)

  useEffect(() => {
    if (!isAuthenticated && !sandboxDbCreating) {
      dispatch(logOutAction())
    }
  }, [isAuthenticated])

  return isAuthenticated ? (
    <Redirect
      to={getLocationDescriptor(
        pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: auth.user.id }),
      )}
    />
  ) : null
}

export default MyProfile

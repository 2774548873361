import React, { useState } from 'react'
import { TabBar, VStack } from '@revolut/ui-kit'
import CultureFitHelp from '@src/pages/Forms/EmployeePerformance/components/HelpSections/CultureFitHelp'
import SkillsHelp from '@src/pages/Forms/EmployeePerformance/components/HelpSections/SkillsHelp'
import DeliverablesHelp from '@src/pages/Forms/EmployeePerformance/components/HelpSections/DeliverablesHelp'

enum Tabs {
  Deliverables = 'Deliverables',
  Skills = 'Skills',
  Values = 'Values',
}

const renderTab = (tab: Tabs) => {
  switch (tab) {
    case Tabs.Deliverables:
      return <DeliverablesHelp />
    case Tabs.Skills:
      return <SkillsHelp />
    case Tabs.Values:
      return <CultureFitHelp />
    default:
      return null
  }
}

export const CombinedHelp = () => {
  const [tab, setTab] = useState<Tabs>(Tabs.Deliverables)

  return (
    <VStack space="s-16">
      <TabBar
        variant="segmented fit"
        defaultValue={Tabs.Deliverables}
        onChange={val => {
          if (val) {
            setTab(val as Tabs)
          }
        }}
      >
        <TabBar.Item to={Tabs.Deliverables}>Deliverables</TabBar.Item>
        <TabBar.Item to={Tabs.Skills}>Skills</TabBar.Item>
        <TabBar.Item to={Tabs.Values}>Values</TabBar.Item>
      </TabBar>
      {renderTab(tab)}
    </VStack>
  )
}

import React from 'react'
import { Flex } from '@revolut/ui-kit'

import { useTable } from '@src/components/Table/hooks'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import {
  exerciseCycleStartDateColumn,
  exerciseCycleHeadcountColumn,
  exerciseCyclePercentReconciledColumn,
  exerciseCycleStatusColumn,
  exerciseCycleTotalAmountColumn,
} from '@src/constants/columns/exerciseCycles'
import Stat from '@src/components/Stat/Stat'
import { payCycleReportsTableRequests } from '@src/api/payroll'
import { PayCycleInterface } from '@src/interfaces/payroll'
import { TableWrapper } from '@src/components/Table/TableWrapper'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { findShareOptionsPayGroup } from './helpers/findShareOptionsPayGroup'

const Row: RowInterface<PayCycleInterface> = {
  linkToForm: data =>
    navigateTo(
      pathToUrl(ROUTES.FORMS.OPTION_EXERCISE.PAYMENTS, {
        id: data.id,
      }),
    ),
  cells: [
    {
      ...exerciseCycleStartDateColumn,
      width: 150,
    },
    {
      ...exerciseCycleHeadcountColumn,
      width: 130,
    },
    {
      ...exerciseCycleTotalAmountColumn,
      width: 130,
    },
    {
      ...exerciseCyclePercentReconciledColumn,
      width: 150,
    },
    {
      ...exerciseCycleStatusColumn,
      width: 150,
    },
  ],
}

export const ExerciseCyclesTable = () => {
  const { payGroupId, isAvailable } = findShareOptionsPayGroup()
  const getInitialFilters = () => {
    if (!isAvailable || !payGroupId) {
      return []
    }
    return [
      {
        filters: [
          {
            name: `${payGroupId}`,
            id: payGroupId,
          },
        ],
        columnName: 'pay_group__id',
        nonResettable: true,
      },
    ]
  }

  const table = useTable(payCycleReportsTableRequests, getInitialFilters(), [
    { sortBy: 'status', direction: SORT_DIRECTION.DESC },
  ])

  return (
    <TableWrapper>
      <Flex mb="s-16" justifyContent="space-between">
        <Flex gap="s-32">
          <Stat
            label="Exercise cycles"
            val={table.loading ? undefined : table.stats?.total_pay_cycles}
          />
          {/* TODO: update after BE is ready
          <Stat label="Total reconciled" val="-" />
          <Stat label="Total amount" val="-" /> */}
        </Flex>
      </Flex>
      <AdjustableTable
        name={TableNames.ExerciseCycles}
        row={Row}
        useWindowScroll
        {...table}
      />
    </TableWrapper>
  )
}

import React from 'react'

import { useTable } from '@components/Table/hooks'
import {
  RiskControlsInterface,
  RiskIncidentsInterface,
  RiskIndicatorInterface,
  RiskStatsInterface,
  RiskStatus,
  RiskSummaryInterface,
  RiskTabs,
} from '@src/interfaces/risk'
import {
  getRiskControls,
  getRiskControlsStats,
  getRiskIncidents,
  getRiskIncidentsStats,
  getRiskIndicators,
  getRiskIndicatorsStats,
  getRiskSummary,
  getRiskSummaryStats,
} from '@src/api/risk'
import { TeamInterface } from '@src/interfaces/teams'
import { SORT_DIRECTION } from '@src/interfaces/data'
import RiskTab from '@src/pages/Forms/CommonRiskTab/RiskTab'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import RiskCircles, {
  statsToKri,
} from '@components/ColumnInserts/RiskCircles/RiskCircles'
import { useParams } from 'react-router-dom'
import { Redirect } from 'react-router'
import { useQuery } from '@src/utils/queryParamsHooks'
import Summary from '@src/pages/Forms/CommonRiskTab/Summary'
import Controls from '@src/pages/Forms/CommonRiskTab/Controls'
import Indicators from '@src/pages/Forms/CommonRiskTab/Indicators'
import Incidents from '@src/pages/Forms/CommonRiskTab/Incidents'
import { useIsNewOrgLayout } from '@src/pages/Team/helpers'

interface Props {
  data: TeamInterface
}

const Risk = ({ data }: Props) => {
  const params = useParams<{ id: string; subtab?: string }>()
  const { query } = useQuery()

  const isNewLayout = useIsNewOrgLayout()

  const initialFilter = [
    {
      filters: [{ name: data.id.toString(), id: data.id }],
      columnName: 'team__id',
      nonResettable: true,
      nonInheritable: true,
    },
  ]
  const initialFilterIncidents = [
    ...initialFilter,
    {
      filters: [{ id: RiskStatus.progress, name: RiskStatus.progress }],
      columnName: 'status',
      nonResettable: true,
      nonInheritable: true,
    },
  ]
  const initialSortBy = [
    {
      sortBy: 'risk_level_order',
      direction: SORT_DIRECTION.ASC,
    },
  ]

  const summaryTable = useTable<RiskSummaryInterface, RiskStatsInterface>(
    {
      getItems: getRiskSummary,
      getStats: getRiskSummaryStats,
    },
    initialFilter,
    initialSortBy,
  )

  const indicatorTable = useTable<RiskIndicatorInterface, RiskStatsInterface>(
    {
      getItems: getRiskIndicators,
      getStats: getRiskIndicatorsStats,
    },
    initialFilter,
    initialSortBy,
  )

  const incidentTable = useTable<RiskIncidentsInterface, RiskStatsInterface>(
    {
      getItems: getRiskIncidents,
      getStats: getRiskIncidentsStats,
    },
    initialFilterIncidents,
    initialSortBy,
  )

  const controlTable = useTable<RiskControlsInterface, RiskStatsInterface>(
    {
      getItems: getRiskControls,
      getStats: getRiskControlsStats,
    },
    initialFilter,
    initialSortBy,
  )

  const tabs = [
    {
      title: 'Summary',
      path: ROUTES.FORMS.TEAM.RISK.SUMMARY,
      to: pathToUrl(ROUTES.FORMS.TEAM.RISK.SUMMARY, params),
      component: <Summary table={summaryTable} data={data} useWrapper={!isNewLayout} />,
      quickSummary: summaryTable.stats !== undefined && (
        <RiskCircles data={statsToKri(summaryTable.stats)} />
      ),
    },
    {
      title: 'Risks and controls',
      path: ROUTES.FORMS.TEAM.RISK.CONTROLS,
      to: pathToUrl(ROUTES.FORMS.TEAM.RISK.CONTROLS, params),
      component: <Controls table={controlTable} data={data} useWrapper={!isNewLayout} />,
      quickSummary: controlTable.stats !== undefined && (
        <RiskCircles data={statsToKri(controlTable.stats)} />
      ),
    },
    {
      title: 'Risk Indicators',
      path: ROUTES.FORMS.TEAM.RISK.INDICATORS,
      to: pathToUrl(ROUTES.FORMS.TEAM.RISK.INDICATORS, params),
      component: <Indicators table={indicatorTable} useWrapper={!isNewLayout} />,
      quickSummary: indicatorTable.stats !== undefined && (
        <RiskCircles data={statsToKri(indicatorTable.stats)} />
      ),
    },
    {
      title: 'Risk Incidents',
      path: ROUTES.FORMS.TEAM.RISK.INCIDENTS,
      to: pathToUrl(ROUTES.FORMS.TEAM.RISK.INCIDENTS, params),
      component: <Incidents table={incidentTable} useWrapper={!isNewLayout} />,
      quickSummary: incidentTable.stats !== undefined && (
        <RiskCircles data={statsToKri(incidentTable.stats)} />
      ),
    },
  ]

  if (query.tab || params?.subtab === undefined) {
    switch (query.tab) {
      case RiskTabs.risk_indicators:
        return (
          <Redirect to={pathToUrl(ROUTES.FORMS.DEPARTMENT.RISK.INDICATORS, params)} />
        )
      case RiskTabs.risk_incidents:
        return <Redirect to={pathToUrl(ROUTES.FORMS.DEPARTMENT.RISK.INCIDENTS, params)} />
      case RiskTabs.summary:
      default:
        return <Redirect to={pathToUrl(ROUTES.FORMS.DEPARTMENT.RISK.SUMMARY, params)} />
    }
  }

  return <RiskTab tabs={tabs} wrapNavigation={isNewLayout} />
}

export default Risk

import React from 'react'
import { Avatar, Item } from '@revolut/ui-kit'
import { GlobalSearch, GlobalSearchTypes } from '@src/interfaces/globalSearch'
import StarItem from './StarItem'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { getInitials } from '@src/utils/employees'
import { getGlobalSearchItemFormUrl } from './common'

interface GlobalSearchItemProps {
  type: GlobalSearchTypes
  data: GlobalSearch
  onClose: () => void
}

const GlobalSearchItem = ({ type, data, onClose }: GlobalSearchItemProps) => {
  const url = getGlobalSearchItemFormUrl(type, data.object_id)

  if (type === 'employee') {
    return (
      <Item use={InternalLink} to={url}>
        <Item.Avatar onClick={onClose}>
          <Avatar image={data?.thumbnail_url}>
            {!data.thumbnail_url ? getInitials(data?.object_name || '') : ''}
          </Avatar>
        </Item.Avatar>
        <Item.Content onClick={onClose}>
          <Item.Title>{data?.object_name}</Item.Title>
          <Item.Description>{data?.subtitle}</Item.Description>
        </Item.Content>
        <Item.Side
          onClick={e => {
            e.preventDefault()
          }}
        >
          <StarItem data={data} />
        </Item.Side>
      </Item>
    )
  }

  return (
    <Item use={InternalLink} to={url}>
      <Item.Content onClick={onClose}>
        <Item.Title>{data?.object_name}</Item.Title>
        <Item.Description>{data?.subtitle}</Item.Description>
      </Item.Content>
      <Item.Side
        onClick={e => {
          e.preventDefault()
        }}
      >
        <StarItem data={data} />
      </Item.Side>
    </Item>
  )
}

export default GlobalSearchItem

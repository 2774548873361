import { Header, Popup } from '@revolut/ui-kit'
import { kpisRequestsNew } from '@src/api/kpis'
import { useFindTeamByName } from '@src/api/teams'
import { KPITypes } from '@src/constants/table'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { KpiInterface } from '@src/interfaces/kpis'
import New from '@src/pages/Forms/KpiForm/New'
import SelectKPIType from '@src/pages/Forms/KpiForm/SelectKPIType'
import React, { ReactNode, useEffect, useState } from 'react'

export const FormPopup = ({
  onClose,
  onAfterSubmit,
}: {
  onClose: () => void
  onAfterSubmit: () => void
}) => {
  const [title, setTitle] = useState<ReactNode>('Select KPI type')
  const { values } = useLapeContext<KpiInterface>()
  const { data: team } = useFindTeamByName('Product · Core · IT')

  useEffect(() => {
    values.kpi_type = { id: KPITypes.team_kpi, name: 'Team' }
  }, [])

  useEffect(() => {
    if (team) {
      values.team = { id: team.id, name: team.name }
    }
  }, [team])

  const typeSelected = !!values.update_type || values.is_inherited

  useEffect(() => {
    if (typeSelected) {
      setTitle('KPI form')
    }
  }, [typeSelected])

  const form = typeSelected ? (
    <New
      hideSettingsButtons
      ignoreAfterRedirect
      onAfterSubmit={() => {
        onAfterSubmit()
        onClose()
      }}
    />
  ) : (
    <SelectKPIType
      useLocalStorageCaching={false}
      onSelect={type => {
        if (type === 'inherit') {
          values.is_inherited = true
        } else {
          values.update_type = type
          values.is_inherited = false
        }
      }}
    />
  )

  return (
    <Popup open onClose={onClose} size="md">
      <Header displayMode="inline">
        <Header.CloseButton aria-label="close" />
        <Header.Title>{title}</Header.Title>
      </Header>
      {form}
    </Popup>
  )
}

export const KpiForm = ({
  open,
  onClose,
  onAfterSubmit,
}: {
  open: boolean
  onClose: () => void
  onAfterSubmit: () => void
}) => {
  return open ? (
    <Form
      forceParams={{ id: undefined }}
      api={kpisRequestsNew}
      disableLocalStorageCaching
    >
      <FormPopup onAfterSubmit={onAfterSubmit} onClose={onClose} />
    </Form>
  ) : null
}

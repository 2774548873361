import React, { useState } from 'react'
import { Button, VStack } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ProbationTemplateInterface } from '@src/interfaces/probationTemplate'
import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'
import { Pencil, Plus } from '@revolut/icons'
import { EmployeeRulesList } from '@src/pages/Forms/ProbationTemplate/EmployeeRulesList'
import { EmployeeRulesSidebarForm } from '@src/pages/Forms/ProbationTemplate/EmployeeRulesSidebarForm'
import { isEmpty } from 'lodash'

export const EligibleEmployees = () => {
  const { values } = useLapeContext<ProbationTemplateInterface>()

  const [rulesSidebarOpen, setRulesSidebarOpen] = useState(false)
  const hasRulesSet = !isEmpty(values.rules)

  return (
    <VStack space="s-8">
      <SectionTitle
        title="To which new joiners should this probation cycle apply?"
        subtitle="Set rules to define which new joining employees this cycle will apply for."
      />
      <Button
        variant="secondary"
        size="sm"
        useIcon={hasRulesSet ? Pencil : Plus}
        onClick={() => setRulesSidebarOpen(!rulesSidebarOpen)}
      >
        {hasRulesSet ? 'Edit rules' : 'Set rules'}
      </Button>
      <EmployeeRulesList rules={values.rules} />
      <EmployeeRulesSidebarForm
        isOpen={rulesSidebarOpen}
        handleClose={() => setRulesSidebarOpen(false)}
      />
    </VStack>
  )
}

import React, { useState } from 'react'
import { Archive, Newspaper, Unarchive } from '@revolut/icons'
import { chain, Color, Group, Text, MoreBar, Box, VStack, Link } from '@revolut/ui-kit'
import { useTheme } from '@src/styles/theme'
import { pathToUrl } from '@src/utils/router'
import { PermissionTypes } from '@src/store/auth/types'
import { ROUTES } from '@src/constants/routes'
import { EngagementQuestionInterface } from '@src/interfaces/engagement'
import {
  archiveEngagementQuestion,
  unarchiveEngagementQuestion,
} from '@src/api/engagement'
import { PageWrapper } from '@components/Page/Page'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageBody } from '@src/components/Page/PageBody'
import { FormPreview } from '@components/FormPreview/FormPreview'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { EditButton } from '@src/features/SettingsButtons'
import { mapStatusToColor } from '../helpers'
import EngagementQuestionPage from '@src/pages/Engagement/EngagementQuestionPage'
import { ActionToggleButton } from '../components/ActionToggleButton'
import HTMLEditor from '@components/HTMLEditor/HTMLEditor'

export const QuestionPreview = () => {
  const { initialValues } = useLapeContext<EngagementQuestionInterface>()
  const theme = useTheme()
  const [showPreview, setShowPreview] = useState<boolean>(false)

  const permissions = initialValues.field_options?.permissions || []
  const canEdit = permissions.includes(PermissionTypes.ChangeEngagementQuestion)
  const canArchive = permissions.includes(PermissionTypes.ArchiveEngagementQuestion)

  const connectedDriverHasNoMoreConnectedQuestions =
    typeof initialValues.driver_active_questions_count === 'number' &&
    initialValues.driver_active_questions_count === 1
  const connectedDriverIsArchived =
    typeof initialValues.driver_active_questions_count === 'number' &&
    initialValues.driver_active_questions_count === 0
  const questionIsArchived = initialValues.status?.id === 'archived'
  const showWarnOnArchive =
    !questionIsArchived && connectedDriverHasNoMoreConnectedQuestions
  const showWarnOnUnarchive = questionIsArchived && connectedDriverIsArchived

  const subtitle = chain(
    <Text color={Color.FOREGROUND}>Survey question</Text>,
    <Text color={mapStatusToColor(initialValues, theme)}>
      {initialValues.status?.name}
    </Text>,
  )

  if (showPreview) {
    return (
      <EngagementQuestionPage
        preview={{
          question: initialValues as EngagementQuestionInterface,
          onClose: () => setShowPreview(false),
        }}
      />
    )
  }
  return (
    <PageWrapper>
      <PageHeader
        title={initialValues.question_text}
        subtitle={subtitle}
        backUrl={ROUTES.APPS.ENGAGEMENT.QUESTIONS_TABLE}
      />
      <PageBody>
        <Box mb="s-24">
          <MoreBar>
            <EditButton
              route={pathToUrl(ROUTES.APPS.ENGAGEMENT.QUESTION.EDIT, {
                id: initialValues.id,
              })}
              isVisible={canEdit}
            />
            <MoreBar.Action useIcon={Newspaper} onClick={() => setShowPreview(true)}>
              Preview
            </MoreBar.Action>
            {canArchive && (
              <ActionToggleButton<EngagementQuestionInterface>
                isActive={initialValues.status?.id !== 'archived'}
                activeButton={{
                  text: 'Archive',
                  icon: Archive,
                  action: archiveEngagementQuestion,
                  successText: 'Question successfully archived',
                }}
                activeSubmit={{
                  title: 'Are you sure you want to archive this question?',
                  subtitle: 'It will be removed from all scheduled surveys.',
                }}
                activeWarn={{
                  title: 'Archiving this question will result in archiving driver',
                  subtitle:
                    'This happens as there are no other questions linked with this driver. ' +
                    'Are you sure you want to continue?',
                }}
                inactiveButton={{
                  text: 'Unarchive',
                  icon: Unarchive,
                  action: unarchiveEngagementQuestion,
                  successText: 'Question successfully unarchived',
                }}
                inactiveSubmit={{
                  title: 'Are you sure you want to unarchive this question?',
                  subtitle:
                    'It will be automatically added to some of the scheduled surveys.',
                }}
                inactiveWarn={{
                  title: 'Unarchiving this question will result in unarchiving driver',
                  subtitle:
                    'As driver is unarchived, related questions will be added to scheduled surveys. ' +
                    'Are you sure you want to continue?',
                }}
                showWarn={showWarnOnArchive || showWarnOnUnarchive}
              />
            )}
          </MoreBar>
        </Box>
        <VStack space="s-16">
          <FormPreview data={initialValues}>
            <Group>
              <FormPreview.Details title="Question" field="question_text" />
              {!!initialValues.description && (
                <FormPreview.Details
                  title="Description"
                  field="description"
                  insert={() => (
                    <HTMLEditor
                      readOnly
                      nonResizable
                      noPadding
                      height="auto"
                      onChange={() => {}}
                      value={initialValues.description}
                      fontSize="14px"
                    />
                  )}
                />
              )}
              <FormPreview.Item<EngagementQuestionInterface>
                title="Owner"
                field="owner"
                insert={d => <UserWithAvatar {...d.owner} />}
              />
            </Group>
          </FormPreview>
          <FormPreview data={initialValues} title="Settings">
            <Group>
              <FormPreview.Item<EngagementQuestionInterface>
                title="Type"
                field="type.name"
              />
              <FormPreview.Item<EngagementQuestionInterface>
                title="Driver"
                field="driver"
                insert={d => (
                  <Link
                    href={pathToUrl(ROUTES.APPS.ENGAGEMENT.DRIVER.PREVIEW, {
                      id: d.driver.id,
                    })}
                  >
                    <Text variant="h6">{d.driver.name}</Text>
                  </Link>
                )}
              />
              <FormPreview.Item<EngagementQuestionInterface>
                title="Comment"
                field="comment.name"
              />
              {!!initialValues.categories?.names && (
                <FormPreview.Details<EngagementQuestionInterface>
                  title="Categories"
                  field="categories"
                  insert={response => (
                    <Box pl="s-32">
                      <ul>
                        {response.categories?.names.map(category => (
                          <li key={category}>{category}</li>
                        ))}
                      </ul>
                    </Box>
                  )}
                />
              )}

              <FormPreview.Item
                title="Last update on"
                type="date"
                field="updated_date_time"
              />
            </Group>
          </FormPreview>
        </VStack>
      </PageBody>
    </PageWrapper>
  )
}

import {
  VStack,
  Input,
  Text,
  Icon,
  Token,
  chain,
  List,
  Checkbox,
  Button,
  InputProps,
  Cell,
} from '@revolut/ui-kit'
import { addRoadmaps, getAllUnassignedRoadmaps } from '@src/api/roadmaps'
import { EntityTypes } from '@src/constants/api'
import { IssueTypes } from '@src/interfaces/deliverables'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { UnassignedRoadmapInterface } from '@src/interfaces/roadmaps'
import debounce from 'lodash/debounce'
import pluralize from 'pluralize'
import React, { useEffect, useMemo, useState } from 'react'

export const SearchInput = ({
  onSearch,
  ...props
}: InputProps & { onSearch: (search?: string) => void }) => {
  return (
    <Input
      {...props}
      onChange={e => onSearch((e.currentTarget as HTMLInputElement).value)}
    />
  )
}

const initialForceSelected = {}

export const JiraSearchWidget = ({
  fieldMessage,
  onSelectionChange,
  forceSelected = initialForceSelected,
  ...inputProps
}: {
  fieldMessage?: string
  onSelectionChange: (epics: Record<string, UnassignedRoadmapInterface>) => void
  forceSelected?: Record<string, UnassignedRoadmapInterface>
} & InputProps) => {
  const [searchValue, setSearchValue] = useState<string>()
  const [searchPending, setSearchPending] = useState(false)
  const [options, setOptions] = useState<UnassignedRoadmapInterface[]>([])
  const [selected, setSelected] =
    useState<Record<string, UnassignedRoadmapInterface>>(forceSelected)

  useEffect(() => {
    setSelected(forceSelected)
    setOptions(Object.values(forceSelected))
  }, [forceSelected])

  const debouncedSearch = useMemo(() => {
    return debounce(async (searchString: string) => {
      const request = getAllUnassignedRoadmaps(searchString).then(resp =>
        resp.data.results.sort(epicsFirst),
      )

      try {
        const roadmaps = await request
        setOptions([...Object.values(selected), ...roadmaps.filter(isNotSelected)])
      } finally {
        setSearchPending(false)
      }
    }, 1000)
  }, [])

  const onSearch = (searchString?: string) => {
    setOptions([...Object.values(selected)])
    setSearchValue(searchString)
    if (searchString) {
      setSearchPending(true)
      debouncedSearch(searchString)
    }
  }

  useEffect(() => {
    onSelectionChange(selected)
  }, [selected])

  const isSelected = (r: UnassignedRoadmapInterface) => !!selected[r.id]
  const isNotSelected = (r: UnassignedRoadmapInterface) => !isSelected(r)
  const epicsFirst = (a: UnassignedRoadmapInterface, b: UnassignedRoadmapInterface) => {
    if (
      a.issue_type === b.issue_type ||
      (a.issue_type !== IssueTypes.Epic && b.issue_type !== IssueTypes.Epic)
    ) {
      return 0
    }
    return a.issue_type === IssueTypes.Epic ? -1 : 1
  }

  const getSearchFieldIcon = () => {
    if (searchPending) {
      return undefined
    }

    return searchValue ? (
      <Icon name="Cross" onClick={() => onSearch('')} style={{ cursor: 'pointer' }} />
    ) : (
      <Icon name="Search" />
    )
  }

  const isDisabledSelect = (option: UnassignedRoadmapInterface) => {
    return option.issue_type !== IssueTypes.Epic
  }

  const renderOptions = () => {
    const currentList = [
      ...Object.values(selected).filter(
        selectedOption => !options.includes(selectedOption),
      ),
      ...options,
    ]
    return (
      <VStack space="s-12">
        <Text variant="caption" color={Token.color.greyTone50}>
          {chain(`Showing ${pluralize('issue', options?.length)}`, options?.length)}
        </Text>
        <Cell p="s-12">
          <List maxHeight={500} overflow="auto">
            {currentList.map(option => (
              <List.Item
                key={option.id}
                use="label"
                style={isDisabledSelect(option) ? undefined : { cursor: 'pointer' }}
                color={isDisabledSelect(option) ? Token.color.greyTone20 : undefined}
                useIcon={
                  <Checkbox
                    checked={isSelected(option)}
                    disabled={isDisabledSelect(option)}
                    onChange={event => {
                      if (event.target.checked) {
                        setSelected({ ...selected, [option.id]: option })
                      } else {
                        const { [option.id]: omit, ...rest } = selected
                        setSelected(rest)
                      }
                    }}
                  />
                }
              >
                {option.display_name}
              </List.Item>
            ))}
          </List>
        </Cell>
      </VStack>
    )
  }

  return (
    <VStack space="s-16">
      <SearchInput
        placeholder="Search epics"
        value={searchValue}
        data-testid="sidebar-multiselect-new-input"
        pending={searchPending}
        onSearch={onSearch}
        renderAction={getSearchFieldIcon}
        {...inputProps}
      />
      {options?.length ? renderOptions() : null}
    </VStack>
  )
}

export const AddJiraRoadmapsWidget = ({
  onAfterSubmit,
  entityId,
  entityType,
  reviewCycle,
}: {
  onAfterSubmit: () => void
  entityType: EntityTypes
  entityId: number
  reviewCycle: ReviewCyclesInterface
}) => {
  const [submitPending, setSubmitPending] = useState(false)
  const [selected, setSelected] = useState<Record<string, UnassignedRoadmapInterface>>({})
  const [cleanSelected, setCleanSelected] = useState<{}>({}) // need this to clean up selected items in the search widget

  const onSubmit = async () => {
    setSubmitPending(true)
    try {
      const keys = Object.values(selected).map(r => r.key)
      await addRoadmaps(entityType, entityId, keys, reviewCycle.id)
      onAfterSubmit()
      setSelected({})
      setCleanSelected({})
    } finally {
      setSubmitPending(false)
    }
  }

  const selectedCount = Object.keys(selected).length

  return (
    <VStack width="100%" space="s-16">
      <Text variant="h5">Add roadmap</Text>
      <JiraSearchWidget
        message={`Epics will be added to the ${reviewCycle?.name}  roadmap`}
        onSelectionChange={setSelected}
        forceSelected={cleanSelected}
      />
      {selectedCount ? (
        <Button
          variant="primary"
          disabled={!selectedCount}
          pending={submitPending}
          onClick={onSubmit}
        >
          {chain(`Add ${pluralize('roadmap', selectedCount)}`, selectedCount)}
        </Button>
      ) : null}
    </VStack>
  )
}
